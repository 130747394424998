
import {IonContent, IonIcon, IonImg, IonInput, IonPage} from '@ionic/vue';
import {lockClosed, person} from 'ionicons/icons';
import {Constants} from "@/common/Constants";
import {login} from "@/api/login";
import {onMounted, reactive, toRefs} from "vue";
import {UIUtils} from "@/common/UIUtils";
import {NaviUtils} from "@/common/NaviUtils";
import {useStore} from "vuex";
import {Env} from "@/common/Env";

export default {
  components: {IonPage, IonContent, IonInput, IonImg, IonIcon},
  setup() {
    let temp: {
      username?: string,
      password?: string,
      event?: Event,
    } = {
      username: undefined,
      password: undefined,
      event: undefined,
    }
    const store = useStore();
    const state = reactive(temp);

    const loginClick = async () => {
      if (!state.username || !state.password) {
        UIUtils.showToast("", "用户名和密码不能为空", 3000, 'top');
        return;
      }

      try {
        await store.dispatch('user/login', {username: state.username, password: state.password});
        await store.dispatch('user/getUserMenu', {});
        console.log("login");
        NaviUtils.navReplace('/home');
      } catch (err: any) {
        UIUtils.showToast("", err.message, 3000);
      }
    }

    onMounted(() => {
      const loginToken = localStorage.getItem(Constants.TOKEN_KEY);
      if (loginToken != null) {
        NaviUtils.navReplace('/home');
      }
    })
    return {
      ...toRefs(state),
      person,
      lockClosed,
      loginClick,
      basePath: Env.basePath,
    }
  }
}
