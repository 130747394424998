
import {IonItem, IonList, IonListHeader, IonRadio, IonRadioGroup, IonCheckbox, IonLabel} from '@ionic/vue';
import {defineComponent} from "vue";
import {SelectionItemType} from "@/common/TypeDefs";

export default defineComponent({
  name: 'PopSelection',
  props: {
    title: {type: String, default: null},
    items: {type: Array},
    mutiple: {type: Boolean, default: false},
    modelValue: {type: Array, default: null},
  },
  data() {
    return {
      selection: new Array(this.$props.items ? this.$props.items.length : 0),
    }
  },
  unmounted() {
    console.info('unmounted')
  },
  deactivated() {
    console.info('deactivated')
  },
  mounted() {
    if (this.modelValue && this.$props.items) {
      for (let i = 0; i < this.$props.items.length; i++) {
        let item: any = this.$props.items[i];
        this.modelValue.forEach((checkedItem: any) => {
          if (checkedItem.value === item.value) {
            this.selection[i] = item;
            return;
          }
        });
      }
    }
  },
  computed: {
    checkedFlag() {
      return (curValue: any) => {
        return curValue != null ? this.modelValue.find((item: any) => curValue === item.value) : false
      };
    }
  },
  methods: {
    onSelect(item: SelectionItemType, index: number) {
      if (this.$props.mutiple) {
        if (this.selection[index] == null) {
          this.selection[index] = item;
        } else {
          this.selection[index] = null;
        }
      } else {
        for (let i = 0; i < this.selection.length; i++) {
          if (i === index) {
            this.selection[i] = item;
          } else {
            this.selection[i] = null;
          }
        }
      }

      const selectionItems = this.selection.filter(item => item != null);
      // console.info(selectionItems);
      this.$emit('update:modelValue', selectionItems);
      if (!this.$props.mutiple) {
        this.$emit('onSelect', selectionItems);
      }
    },
    onClose() {
      this.$emit('onClose')
    }
  },
  components: {IonList, IonItem, IonListHeader, IonRadio, IonRadioGroup, IonCheckbox, IonLabel},
  emits: ['onClose', 'onSelect', 'update:modelValue'],
});
