
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {arrowBack, ellipse, square, triangle} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import {useRoute} from "vue-router";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {useStore} from "vuex";
import DynamicForm from "@/views/component/DynamicForm.vue";

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, DynamicForm
  },
  props: {
    pageItems: {type: Array}
  },
  emits: ['onFinish'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      itemTypes: { [name: string]: number },
      event?: Event,
    } = {
      itemTypes: Constants.ItemTypes,
      event: undefined,
    }
    const state = reactive(temp);


    const badgeNum = computed(() => {
      return null;
    })

    const back = () => {
      NaviUtils.navPop();
    }

    const gotoPage = (url: string) => {
      console.log(url)
      NaviUtils.navPush(url)
    }

    const confirm = async () => {
      try {
        const report = proxy.$refs['dynamicFormId'].getReportData()
        emit('onFinish', report)
      } catch (e: any) {
        UIUtils.showToast("", "提交失败:" + e.message, 2000)
        console.error(e)
      }
    }

    onMounted(() => {
      console.log("on AfterConfirmPage")
    })
    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      arrowBack,
      badgeNum,
      back,
      gotoPage,
      confirm,
    }
  }
}
