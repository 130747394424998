export type StateItems = {
    deptIds: Array<string>,
    deptNames: Array<string>,
};

const state: StateItems = {deptIds: [], deptNames: []};
const getters = {
    deptIds: (state: StateItems) => state.deptIds,
    topDeptId: (state: StateItems) => {
        if (state.deptIds.length) {
            return state.deptIds[0]
        }
        return null
    },
    lastDeptId: (state: StateItems) => {
        if (state.deptIds.length) {
            return state.deptIds[state.deptIds.length - 1]
        }
        return null
    },
    lastDeptName: (state: StateItems) => {
        if (state.deptNames.length) {
            return state.deptNames[state.deptNames.length - 1]
        }
        return null
    },
}
const mutations = {
    pushDeptId(state: StateItems, {deptId, deptName}: { deptId: string, deptName: string }) {
        state.deptIds.push(deptId)
        state.deptNames.push(deptName)
    },
    popDeptId(state: StateItems) {
        if (state.deptIds.length > 0) {
            state.deptIds.splice(state.deptIds.length - 1, 1)
        }
        if (state.deptNames.length > 0) {
            state.deptNames.splice(state.deptNames.length - 1, 1)
        }
    },
    clearDeptId(state: StateItems) {
        state.deptIds = []
        state.deptNames = []
    },
}
const actions = {}
export default {state, getters, mutations, actions}
