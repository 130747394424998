
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  pickerController,
  SelectCustomEvent,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, nextTick, onMounted, PropType, reactive, toRefs, watch} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {createWithLastReport, doEdit} from "@/api/nucleic"
import {UIUtils} from "@/common/UIUtils";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import {Filters} from "@/common/Filters";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {PickerColumnOption} from "@ionic/core/dist/types/components/picker/picker-interface";
import {DateUtils} from "@/common/DateUtils";
import {PictureInfoType} from "@/common/TypeDefs";
import {Constants} from "@/common/Constants";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import DynamicForm from "@/views/component/DynamicForm.vue";
import GeoLocator from "@/views/component/GeoLocator.vue";
import {PositionRes} from "@awesome-cordova-plugins/gao-de-location";
import {isBefore, parse, parseISO} from "date-fns";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonInput,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    IonSelect,
    IonSelectOption,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonDatetimeButton,
    IonDatetime,
    IonModal,
    PhotoUploader,
    DynamicForm,
    GeoLocator
  }
  ,
  props: {
    params: {
      item: Object as PropType<NucleicTemperInfo>,
      viewFlag: Boolean,
      isNew: Boolean,
    }
  },
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    const addressPos = (form: NucleicTemperInfo) => {
      if (form) {
        if (form.address3) {
          return 3
        } else if (form.address2) {
          return 2
        } else if (form.address) {
          return 1
        }
      }
      return 0;
    }

    let temp: {
      pageItems: Array<ReportDefItemWithValue>,
      form: NucleicTemperInfo,
      detectTimeString?: string,
      isolationStartTimeString?: string,
      isolationEndTimeString?: string,
      isolationEndTimeModal: boolean,
      symptomDescArray: Array<string>,
      detectImgArray: Array<PictureInfoType>,
      notDetectRemarkRef: any,
      openAccordionList: Array<string>,
      addressPosIndex: number,
      event?: Event,
    } = {
      pageItems: [],
      form: (props.params && props.params.item) ? props.params.item : {},
      detectTimeString: undefined,
      isolationStartTimeString: undefined,
      isolationEndTimeString: undefined,
      isolationEndTimeModal: false,
      symptomDescArray: (props.params && props.params.item && props.params.item.symptomDesc ? JSON.parse(props.params.item.symptomDesc) : []),
      detectImgArray: (props.params && props.params.item && props.params.item.detectImg ? JSON.parse(props.params.item.detectImg) : []),
      notDetectRemarkRef: null,
      openAccordionList: ['temper'],
      addressPosIndex: addressPos(props.params.item),
      event: undefined,
    }

    if (!temp.form.temper1Time) {
      temp.form.temper1Time = DateUtils.parseTimeString("09:00", "HH:mm")
    }
    if (!temp.form.temper2Time) {
      temp.form.temper2Time = DateUtils.parseTimeString("14:00", "HH:mm")
    }

    const state = reactive(temp);

    const temperTimeSet = (index: number, timer?: number) => {
      openPicker((ts) => {
        if (index == 1) {
          state.form.temper1Time = ts
        } else if (index == 2) {
          state.form.temper2Time = ts
        }
      }, timer)
    }

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const save = async (flag: number) => {
      state.form.detectImg = state.detectImgArray.length > 0 ? JSON.stringify(state.detectImgArray) : undefined

      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      if (state.symptomDescArray && state.symptomDescArray.length > 0) {
        state.form.symptomDesc = JSON.stringify(state.symptomDescArray)
      }

      if (state.detectTimeString) {
        state.form.detectDate = parseISO(state.detectTimeString).getTime()
      } else {
        state.form.detectDate = undefined
      }
      if (state.isolationStartTimeString) {
        state.form.isolationStart = parseISO(state.isolationStartTimeString).getTime()
      } else {
        state.form.isolationStart = undefined
      }
      if (state.isolationEndTimeString) {
        state.form.isolationEnd = parseISO(state.isolationEndTimeString).getTime()
      } else {
        state.form.isolationEnd = undefined
      }

      let confirmMsg = ""
      const originStatus = state.form.status
      if (flag === 1) {
        confirmMsg = "是否确认提交?"
        state.form.status = Constants.NucleicReportStatus.TEAM_AUDIT
      } else if (flag === 2) {
        confirmMsg = "是否确认更新?"
        state.form.auditFlag = 0
        state.form.status = undefined
      } else {
        //表示仅保存，需要将状态置为未提交
        state.form.status = Constants.NucleicReportStatus.UNCOMMIT
      }

      // const report = proxy.$refs['dynamicFormId'].getReportData()
      // if (typeof report !== 'string') {
      //   state.form.reportValues = JSON.stringify(report.data)
      // } else {
      //   state.form.reportValues = JSON.stringify(JSON.parse(report).data)
      // }
      UIUtils.showAlertConfirm("确认", confirmMsg, async () => {

        try {
          await doEdit(state.form)
          back()
        } catch (e: any) {
          state.form.status = originStatus
          console.error(e)
          UIUtils.showToast("", e.message, 2000)
        }
      }, () => {
        console.log("取消")
        state.form.status = originStatus
      }, "提交", "取消")
    }

    const validation = () => {
      const temp1Time = DateUtils.fromTs(state.form.temper1Time, 'HH:mm')
      const temp2Time = DateUtils.fromTs(state.form.temper2Time, 'HH:mm')

      if (!state.form.temper1Value) {
        return "第一次体温不允许为空";
      }
      if (!state.form.temper2Value) {
        return "第二次体温不允许为空";
      }
      if (!state.form.workStatus || state.form.workStatus.trim() == '') {
        return "工作状态不允许为空";
      }
      // if (!state.form.address || state.form.address.trim() == '') {
      //   return "首次定位不允许为空";
      // }
      if (!state.form.selfHealth || state.form.selfHealth.trim() == '') {
        return "本人健康状况不允许为空";
      }

      if (!state.form.detectResult || state.form.detectResult.trim() == '') {
        return "本人当前新冠阴/阳性状态不允许为空";
      }

      if (!state.form.detectStatus || state.form.detectStatus.trim() == '') {
        return "检测状态不允许为空";
      } else if (state.form.detectStatus === '未检测') {
        console.info("未检测不校验")
      } else if (state.form.detectStatus === '已检测') {
        if (!state.detectTimeString || state.detectTimeString.trim() == '') {
          return "检测日期不允许为空";
        } else {
          const result = parseISO(state.detectTimeString)
          if (isBefore(new Date(), result)) {
            return "检测日期不允许晚于当前时间";
          }
        }
        if (!state.form.detectType || state.form.detectType.trim() == '') {
          return "检测方式不允许为空";
        }
        if (state.detectImgArray.length == 0) {
          return "检测结果图片不允许为空";
        }
      }

      if (!state.form.cohabitantHealth || state.form.cohabitantHealth.trim() == '') {
        return "同住人健康状况不允许为空";
      }

      if (state.form.selfHealth === '涉及新冠疫情') {
        if (state.form.detectResult !== '阳性') {
          return "本人涉及新冠疫情,【本人当前新冠阴/阳性状态】必须为【阳性】"
        }
      }

      if (state.form.cohabitantHealth === '涉及新冠疫情' || state.form.selfHealth === '涉及新冠疫情') {
        if (!state.form.classify || state.form.classify.trim() == '') {
          return "人员类别不允许为空"
        }
        if (!state.form.isolationLocation || state.form.isolationLocation.trim() == '') {
          return "隔离地点不允许为空"
        }
        if (!state.form.lockdown || state.form.lockdown.trim() == '') {
          return "社区是否有管控要求不允许为空"
        }
        if (!state.form.isolationStart) {
          return " 隔离开始时间不允许为空"
        }
        if (!state.form.symptom || state.form.symptom.trim() == '') {
          return "症状不允许为空"
        } else if (state.form.symptom === '有症状') {
          if (state.symptomDescArray.length === 0) {
            return "具体症状不允许为空"
          }
        }

        if (state.form.takeMedicine === 1) {
          if (!state.form.medicineDesc || state.form.medicineDesc.trim() == '') {
            return "药物名称不允许为空"
          }
        }

        if (!state.form.liveStatus || state.form.liveStatus.trim() == '') {
          return "居住情况不允许为空"
        }
        if (state.form.cohabitantFlag === undefined) {
          return "是否有同住人不允许为空"
        }

        if (state.form.cohabitantFlag === 1) {
          if (!state.form.cohabitantName || state.form.cohabitantName.trim() == '') {
            return "同住人姓名不允许为空"
          }
          if (!state.form.cohabitantContact || state.form.cohabitantContact.trim() == '') {
            return "同住人联系方式不允许为空"
          }
        }
      }

      // 刘虓要求去掉次校验
      // if (state.form.workStatus === '到岗上班') {
      //   if (state.form.detectStatus !== '已检测') {
      //     return '【工作状态】为【到岗上班】时【检测状态】必须为【已检测】'
      //   }
      // }

      if (state.form.detectResult === '阳性') {
        if (state.form.selfHealth !== '涉及新冠疫情') {
          return '【检测结果】为【阳性】时【本人健康状况】必须为【涉及新冠疫情】'
        }
      }
      return null;
    }

    const openPicker = async (callback: (ts: number) => void, timer?: number) => {
      const hours: PickerColumnOption[] = []
      const minutes: PickerColumnOption[] = []

      let hourIndex
      let minuteIndex
      if (timer) {
        hourIndex = parseInt(DateUtils.fromTs(timer, "HH"))
        minuteIndex = parseInt(DateUtils.fromTs(timer, "mm"))
      }
      for (let h = 0; h < 24; h++) {
        hours.push({text: h.toString(), value: h})
      }
      for (let m = 0; m < 60; m++) {
        minutes.push({text: m.toString(), value: m})
      }
      const picker = await pickerController.create({
        columns: [
          {
            name: 'hour',
            options: hours,
            selectedIndex: hourIndex,
          },
          {
            name: 'minute',
            options: minutes,
            selectedIndex: minuteIndex,
          },
        ],
        buttons: [
          {
            text: '取消',
            role: 'cancel',
          },
          {
            text: '确认',
            handler: (value) => {
              console.log(value)
              callback(DateUtils.parseTimeString(value.hour.value + ":" + value.minute.value, 'HH:mm'))
            },
          },
        ],
      });
      await picker.present();
    }

    const onLocation = (location: PositionRes, index: number) => {
      if (index === 1) {
        state.form.address = location.formattedAddress
        state.form.province = location.province
        state.form.city = location.city
        state.form.area = location.district
        state.form.street = location.street
        state.form.latitude = location.latitude
        state.form.longitude = location.longitude
      } else if (index === 2) {
        state.form.address2 = location.formattedAddress
        state.form.province2 = location.province
        state.form.city2 = location.city
        state.form.area2 = location.district
        state.form.street2 = location.street
        state.form.latitude2 = location.latitude
        state.form.longitude2 = location.longitude
      } else if (index === 3) {
        state.form.address3 = location.formattedAddress
        state.form.province3 = location.province
        state.form.city3 = location.city
        state.form.area3 = location.district
        state.form.street3 = location.street
        state.form.latitude3 = location.latitude
        state.form.longitude3 = location.longitude
      }
    }

    const selfHealthChange = (e: SelectCustomEvent) => {
      refreshHealth()
    }

    const symptomChange = (e: SelectCustomEvent) => {
      refreshSymptom(e.detail.value)
    }

    const isolationTimeChange = (event: CustomEvent, type: number) => {
      const date = parseISO(event.detail.value)
      if (type === 0) {
        state.isolationStartTimeString = event.detail.value
        state.form.isolationStart = date.getTime()
      } else if (type === 1) {
        state.isolationEndTimeString = event.detail.value
        state.form.isolationEnd = date.getTime()
      } else if (type === 2) {
        state.detectTimeString = event.detail.value
        state.form.detectDate = date.getTime()
      }

      console.info(date)
    }

    const detectStatusChange = (e: SelectCustomEvent) => {
      refreshDetectStatus(e.detail.value)
    }

    const accordionChange = (e: any) => {
      if (e.target && e.currentTarget) {
        if (e.target.id === e.currentTarget.id) {
          state.openAccordionList = e.detail.value
        }
      }
    }

    const refreshHealth = () => {
      nextTick(() => {
        if (state.form.cohabitantHealth !== '涉及新冠疫情' && state.form.selfHealth !== '涉及新冠疫情') {
          state.form.classify = undefined
          state.form.isolationLocation = undefined
          state.form.lockdown = undefined
          state.form.isolationStart = undefined
          state.form.isolationEnd = undefined
          state.form.symptom = undefined
          state.form.symptomDesc = undefined
          state.form.takeMedicine = undefined
          state.form.medicineDesc = undefined
          state.form.otherRemark = undefined
          state.form.liveStatus = undefined
          state.form.cohabitantFlag = undefined
          state.form.cohabitantName = undefined
          state.form.cohabitantContact = undefined

          state.symptomDescArray = []
          state.isolationStartTimeString = undefined
          state.isolationEndTimeString = undefined
        } else {
          if (state.isolationStartTimeString === undefined) {
            state.form.isolationStart = new Date().getTime()
            state.isolationStartTimeString = DateUtils.tsToIsoString(state.form.isolationStart)
          }
        }
      })
    }

    const refreshSymptom = (symptom: string) => {
      if (symptom === '无症状') {
        state.form.symptomDesc = undefined
      }
    }

    const refreshDetectStatus = (detectStatus: string) => {
      console.info("detectStatus = ", detectStatus)
      if (detectStatus === '未检测') {
        state.form.detectDate = undefined
        state.detectTimeString = undefined
        state.form.detectType = undefined
      } else {
        if (!state.form.detectDate) {
          state.form.detectDate = new Date().getTime()
        }
        if (!state.detectTimeString) {
          state.detectTimeString = DateUtils.fromTs(state.form.detectDate, 'yyyy-MM-dd')
        }
      }

      console.info("openAccordionList = ", state.openAccordionList)
    }

    watch(() => state.openAccordionList, (n, o) => {
      console.info("openAccordionList change", n, o)
    })

    onMounted(async () => {
      const intervallId = setInterval(async () => {
        //感觉是ionic的bug, textarea-wrapper的高度设置被设置为nativeInput的scrollHeight的高度, 但是scrollHeight高度为0, 导致控件显示不出来
        if (state.notDetectRemarkRef) {
          const textarea: HTMLTextAreaElement = await state.notDetectRemarkRef.$el.getInputElement()
          if (textarea) {
            if (textarea.parentElement) {
              textarea.parentElement.style.height = "auto"
            }
            textarea.style.height = "auto"
            console.log(textarea)

            clearInterval(intervallId)
          }
        }
      }, 1000)

      if (!state.form || !state.form.temper1Value) {
        const lastTemper = await createWithLastReport()
        console.info("lastTemper = ", lastTemper)
        if (lastTemper) {
          Object.assign(state.form, lastTemper)
        } else {
          state.form.cohabitantHealth = '健康'
          state.form.selfHealth = '健康'
          state.form.detectStatus = '未检测'
        }

        if (lastTemper.detectDate !== undefined) {
          state.form.detectDate = new Date().getTime()
        }
      }

      if (state.form && state.form.detectDate) {
        state.detectTimeString = DateUtils.tsToIsoString(state.form.detectDate)
      }
      if (state.form && state.form.isolationStart) {
        state.isolationStartTimeString = DateUtils.tsToIsoString(state.form.isolationStart)
      }
      if (state.form && state.form.isolationEnd) {
        state.isolationEndTimeString = DateUtils.tsToIsoString(state.form.isolationEnd)
      }
      if (state.form && state.form.symptomDesc) {
        state.symptomDescArray = JSON.parse(state.form.symptomDesc)
      }
      // refreshReportExt()
    })

    const viewFlagCalc = computed(() => {
      if (props.params.isNew === false) {
        if (state.form.status) {
          if (state.form.auditFlag != undefined && state.form.auditFlag != null) {
            return state.form.auditFlag === 1
          } else {
            return state.form.status > Constants.NucleicReportStatus.UNCOMMIT
          }
        }
      }
      return false

    })

    const lastReportCalc = computed(() => {
      if (state.form.lastDate) {
        return state.form.lastDate + '(' + state.form.lastDetectResult + ')' + ', 距今' + state.form.lastDays + '天'
      } else {
        return '未找到上次核酸信息'
      }
    })

    const healthReportCalc = computed(() => {
      if (state.form) {
        return state.form.selfHealth === '涉及新冠疫情' || state.form.cohabitantHealth === '涉及新冠疫情'
      }
      return false
    })

    const symptomCalc = computed(() => {
      return state.form.symptom === '有症状'
    })

    const isolationEndTimeCalc = computed(() => {
      console.info(state.isolationEndTimeString)
      return state.isolationEndTimeString
    })

    const detectResultCalc = computed(() => {
      return state.form.detectStatus === '未检测'
    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
      temperTimeSet,
      detectTypeList: store.getters["dic/detectTypeList"],
      detectResultList: [{label: "阴性", value: "阴性"}, {label: "阳性", value: "阳性"}],
      notDetectReasonList: store.getters["dic/notDetectReasonList"],
      timeFormatCalc: Filters.timeFormatCalc,
      viewFlagCalc,
      lastReportCalc,
      healthReportCalc,
      symptomCalc,
      isolationEndTimeCalc,
      detectResultCalc,
      onLocation,
      selfHealthChange,
      symptomChange,
      accordionChange,
      isolationTimeChange,
      detectStatusChange,
      nucleicReportStatus: Constants.NucleicReportStatus
    }
  }
}
