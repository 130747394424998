
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItemOption, IonModal, IonPage, IonSearchbar, IonTitle, IonToolbar,} from '@ionic/vue';
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {arrowBack, copyOutline, trash} from 'ionicons/icons';
import {RoomInfoVO} from "@/vo/RoomInfoVO";
import {useStore} from "vuex";
import ComplexList from "@/views/component/ComplexList.vue"

export default {
  name: 'RoomSelectionModal',
  components: {
    IonModal,
    IonPage,
    IonSearchbar,
    IonButton,
    IonButtons,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonIcon,
    IonItemOption,
    ComplexList
  },
  props: {
    open: {type: Boolean, default: false},
    modelValue: {type: Object, default: null},
  },
  emits: ['onClose', 'onSelect', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      searchText: string | null,
      event?: Event,
      roomList: Array<RoomInfoVO>,
      filterRoomList: Array<RoomInfoVO>,
    } = {
      searchText: null,
      event: undefined,
      roomList: store.getters["selector/roomList"],
      filterRoomList: store.getters["selector/roomList"]
    }
    const state = reactive(temp);

    const onSelect = (item: RoomInfoVO) => {
      emit('onSelect', item);
      emit('update:modelValue', item);
    }
    const onClose = () => {
      emit('onClose');
    }
    const onSearch = (e: Event) => {
      console.log("onSearch, ", state.searchText, e)
      state.filterRoomList = state.roomList.filter(item => (state.searchText && state.searchText.trim() != '') ? item.roomName.indexOf(state.searchText) >= 0 : true)
    }

    const logPrint = (log: any) => {
      console.log(log)
    }
    onMounted(() => {
      console.info("RoomSelectionModal Mount")
    })

    return {
      ...toRefs(state),
      arrowBack, trash, copyOutline,
      onSelect,
      onClose,
      onSearch,
      logPrint,
    }
  }
}
