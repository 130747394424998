
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {doAudit} from "@/api/isolation";
import {DateUtils} from "@/common/DateUtils";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import {IsolationApplyVO} from "@/vo/IsolationApplyVO";
import {SelectionItemType} from "@/common/TypeDefs";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonDatetimeButton,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    IonDatetime,
    IonModal,
    PhotoUploader,
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      startDate?: string,
      endDate?: string,
      form: IsolationApplyVO,
      isolationTypeList: Array<SelectionItemType>
      event?: Event,
    } = {
      startDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.startDate) : DateUtils.tsToIsoString(new Date().getTime()),
      endDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.endDate) : DateUtils.tsToIsoString(new Date().getTime()),
      form: (props.params && props.params.item) ? props.params.item : {closeContactFlag: false, reportFlag: false},
      isolationTypeList: [{label: '集中隔离(观察)中', value: '集中隔离(观察)中'}, {label: '居家隔离(观察)中', value: '居家隔离(观察)中'}],
      event: undefined,
    }
    const state = reactive(temp);

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const onAudit = async (flag: number, reason?: string) => {
      if (flag === 1) {
        await audit(state.form.id, 0)
      } else if (flag === 0) {
        UIUtils.showAlertConfirm("确认", "是否确认驳回?", async () => {
          await audit(state.form.id, 1, reason)
        }, () => {
          console.log("取消")
        }, "驳回", "取消")
      }
    }

    const audit = async (id: string, reject: number, reason?: string) => {
      try {
        await doAudit(id, reject, reason)
        back()
      } catch (e: any) {
        console.error(e)
        UIUtils.showToast("", e.message, 2000)
      }
    }

    const rejectedCalc = computed(() => {
      return state.form.status === Constants.IsolationStatus.APPLY_REJECT || state.form.status === Constants.IsolationStatus.END_REJECT
    })

    const endCalc = computed(() => {
      return state.form.status !== Constants.IsolationStatus.APPLY_FINISH && state.form.status !== Constants.IsolationStatus.END_REJECT
    })
    onMounted(() => {
      console.log("onMounted")
    })

    onIonViewDidEnter(() => {
      console.log("onIonViewDidEnter")

    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      onAudit,
      rejectedCalc,
      endCalc,
      IsolationStatus: Constants.IsolationStatus,
      timeFormatCalc: Filters.timeFormatCalc,
    }
  }
}
