
import {IonButton, IonButtons, IonCheckbox, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRow, IonToolbar} from '@ionic/vue';
import {arrowBack} from 'ionicons/icons';
import {defineComponent} from "vue";
import {UserInfo} from "@/vo/UserInfo";

export default defineComponent({
  name: 'UserPopSelection',
  props: {
    title: {type: String},
    modelValue: {type: Array, default: null},
    items: {type: Array},
  },
  data() {
    const temp: {
      selection: Array<any>
    } = {
      selection: [],
    }
    return {
      ...temp,
      arrowBack
    }
  },
  unmounted() {
    console.info('unmounted')
  },
  deactivated() {
    console.info('deactivated')
  },
  async mounted() {
    console.info("modelValue = ", this.$props.modelValue)
    this.selection = new Array(this.$props.items ? this.$props.items.length : 0)
    if (this.$props.modelValue && this.items) {
      for (let i = 0; i < this.items.length; i++) {
        let item: any = this.items[i];
        this.$props.modelValue.forEach((checkedItem: any) => {
          if (checkedItem.userId === item.userId) {
            this.selection[i] = item;
            return;
          }
        });
      }
    }
    console.info("selection = ", this.selection)
  },
  computed: {
    checkedFlag() {
      return (curValue: any) => {
        return curValue != null ? this.selection.find((item: any) => item && curValue === item.userId) : false
      };
    }
  },
  methods: {
    onSelect(item: UserInfo, index: number) {
      for (let i = 0; i < this.selection.length; i++) {
        if (i === index) {
          if (this.selection[i]) {
            this.selection[i] = null;
          } else {
            this.selection[i] = item;
          }
        }
      }
    },
    onClear() {
      for (let i = 0; i < this.selection.length; i++) {
        this.selection[i] = null;
      }
    },
    onClose() {
      this.$emit('onClose')
    },
    confirm() {
      const selectionItems = this.selection.filter(item => item != null);
      this.$emit('update:modelValue', selectionItems);
      this.$emit('onClose')
    }
  },
  components: {IonPage, IonFooter, IonGrid, IonRow, IonCol, IonList, IonItem, IonHeader, IonToolbar, IonButton, IonButtons, IonContent, IonCheckbox, IonLabel,},
  emits: ['onClose', 'onSelect', 'update:modelValue'],
});
