
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonList, IonModal, IonPage, IonSelect, IonSelectOption, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {computed, getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {arrowBack} from 'ionicons/icons';
import {SelectChangeEventDetail} from "@ionic/core";
import {IonSelectCustomEvent} from "@ionic/core/dist/types/components";
import {getAuditList, getStatsData} from "@/api/nucleic";
import {AuditStatisticsDataVO} from "@/vo/AuditStatisticsDataVO";
import {Filters} from "@/common/Filters";
import {DateUtils} from "@/common/DateUtils";
import {useStore} from "vuex";

export default {
  name: 'NucleicAbnormalModal',
  components: {IonModal, IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonSelect, IonSelectOption, IonList, IonItem},
  props: {
    type: {type: Number, default: 0},
    deptId: {type: String, default: null},
    open: {type: Boolean, default: false},
  },
  emits: ['onClose'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      event?: Event,
      curType: number,
      isOpen: boolean,
      stats?: AuditStatisticsDataVO,
    } = {
      event: undefined,
      curType: props.type,
      isOpen: props.open,
      stats: undefined,
    }
    const state = reactive(temp);

    const typeChange = (event: IonSelectCustomEvent<SelectChangeEventDetail>) => {
      console.log("typeChange", event)
    }
    const onClose = () => {
      emit('onClose');
      state.isOpen = false;
      state.stats = undefined
    }

    const refresh = async () => {
      const date: Date = store.getters["nucleic/reportDate"]
      state.stats = await getStatsData(props.deptId,DateUtils.fromTs(date.getTime(), 'yyyy-MM-dd'))
    }

    const itemFilterCalc = computed(() => {
      if (state.stats) {
        switch (state.curType) {
          case 0:
            return state.stats.notReportData
          case 1:
            return state.stats.rejectedData
          case 2:
            return state.stats.tempAbnormalData
          case 3:
            return state.stats.nucleicAbnormalData
        }
      }
      return []
    })

    watch(() => props.open, (newValue, oldValue) => { //直接监听
      state.isOpen = newValue;
      if (state.isOpen) {
        refresh()
      }
    });

    watch(() => props.type, (newValue, oldValue) => { //直接监听
      state.curType = newValue;
    });

    onIonViewDidEnter(() => {
      refresh()
    })
    onMounted(() => {
      refresh()
    })

    return {
      ...toRefs(state),
      arrowBack,
      typeChange,
      onClose,
      itemFilterCalc,
      getLastDeptName: Filters.getLastDeptName,
    }
  }
}
