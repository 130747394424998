
import {add, arrowBack, checkmark, chevronDownCircleOutline, close, trash} from "ionicons/icons";
import {getCurrentInstance, onMounted, PropType, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {IndexBarItem, IndexBarList} from "@/common/TypeDefs";

export default {
  name: 'IndexBar',
  components: {},
  props: {
    barItemList: {type: Array as PropType<IndexBarList>, default: null},
    scrollId: {type: String},
  },
  emits: [],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      event?: Event,
      selectedBarIndex: number,
    } = {
      event: undefined,
      selectedBarIndex: -1,
    }
    const state = reactive(temp);

    const onClick = (item: IndexBarItem, index: number) => {
      state.selectedBarIndex = index

      const targetItem: any = document.querySelector("#" + item.refId)
      const scrollItem: any = document.querySelector("#" + props.scrollId);
      if (targetItem && scrollItem) {
        scrollItem.scrollToPoint(0, targetItem.offsetTop)
      }
    }

    onMounted(() => {
      // proxy.$refs[refId];

    })


    return {
      ...toRefs(state),
      add, arrowBack, chevronDownCircleOutline, close, trash, checkmark,
      onClick,
    }
  }
}
