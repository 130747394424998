
import {IonContent, IonHeader, IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar, onIonViewDidEnter, TabsCustomEvent, useIonRouter} from '@ionic/vue';
import {alertCircleOutline, arrowBack, checkmarkDoneOutline, closeCircleOutline, pencilOutline} from 'ionicons/icons';
import {NaviUtils} from "@/common/NaviUtils";
import {getCurrentInstance, reactive, toRefs} from "vue";

export default {
  components: {IonHeader, IonToolbar, IonTitle, IonContent, IonPage, IonIcon, IonTabBar, IonTabs, IonLabel, IonTabButton, IonRouterOutlet},
  setup() {
    const {proxy}: any = getCurrentInstance()
    const ionRouter = useIonRouter();
    let temp: {
      tabs: any,
      childTab: any,
      event?: Event,
    } = {
      tabs: null,
      childTab: null,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navReplace("/home")
    }

    const beforeTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const afterTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    onIonViewDidEnter(() => {
      console.log("tabs onIonViewDidEnter", state)
      // fireLifecycle(state.childTab.componenets[0].vueComponent, state.childTab.components[0].vueComponentRef, "ionViewDidEnter")

      state.childTab.components.forEach((component: any) => {
        if (state.childTab.injectedRoute.path === component.pathname) {
          component.vueComponentRef._value.doRefresh(null)
        }
      })
    })
    return {
      ...toRefs(state),
      arrowBack,
      checkmarkDoneOutline,
      alertCircleOutline,
      pencilOutline,
      closeCircleOutline,
      back,
      beforeTabChange,
      afterTabChange,
    }
  }
}
