
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonItemSliding,
  IonLabel,
  IonList,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter
} from '@ionic/vue';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, defineComponent, getCurrentInstance, reactive, toRefs, watch} from "vue";
import {add, arrowBack, checkmark, chevronDownCircleOutline, trash} from "ionicons/icons";
import {doAuditCommit, getAuditList} from "@/api/nucleic"
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {useStore} from "vuex";
import {Filters} from "@/common/Filters";
import {Constants} from "@/common/Constants";
import {UserInfo} from "@/vo/UserInfo";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {NucleicAuditVO} from "@/vo/NucleicAuditVO";
import {NucleicAuditListVO} from "@/vo/NucleicAuditListVO";
import {UIUtils} from "@/common/UIUtils";
import NucleicAbnormalModal from "@/views/component/NucleicAbnormalModal.vue";
import InputModal from "@/views/component/InputModal.vue";
import {DateUtils} from "@/common/DateUtils";

export default defineComponent({
  components: {
    IonPage,
    IonIcon,
    IonItemSliding,
    IonRefresher,
    IonRefresherContent,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonLabel,
    IonItem,
    IonList,
    IonImg,
    IonContent,
    NucleicAbnormalModal,
    InputModal,
  },
  props: {
    type: {
      type: Number, default: null
    },
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      list: NucleicAuditListVO,
      user: UserInfo,
      abnormalModalOpen: boolean,
      abnormalModalType: number,
      abnormalModalDeptId?: string,
      remarkModalOpen: boolean,
      remark?: string,
      currentDeptId?: string,
      remarkViewFlag: boolean,
      event?: Event,
    } = {
      list: new NucleicAuditListVO("", [], []),
      user: store.getters['user/userInfo'],
      abnormalModalOpen: false,
      abnormalModalType: 0,
      abnormalModalDeptId: undefined,
      remarkModalOpen: false,
      remark: undefined,
      currentDeptId: undefined,
      remarkViewFlag: false,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const viewFlagFunc = (item: NucleicAuditVO | NucleicTemperInfo) => {
      return false
    }

    const viewFlagCalc = computed(() => {
      return (item: NucleicAuditVO) => viewFlagFunc(item)
    })

    const openAuditUnit = async (vo: NucleicAuditVO) => {
      store.commit('audit/pushDeptId', {deptId: vo.reportUnitId, deptName: vo.reportUnitName})
      NaviUtils.navPush("/nucleic/audit/detail/list/1")
    }

    const fetchData = async () => {
      const date: Date = store.getters["nucleic/reportDate"]
      const data = await getAuditList(props.type, DateUtils.fromTs(date.getTime(), 'yyyy-MM-dd'))

      store.dispatch("nucleic/getReportCount")
      return data
    }

    const doRefresh = async (event: RefresherCustomEvent | null) => {
      try {
        state.list = await fetchData()
      } finally {
        if (event) {
          event.target.complete()
        }
      }
    }

    const submitAudit = async (deptId: string, event?: Event) => {
      if (event) {
        event.cancelBubble = true
        event.preventDefault()
      }
      UIUtils.showAlertConfirm("确认", "是否确认提交?", async () => {
        try {
          const date: Date = store.getters["nucleic/reportDate"]
          await doAuditCommit(deptId, deptId, false, DateUtils.fromTs(date.getTime(), 'yyyy-MM-dd'))
          doRefresh(null)
        } catch (e: any) {
          if (e.code && e.code === Constants.ApiCode.ERROR_NUCLEIC_NOT_REPORT_IS_NOT_EMPTY) {
            UIUtils.showAlertConfirm("确认", e.msg + ", 依然要提交?", async () => {
              //打开备注页
              state.currentDeptId = deptId
              state.remarkModalOpen = true
            }, () => {
              console.info("不提交")
            })
            console.error(e)
          } else {
            UIUtils.showToast("", e.message, 2000)
          }
        }
      }, () => {
        console.log("取消提交")
      })
    }

    const auditIsNotEmpty = (item: NucleicAuditListVO) => {
      return item.auditItems.length > 0 || item.temperItems.length > 0;
    }

    const openAbnormalModal = (type: number, item: NucleicAuditVO) => {
      console.log("openAbnormalModal")
      state.abnormalModalDeptId = item.reportUnitId
      state.abnormalModalOpen = true
      state.abnormalModalType = type
    }

    const openRemark = (item: NucleicAuditVO) => {
      state.remarkModalOpen = true
      state.remarkViewFlag = true
      state.remark = item.remark
    }

    const onRemarkClose = async (flag: number) => {
      state.remarkModalOpen = false
      state.remarkViewFlag = false
      if (flag === 1) {
        if (state.currentDeptId) {
          try {
            const date: Date = store.getters["nucleic/reportDate"]
            await doAuditCommit(state.currentDeptId, state.currentDeptId, true, DateUtils.fromTs(date.getTime(), 'yyyy-MM-dd'), state.remark)
            doRefresh(null)
            state.remark = undefined
          } catch (e2: any) {
            UIUtils.showToast("", e2.message, 2000)
          }
        }
      } else {
        state.remark = undefined
        console.warn("state.currentDeptId 为空")
      }
    }

    watch(() => store.getters["nucleic/reportDate"], (n, o) => {
      doRefresh(null)
    })
    //此处页面刷新不生效，需要在tabs页监听
    onIonViewDidEnter(async () => {
      await doRefresh(null)
    })

    return {
      ...toRefs(state),
      arrowBack, add, trash, checkmark,
      chevronDownCircleOutline,
      openAuditUnit,
      submitAudit,
      doRefresh,
      back,
      auditIsNotEmpty,
      openAbnormalModal,
      onRemarkClose,
      openRemark,
      statusNameCalc: Filters.getCleanStatusName,
      viewFlagCalc,
      timeFormatCalc: Filters.timeFormatCalc,
      NucleicStatus: Constants.NucleicReportStatus,
      nucleicAuditStatusNameCalc: Filters.getNucleicAuditStatusName,
    }
  }
})
