import axios from "@/common/AxiosExt";
import {API} from "./config";
import {GridResult} from "@/vo/GridResult";
import {RoomCleanVO} from "@/vo/RoomCleanVO";
import {BadgeItem} from "@/common/TypeDefs";

export async function getList(pageNo: number, pageSize: number, type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: GridResult<RoomCleanVO> = await axios.get(API.GET_ROOMCLEAN_LIST, {params: {pageNo, pageSize, type}, headers});
    return data;
}

export async function getCount(type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: BadgeItem = await axios.get(API.GET_ROOMCLEAN_COUNT, {params: {type}, headers});
    return data;
}

export async function doEdit(room: RoomCleanVO) {
    await axios.post(API.SAVE_ROOMCLEAN, room);
}


export async function doDelete(ids: Array<string>) {
    await axios.post(API.DELETE_ROOMCLEAN, ids);
}

export async function getLastReport(areaId: string): Promise<RoomCleanVO> {
    return await axios.get(API.GET_ROOMCLEAN_LAST_REPORT, {params: {areaId}});
}
