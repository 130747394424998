
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonPage, IonTextarea, IonTitle, IonToolbar} from '@ionic/vue';
import {getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {arrowBack} from 'ionicons/icons';
import {InputChangeEventDetail} from "@ionic/core";
import {IonInputCustomEvent} from "@ionic/core/dist/types/components";

export default {
  name: 'InputModal',
  components: {IonModal, IonTextarea, IonPage, IonContent, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle},
  props: {
    open: {type: Boolean, default: false},
    title: {type: String, default: null},
    btnText: {type: String, default: null},
    placeholder: {type: String, default: null},
    modelValue: {type: String, default: null},
    viewFlag: {type: Boolean, default: false},
  },
  emits: ['onClose', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      event?: Event,
      isOpen: boolean,
      text?: string,
    } = {
      event: undefined,
      isOpen: props.open,
      text: props.modelValue,
    }
    const state = reactive(temp);

    const textChange = (event: IonInputCustomEvent<InputChangeEventDetail>) => {
      emit('update:modelValue', event.detail.value);
    }
    const onClose = (flag: number) => {
      emit('onClose', flag);
      emit('update:modelValue', state.text);
      state.isOpen = false;
    }

    watch(() => props.open, (newValue, oldValue) => { //直接监听
      state.isOpen = newValue;
    });
    watch(() => props.modelValue, (newValue, oldValue) => { //直接监听
      state.text = newValue;
    });

    onMounted(() => {
      console.info("InputModal Mount")
    })
    return {
      ...toRefs(state),
      arrowBack,
      textChange,
      onClose,
    }
  }
}
