import axios from "@/common/AxiosExt";
import {API} from "./config";
import qs from "qs";
import {LoginResult} from "@/vo/LoginResult";
import {SystemUserInfo} from "@/vo/SystemUserInfo";
import {Constants} from "@/common/Constants";

export async function login(username: string, password: string) {
    const data: LoginResult = await axios.post(API.LOGIN, qs.stringify({
        username,
        password,
        client_id: '22eb6d8ca24f41c38ddc24a8afc554fa',
        client_secret: '9gGz50K3dfX4UoYSJN0ms24Pb08730uAPNT7WRionCsGdDm1I6AEdTKG0P2ZTwU6'
    }));
    return data;
}

export async function updatePass(accountId: string, pass: string | null, oldpass: string | null) {
    await axios.put(API.UPDATE_PASS.replace('{accountId}', accountId), qs.stringify({
        pass,
        oldpass,
        client_id: '22eb6d8ca24f41c38ddc24a8afc554fa',
        client_secret: '9gGz50K3dfX4UoYSJN0ms24Pb08730uAPNT7WRionCsGdDm1I6AEdTKG0P2ZTwU6'
    }));
}

export async function getSystemUser() {
    let userInfo = localStorage.getItem(Constants.USER_INFO);
    if (userInfo === null) {
        const data: SystemUserInfo = await axios.get(API.GET_USER);
        console.info("userData = ", data);
        userInfo = JSON.stringify(data)
        localStorage.setItem(Constants.USER_INFO, userInfo)
    }
    return JSON.parse(userInfo);
}

export async function logout(token: string) {
    await axios.post(API.LOGOUT, {token}, {headers: {'authorization': 'IGNORED'}});
}
