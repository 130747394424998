
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonPage, IonSearchbar, IonTitle, IonToolbar,} from '@ionic/vue';
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {arrowBack, copyOutline, trash} from 'ionicons/icons';
import {useStore} from "vuex";
import ComplexList from "@/views/component/ComplexList.vue"
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {searchByUser} from "@/api/nucleic";
import {UIUtils} from "@/common/UIUtils";
import NucleicAuditDetailSliderPage from "@/views/nucleic/audit/NucleicAuditDetailSliderPage.vue";

export default {
  name: 'RoomSelectionModal',
  components: {
    IonModal,
    IonPage,
    IonSearchbar,
    IonButton,
    IonButtons,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar,
    ComplexList
  },
  props: {
    open: {type: Boolean, default: false},
    modelValue: {type: Object, default: null},
  },
  emits: ['onClose', 'onSelect', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      searchText: string | null,
      event?: Event,
      list: Array<NucleicTemperInfo>,
    } = {
      searchText: null,
      event: undefined,
      list: []
    }
    const state = reactive(temp);

    const onClose = () => {
      emit('onClose');
    }
    const onSearch = async (e: Event) => {
      console.log("onSearch, ", state.searchText, e)
      if (state.searchText && state.searchText.trim() !== '') {
        state.list = await searchByUser(state.searchText)
      }
    }

    const openAuditTemper = async ({item, index}: { item: NucleicTemperInfo, index: number }) => {
      console.info("openAuditTemper")
      if (item) {
        const viewFlag = false
        await UIUtils.showModal(NucleicAuditDetailSliderPage, {title: viewFlag ? '查看' : '审核', index, viewFlag, auditItems: {temperItems: state.list}})
      }
    }

    onMounted(() => {
      console.info("RoomSelectionModal Mount")
    })

    return {
      ...toRefs(state),
      arrowBack, trash, copyOutline,
      onClose,
      onSearch,
      openAuditTemper,
    }
  }
}
