import {actionSheetController, alertController, loadingController, modalController, popoverController, toastController} from "@ionic/vue";
import {ActionSheetButton} from "@ionic/core/dist/types/components/action-sheet/action-sheet-interface";
import {camera, imageOutline} from 'ionicons/icons';
import {Camera, CameraResultType, CameraSource} from "@capacitor/camera";
import {GalleryPhotos, Photo} from "@capacitor/camera/dist/esm/definitions";
import {ToastButton} from "@ionic/core/dist/types/components/toast/toast-interface";
import {uuid} from "vue-uuid";
import {PhotoViewer} from "@awesome-cordova-plugins/photo-viewer";
import {Capacitor} from "@capacitor/core";

export class UIUtils {
    private static currentModal: HTMLIonModalElement | null;
    public static currentPopover: HTMLIonPopoverElement | null;
    public static modalMap: { [id: string]: HTMLIonModalElement } = {};

    private static loadingSingleFlag = false;


    public static async showToast(header: string, message: string, duration: number, position?: 'top' | 'bottom' | 'middle',
                                  icon?: string, buttons?: Array<ToastButton | string>) {
        const toast = await toastController
            .create({
                header: header,
                message: message,
                icon: icon,
                position: position,
                buttons: buttons,
                duration: duration,
            })
        console.info(`显示toast：${message}`)
        await toast.present();

        const {role} = await toast.onDidDismiss();
        console.log('onDidDismiss resolved with role', role);
    }

    public static async showLoading(message: string, duration?: number, cssClass?: string): Promise<() => void> {
        const loading = await loadingController
            .create({
                spinner: null,
                duration: duration,
                message: message,
                translucent: true,
                cssClass: 'custom-class custom-loading',
                backdropDismiss: true
            });

        await loading.present();

        if (duration && duration > 0) {
            setTimeout(function () {
                loading.dismiss()
            }, duration);
        }

        return () => {
            loading.dismiss();
        };
    }

    public static async showLoadingSingle(message: string, duration?: number, cssClass?: string): Promise<() => void> {
        if (UIUtils.loadingSingleFlag) {
            return () => {
                console.info("showLoadingSingle do nothing")
            }
        }
        UIUtils.loadingSingleFlag = true
        const loading = await loadingController
            .create({
                spinner: null,
                duration: duration,
                message: message,
                translucent: true,
                cssClass: 'custom-class custom-loading',
                backdropDismiss: true
            });

        await loading.present();

        if (duration && duration > 0) {
            setTimeout(function () {
                loading.dismiss()
                UIUtils.loadingSingleFlag = true
            }, duration);
        }

        return () => {
            loading.dismiss();
            UIUtils.loadingSingleFlag = false
        };
    }


    public static async showModal(component: any, params: any, onDismiss?: (data: any) => void) {
        const id = uuid.v4()
        const modal = await modalController
            .create({
                id: id,
                component: component,
                cssClass: 'my-custom-class',
                componentProps: {
                    params,
                    modalId: id
                },
            });

        UIUtils.currentModal = modal
        UIUtils.modalMap[id] = modal
        console.log(UIUtils.modalMap)
        // if (onDismiss) {
        //     modal.onDidDismiss(onDismiss)
        // }

        await modal.present()
        return modal.onDidDismiss();
    }

    public static dismissModal(id?: string) {
        if (id) {
            UIUtils.modalMap[id].dismiss().then(() => {
                delete UIUtils.modalMap[id];
            });
        } else if (UIUtils.currentModal) {
            UIUtils.currentModal.dismiss().then(() => {
                UIUtils.currentModal = null;
            });
        }
    }

    public static async showPopup(component: any, ev: Event) {
        const popover = await popoverController.create({
            component: component,
            event: ev,
            translucent: true,
        });
        UIUtils.currentPopover = popover;
        return popover.present();
    }

    public static dismissPopover() {
        if (UIUtils.currentPopover) {
            UIUtils.currentPopover.dismiss().then(() => {
                UIUtils.currentPopover = null;
            });
        }
    }

    public static async showActionSheet(title: string, buttons: (ActionSheetButton | string)[]) {
        const actionSheet = await actionSheetController
            .create({
                header: title,
                cssClass: 'my-custom-class',
                buttons: buttons,
            });
        await actionSheet.present();

        const {role, data} = await actionSheet.onDidDismiss();
        return data;
    }

    public static async showPicturePick() {
        let fileUrls: Array<string> = [];
        if (Capacitor.getPlatform() === 'web') {
            //浏览器只允许上传图片
            const image: Photo = await Camera.getPhoto({
                quality: 90,
                allowEditing: false,
                source: CameraSource.Photos,
                resultType: CameraResultType.Base64
            });
            if (image.base64String) {
                fileUrls = ['data:image/' + image.format + ';' + image.base64String];
            }
        } else {
            const buttons = [{
                text: '选择图片',
                role: 'destructive',
                icon: imageOutline,
                id: 'select',
                data: {action: 'select'},
                handler: () => {
                    console.log('选择图片')
                },
            }, {
                text: '现在拍摄',
                role: 'destructive',
                icon: camera,
                id: 'camera',
                data: {action: 'camera'},
                handler: () => {
                    console.log('现在拍摄')
                },
            }];

            const data = await this.showActionSheet('选择图片', buttons);
            if (data.action === 'camera') {
                const image: Photo = await Camera.getPhoto({
                    quality: 90,
                    allowEditing: false,
                    source: CameraSource.Camera,
                    resultType: CameraResultType.Uri,
                    saveToGallery: true,
                    promptLabelHeader: '上传照片',
                    promptLabelPhoto: '从相册中选择',
                    promptLabelPicture: '立即拍照',
                });

                // image.webPath will contain a path that can be set as an image src.
                // You can access the original file using image.path, which can be
                // passed to the Filesystem API to read the raw data of the image,
                // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
                if (image.path) {
                    fileUrls = [image.path];
                }
            } else if (data.action === 'select') {
                const images: GalleryPhotos = await Camera.pickImages({quality: 90});
                if (images.photos && images.photos.length > 0) {
                    images.photos.forEach(photo => {
                        if (photo.path) {
                            fileUrls.push(photo.path);
                        }
                    });
                }
                console.log(images);
            }
        }

        return fileUrls;
    }

    public static async showAlertConfirm(title: string, message: string, okHandler: () => void, cancelHandler: () => void, okBtnName?: string, cancelBtnName?: string, backdropDismiss?: boolean) {
        const alert = await alertController.create({
            cssClass: 'my-custom-class',
            header: title,
            message: message,
            backdropDismiss,
            buttons: [
                {
                    text: cancelBtnName ? cancelBtnName : '取消',
                    role: 'cancel',
                    cssClass: 'secondary',
                    id: 'cancel-button',
                    handler: cancelHandler,
                },
                {
                    text: okBtnName ? okBtnName : '确定',
                    id: 'confirm-button',
                    handler: okHandler,
                },
            ],
        });
        return alert.present();
    }

    public static previewImage(url: string) {
        setTimeout(() => {
            console.info("previewImage, url = " + url);
            PhotoViewer.show(url, '', {
                share: true,
                closeButton: true,
                copyToReference: false,
                headers: "",
                piccasoOptions: {}
            });
        }, 500);
    }
}
