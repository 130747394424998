
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  TabsCustomEvent,
  useIonRouter,
} from '@ionic/vue';
import {alertCircleOutline, arrowBack, checkmarkDoneOutline, closeCircleOutline, pencilOutline} from 'ionicons/icons';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {Constants} from "@/common/Constants";
import SignatureSelectionModal from "@/views/component/SignatureSelectionModal.vue";
import {SignatureItemType, SignatureResultType} from "@/common/TypeDefs";
import {updateTableSign} from "@/api/common";
import {UIUtils} from "@/common/UIUtils";
import {DateUtils} from "@/common/DateUtils";
import {Filters} from "@/common/Filters";
import {getList} from "@/api/toolsclean";
import {ToolsCleanVO} from "@/vo/ToolsCleanVO";

export default {
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonTabBar,
    IonTabs,
    IonLabel,
    IonTabButton,
    IonRouterOutlet,
    IonBadge,
    IonButton,
    IonButtons,
    SignatureSelectionModal
  },
  setup() {
    const {proxy}: any = getCurrentInstance()
    const ionRouter = useIonRouter()
    const store = useStore()
    let temp: {
      tabs: any,
      childTab: any,
      signatureSelectionOpenFlag: boolean,
      signaleItems: Array<SignatureItemType>,
      event?: Event,
    } = {
      tabs: null,
      childTab: null,
      signatureSelectionOpenFlag: false,
      signaleItems: [],
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navReplace("/home")
    }

    const beforeTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const afterTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const onSignature = async (items: Array<SignatureResultType>) => {
      console.log("onSignatureSelect", items)

      onSignatureClose()

      try {
        await updateTableSign({ids: items.map(item => item.id), table: 'tools_clean_t', dataURL: items[0].dataURL})
      } catch (e: any) {
        console.error(e)
        UIUtils.showToast("", e.message, 2000)
      } finally {
        refreshCurrent()
      }
    }
    const onSignatureClose = async () => {
      state.signatureSelectionOpenFlag = false
    }

    const signatureSelectionOpen = async () => {
      const {total, list} = await getList(1, 10000, 2, false)
      state.signaleItems = list.map((item: ToolsCleanVO) => {
        const name = item.details.flatMap(detail => {
          if (detail.items) {
            return detail.items
          } else if (detail.itemNames) {
            return detail.itemNames.map(t => {
              return {name: t}
            })
          }
          return []
        }).map(item => item.name).reduce((t, i) => t + "," + i)
        return {
          id: item.id ? item.id : '',
          name: name ? name : '',
          time: item.createTime ? DateUtils.fromTs(item.createTime, DateUtils.FULL_DATE_PATTERN) : null,
        }
      })

      state.signatureSelectionOpenFlag = true
    }

    const refreshCurrent = () => {
      if (state.childTab) {
        state.childTab.components.forEach((component: any) => {
          if (state.childTab.injectedRoute.path === component.pathname) {
            component.vueComponentRef._value.doRefresh(null)
          }
        })
      }
    }

    onIonViewDidEnter(() => {
      console.log("tabs onIonViewDidEnter", state)
      refreshCurrent()
    })

    const processableCountCalc = computed(() => {
      if (store.getters["clean/toolsCleanCount"]) {
        return Filters.statusNum(store.getters["clean/toolsCleanCount"], [Constants.CleanStatus.BEGIN, Constants.CleanStatus.REJECT, Constants.CleanStatus.COMMIT])
      }
      return 0
    })

    const processingCountCalc = computed(() => {
      if (store.getters["clean/toolsCleanCount"]) {
        return Filters.statusNum(store.getters["clean/toolsCleanCount"], [Constants.CleanStatus.SIGNED, Constants.CleanStatus.ARCHIVE])
      }
      return 0
    })

    return {
      ...toRefs(state),
      arrowBack,
      checkmarkDoneOutline,
      alertCircleOutline,
      pencilOutline,
      closeCircleOutline,
      back,
      beforeTabChange,
      afterTabChange,
      onSignature,
      onSignatureClose,
      signatureSelectionOpen,
      processableCountCalc,
      processingCountCalc,
    }
  }
}
