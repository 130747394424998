
import {DatetimeCustomEvent, IonButton, IonCol, IonContent, IonDatetime, IonFooter, IonGrid, IonHeader, IonLabel, IonModal, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/vue';
import {computed, getCurrentInstance, reactive, toRefs, watch} from "vue";
import {parse, parseISO} from 'date-fns';
import {format} from 'date-fns-tz';
import {Constants} from "@/common/Constants";
import {DateUtils} from "@/common/DateUtils";

export default {
  name: 'DateTimePicker',
  components: {
    IonCol,
    IonGrid,
    IonRow,
    IonLabel,
    IonModal,
    IonContent,
    IonDatetime,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonPage,
    IonButton,
    IonFooter
  },
  props: {
    title: {type: String},
    required: {type: Boolean},
    mode: {type: Number},
    viewFlag: {type: Boolean, default: false},
    placeholder: {type: String},
    textClass: {type: String},
    placeholderClass: {type: String},
    modelValue: {type: String},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    const formatStr = () => {
      if (props.mode === Constants.ItemTypes.DATE_PICKER) {
        return 'yyyy-MM-dd';
      } else if (props.mode === Constants.ItemTypes.TIME_PICKER) {
        return 'HH:mm';
      } else if (props.mode === Constants.ItemTypes.DATETIME_PICKER) {
        return 'yyyy-MM-dd HH:mm';
      } else {
        return 'yyyy-MM-dd HH:mm'
      }
    }

    const getInitDate = (date: any) => {
      if (date) {
        return parse(props.modelValue, formatStr(), new Date()).toISOString()
      }
      return null
    }

    const getIonicDate = (date: any) => {
      if (date) {
        const date = parse(props.modelValue, formatStr(), new Date())
        return DateUtils.convertTime(date, true, new Date()).toISOString()
      }
      return null
    }

    let temp: {
      openState: boolean,
      //本地时间
      dateTimeValue?: string | null,
      //UTC时间,ionic时间选择框只能使用utc时间
      ionDateTimeValue?: string | null,
      firstChange: boolean,
      event?: Event,
    } = {
      openState: false,
      dateTimeValue: getInitDate(props.modelValue),
      ionDateTimeValue: getIonicDate(props.modelValue),
      firstChange: props.modelValue ? false : true,
      event: undefined,
    }
    const state = reactive(temp);

    const open = () => {
      state.openState = true
    }

    const selectItemLabel = computed(() => {
      return '';
    })


    const dateTimeChange = (ev: DatetimeCustomEvent) => {
      const value = ev.detail.value as string
      if (ev.detail.value && !value.endsWith("Z")) {
        let dateIso = parseISO(value)
        if (state.firstChange) {
          dateIso = new Date(dateIso.getTime() - dateIso.getTimezoneOffset() * 60 * 1000)
          state.ionDateTimeValue = dateIso.toISOString()
          state.firstChange = false
        } else {
          state.ionDateTimeValue = value
        }
        state.dateTimeValue = state.ionDateTimeValue //format(DateUtils.convertTime(parseISO(state.ionDateTimeValue), false, new Date()), formatStr())
        console.info("dateTimeChange, dateTimeValue, dateIso", state.ionDateTimeValue, state.dateTimeValue, dateIso)
        // emit('update:modelValue', state.dateTimeValue)
      }
    }

    const clear = () => {
      state.dateTimeValue = null
      state.ionDateTimeValue = null
      emit('update:modelValue', state.ionDateTimeValue)
      state.openState = false
    }

    const cancel = () => {
      state.openState = false
    }

    const confirm = () => {
      //如果没有选择日期点击了确定 那么无法获得change事件 此时需要设置为当前时间
      if (state.ionDateTimeValue === null) {
        const now = new Date()
        const normalDate = new Date(now.getTime() - now.getTimezoneOffset() * 60 * 1000)
        state.ionDateTimeValue = new Date().toISOString()
        state.dateTimeValue = new Date().toISOString()
        emit('update:modelValue', format(now, formatStr()))

        console.info("ionDateTimeValue confirm = " + state.dateTimeValue)
      } else {
        if (state.dateTimeValue) {
          if (state.dateTimeValue.endsWith("Z")) {
            state.dateTimeValue = state.dateTimeValue.substring(0, state.dateTimeValue.length - 1) + "+0800"
          }
          const normalTime = parseISO(state.dateTimeValue)
          state.dateTimeValue = normalTime ? normalTime.toISOString() : null
          emit('update:modelValue', format(normalTime, formatStr()))

          console.info("dateTimeValue confirm = " + state.dateTimeValue)
        }
      }

      state.openState = false
    }

    const presentationCalc = computed(() => {
      if (props.mode === Constants.ItemTypes.DATE_PICKER) {
        return 'date';
      } else if (props.mode === Constants.ItemTypes.TIME_PICKER) {
        return 'time';
      } else if (props.mode === Constants.ItemTypes.DATETIME_PICKER) {
        return 'date-time';
      }
      return '';
    })

    const dateTimeValueCalc = computed(() => {
      if (state.dateTimeValue) {
        const dateIso = parseISO(state.dateTimeValue)
        const dateStr = format(dateIso, formatStr())
        console.info("dateTimeValueCalc", dateIso, dateStr)
        return dateStr
      } else {
        return props.placeholder
      }
    })

    const textLabelClassCalc = computed(() => {
      if (!state.dateTimeValue) {
        if (props.placeholderClass) {
          return {[props.placeholderClass]: true}
        } else {
          return {"report-placeholder-class": true}
        }
      }
      if (props.textClass) {
        return {[props.textClass]: true}
      } else {
        return {"report-item-class": true}
      }
    })

    watch(() => props.modelValue, (newValue, oldValue) => {
      if (newValue) {
        const date = parse(newValue, formatStr(), new Date())
        state.dateTimeValue = date.toISOString()
        state.ionDateTimeValue = DateUtils.convertTime(date, true, new Date()).toISOString()
      } else {
        state.dateTimeValue = null
        state.ionDateTimeValue = null
      }
    })
    return {
      ...toRefs(state),
      selectItemLabel,
      open,
      confirm,
      clear,
      cancel,
      dateTimeChange,
      presentationCalc,
      dateTimeValueCalc,
      textLabelClassCalc,
    }
  }
}
