import {App} from "vue";
import {createStore} from "vuex";

const modules: any = {};
const files = require.context('./modules', false, /\.ts$/)
files.keys().forEach((key) => {
    modules[key.replace(/(modules|\/|\.|ts)/g, '')] = {
        ...files(key).default,
        namespaced: true,
    }
})

const store = createStore({modules})

export function setupStore(app: App) {
    console.log("initing store...")
    app.use(store)
}

export default store
