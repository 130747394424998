import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, { "is-open": _ctx.isOpen }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_page, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_buttons, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.onClose(0)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            style: {"color":"black"},
                            icon: $setup.arrowBack
                          }, null, 8 /* PROPS */, ["icon"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (!$props.viewFlag)
                    ? (_openBlock(), _createBlock(_component_ion_buttons, {
                        key: 0,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.onClose(1)))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString($props.btnText), 1 /* TEXT */)
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_textarea, {
                disabled: $props.viewFlag,
                modelValue: _ctx.text,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.text) = $event)),
                placeholder: $props.placeholder,
                onIonChange: $setup.textChange
              }, null, 8 /* PROPS */, ["disabled", "modelValue", "placeholder", "onIonChange"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}