import axios from "@/common/AxiosExt";
import {API} from "./config";
import {MaterialApplyVO} from "@/vo/MaterialApplyVO";
import {GridResult} from "@/vo/GridResult";
import {ProcessButton} from "@/vo/ProcessButton";

export async function materialApply(report: any) {
    const data: string = await axios.post(API.MATERIAL_APPLY, report);
    return data;
}

export async function getList(status: number, pageNo: number, pageSize: number) {
    const data: GridResult<MaterialApplyVO> = await axios.get(API.GET_MATERIAL_LIST, {params: {status, pageNo, pageSize}});
    return data;
}

export async function getProcessableMaterialApplyList(status: number, pageNo: number, pageSize: number) {
    const data: GridResult<MaterialApplyVO> = await axios.get(API.GET_PROCESSABLE_MATERIAL_LIST, {params: {status, pageNo, pageSize}});
    return data;
}

export async function getButtons(id: string) {
    const data: Array<ProcessButton> = await axios.get(API.GET_MATERIAL_PAGE_BUTTON, {params: {id}});
    return data;
}
