
import {
  IonBadge,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
  TabsCustomEvent,
  useIonRouter
} from '@ionic/vue';
import {alertCircleOutline, arrowBack, checkmarkDoneOutline, closeCircleOutline, pencilOutline} from 'ionicons/icons';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, getCurrentInstance, reactive, toRefs} from "vue";
import {useStore} from "vuex";
import {Constants} from "@/common/Constants";
import {useRoute} from "vue-router";
import {Filters} from "@/common/Filters";

export default {
  components: {
    IonHeader,
    IonButtons,
    IonButton,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonIcon,
    IonTabBar,
    IonTabs,
    IonLabel,
    IonTabButton,
    IonRouterOutlet,
    IonBadge,
  },
  setup() {
    const {proxy}: any = getCurrentInstance()
    const ionRouter = useIonRouter()
    const store = useStore()
    const route = useRoute()
    let temp: {
      tabsRef: any,
      childTab: any,
      event?: Event,
    } = {
      tabsRef: null,
      childTab: null,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navReplace("/home")
    }

    const beforeTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const afterTabChange = async (event: TabsCustomEvent) => {
      console.log(event)
    }

    const refreshCurrent = () => {
      if (state.childTab) {
        state.childTab.components.forEach((component: any) => {
          if (state.childTab.injectedRoute.path === component.pathname) {
            component.vueComponentRef._value.doRefresh(null)
          }
        })
      }
    }

    onIonViewDidEnter(() => {
      console.log("tabs onIonViewDidEnter", state)
      refreshCurrent()
    })

    const processableCountCalc = computed(() => {
      console.log("processableCountCalc")
      if (store.getters["suspend/suspendApplyCount"]) {
        return Filters.statusNum(store.getters["suspend/suspendApplyCount"], [Constants.ApplyStatus.UNCOMMIT, Constants.ApplyStatus.REJECT])
      }
      return 0
    })

    const processingCountCalc = computed(() => {
      console.log("processingCountCalc")
      if (store.getters["suspend/suspendApplyCount"]) {
        return Filters.statusNum(store.getters["suspend/suspendApplyCount"], [Constants.ApplyStatus.COMMIT, Constants.ApplyStatus.FINISH])
      }
      return 0
    })


    return {
      ...toRefs(state),
      arrowBack,
      checkmarkDoneOutline,
      alertCircleOutline,
      pencilOutline,
      closeCircleOutline,
      back,
      beforeTabChange,
      afterTabChange,
      processableCountCalc,
      processingCountCalc,
    }
  }
}
