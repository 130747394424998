
import {IonApp, IonRouterOutlet, useIonRouter} from '@ionic/vue';
import {defineComponent} from 'vue';
import {App} from "@capacitor/app";
import {useStore} from "vuex";

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    const store = useStore()
    const ionRouter = useIonRouter();
    console.log("App setup");

    App.addListener('backButton', ({canGoBack}) => {
      if (!canGoBack) {
        App.exitApp();
      } else {
        const backButtonCallback = store.getters["nav/getBackButtonCallback"]
        if (backButtonCallback) {
          const result = backButtonCallback()
          if (result) {
            store.commit("nav/clearBackButtonCallback")
          }
        } else {
          window.history.back();
        }
      }
    });
  }
});
