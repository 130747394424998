import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import JobDetailPage from "@/views/job/JobDetailPage.vue";
import HomePage from "@/views/HomePage.vue";
import InOutPage from "@/views/InOutPage.vue";
import InOutConfirmPage from "@/views/InOutConfirmPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import JobListPage from "@/views/job/JobListPage.vue";
import LogoutPage from "@/views/LogoutPage.vue";
import MaterialHomePage from "@/views/material/MaterialHomePage.vue";
import MaterialApplyPage from "@/views/material/MaterialApplyPage.vue";
import MaterialApplyListPage from "@/views/material/MaterialApplyListPage.vue";
import RoomCleanListPage from "@/views/clean/room/RoomCleanListPage.vue";
import VehicleCleanListPage from "@/views/clean/vehicle/VehicleCleanListPage.vue";
import ToolsCleanListPage from "@/views/clean/tools/ToolsCleanListPage.vue";
import PasswordEditPage from "@/views/PasswordEditPage.vue";
import JobHomePage from "@/views/job/JobHomePage.vue";
import RoomHomePage from "@/views/clean/room/RoomHomePage.vue";
import ToolsHomePage from "@/views/clean/tools/ToolsHomePage.vue";
import VehicleHomePage from "@/views/clean/vehicle/VehicleHomePage.vue";
import NucleicListPage from "@/views/nucleic/report/NucleicListPage.vue";
import NucleicAuditHomePage from "@/views/nucleic/audit/NucleicAuditHomePage.vue";
import NucleicAuditListPage from "@/views/nucleic/audit/NucleicAuditListPage.vue";
import NucleicAuditDetailHomePage from "@/views/nucleic/audit/NucleicAuditDetailHomePage.vue";
import NucleicAuditDetailListPage from "@/views/nucleic/audit/NucleicAuditDetailListPage.vue";
import NucleicSuspendApplyHomePage from "@/views/suspend/apply/NucleicSuspendApplyHomePage.vue";
import NucleicSuspendApplyListPage from "@/views/suspend/apply/NucleicSuspendApplyListPage.vue";
import NucleicSuspendAuditHomePage from "@/views/suspend/audit/NucleicSuspendAuditHomePage.vue";
import NucleicSuspendAuditListPage from "@/views/suspend/audit/NucleicSuspendAuditListPage.vue";
import IsolationApplyHomePage from "@/views/isolation/apply/IsolationApplyHomePage.vue";
import IsolationApplyListPage from "@/views/isolation/apply/IsolationApplyListPage.vue";
import IsolationAuditHomePage from "@/views/isolation/audit/IsolationAuditHomePage.vue";
import IsolationAuditListPage from "@/views/isolation/audit/IsolationAuditListPage.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/home',
        component: HomePage,
    },
    {
        path: '/inout',
        component: InOutPage,
    },
    {
        path: '/inout_confirm/:inOut',
        component: InOutConfirmPage,
    },
    {
        path: '/job/tab/',
        component: JobHomePage,
        children: [
            {
                path: '',
                redirect: '/job/tab/process/0',
            },
            {
                path: 'process/:type',
                component: JobListPage,
                props: true,
            },
        ]
    },
    {
        path: '/job/detail/:showStatus/:id/:jobTitle/:activiId',
        component: JobDetailPage,
        props: true,
    },
    {
        path: '/material/tab/',
        component: MaterialHomePage,
        children: [
            {
                path: '',
                redirect: '/material/tab/apply/10',
            },
            {
                path: 'apply/:type',
                component: MaterialApplyListPage,
                props: true,
            },
        ]
    },
    {
        path: '/material/detail/:id',
        component: MaterialApplyPage,
        props: true,
    },
    {
        path: '/clean/tab/room',
        component: RoomHomePage,
        children: [
            {
                path: '',
                redirect: '/clean/tab/room/0',
            },
            {
                path: ':type',
                component: RoomCleanListPage,
                props: true,
            },
        ]
    },
    {
        path: '/clean/tab/vehicle',
        component: VehicleHomePage,
        children: [
            {
                path: '',
                redirect: '/clean/tab/vehicle/0',
            },
            {
                path: ':type',
                component: VehicleCleanListPage,
                props: true,
            },
        ]
    },
    {
        path: '/clean/tab/tools',
        component: ToolsHomePage,
        children: [
            {
                path: '',
                redirect: '/clean/tab/tools/0',
            },
            {
                path: ':type',
                component: ToolsCleanListPage,
                props: true,
            },
        ]
    },
    {
        path: '/roomclean/list',
        component: RoomCleanListPage,
        props: true,
    },
    {
        path: '/vehicleclean/list',
        component: VehicleCleanListPage,
        props: true,
    },
    {
        path: '/toolsclean/list',
        component: ToolsCleanListPage,
        props: true,
    },
    {
        path: '/nucleic/list',
        component: NucleicListPage,
        props: true,
    },
    {
        path: '/nucleic/audit/',
        component: NucleicAuditHomePage,
        children: [
            {
                path: '',
                redirect: '/nucleic/audit/list/0',
            },
            {
                path: 'list/:type',
                component: NucleicAuditListPage,
                props: true,
            },
        ]
    },
    {
        path: '/nucleic/audit/detail',
        component: NucleicAuditDetailHomePage,
        children: [
            {
                path: '',
                redirect: '/nucleic/audit/detail/list/1',
            },
            {
                path: 'list/:type',
                component: NucleicAuditDetailListPage,
                props: true,
            },
        ]
    },
    {
        path: '/nucleic/suspend/apply',
        component: NucleicSuspendApplyHomePage,
        children: [
            {
                path: '',
                redirect: '/nucleic/suspend/apply/tab/0',
            },
            {
                path: 'tab/:type',
                component: NucleicSuspendApplyListPage,
                props: true,
            },
        ]
    },
    {
        path: '/nucleic/suspend/audit',
        component: NucleicSuspendAuditHomePage,
        children: [
            {
                path: '',
                redirect: '/nucleic/suspend/audit/tab/0',
            },
            {
                path: 'tab/:type',
                component: NucleicSuspendAuditListPage,
                props: true,
            },
        ]
    },
    {
        path: '/isolation/apply',
        component: IsolationApplyHomePage,
        children: [
            {
                path: '',
                redirect: '/isolation/apply/tab/0',
            },
            {
                path: 'tab/:type',
                component: IsolationApplyListPage,
                props: true,
            },
        ]
    },
    {
        path: '/isolation/audit',
        component: IsolationAuditHomePage,
        children: [
            {
                path: '',
                redirect: '/isolation/audit/tab/0',
            },
            {
                path: 'tab/:type',
                component: IsolationAuditListPage,
                props: true,
            },
        ]
    },
    {
        path: '/password',
        component: PasswordEditPage,
        props: true,
    },
    {
        path: '/logout',
        component: LogoutPage,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
