import {NucleicAuditVO} from "@/vo/NucleicAuditVO";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";

export class NucleicAuditListVO {
    deptName: string;
    auditItems: Array<NucleicAuditVO>;
    temperItems: Array<NucleicTemperInfo>;

    constructor(deptName: string,
                auditItems: Array<NucleicAuditVO>,
                temperItems: Array<NucleicTemperInfo>) {
        this.deptName = deptName
        this.auditItems = auditItems
        this.temperItems = temperItems
    }
}
