
import {IonContent, IonPage} from '@ionic/vue';
import {lockClosed, person} from 'ionicons/icons';
import {onMounted, reactive, toRefs} from "vue";
import {useStore} from "vuex";

export default {
  components: {IonPage, IonContent},
  setup() {
    let temp: {
      event?: Event,
    } = {
      event: undefined,
    }
    const store = useStore();
    const state = reactive(temp);

    onMounted(async () => {
      try {
        await store.dispatch('user/logout');
      } finally {
        document.location.href = '/';
      }

    })
    return {
      ...toRefs(state),
      person,
      lockClosed,
    }
  }
}
