import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b96756ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "require-item-star"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { style: {"padding":"12px"} }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "report-item-class" }, {
                default: _withCtx(() => [
                  ($props.required)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                    : _createCommentVNode("v-if", true),
                  _createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { style: {"width":"100%"} }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                return (_openBlock(), _createBlock(_component_ion_item, {
                  key: index,
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createCommentVNode(" SINGLE_INPUT "),
                    ($props.type === _ctx.InputTableType.SINGLE_INPUT && !$props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_input, {
                          key: 0,
                          modelValue: item.item1,
                          "onUpdate:modelValue": ($event: any) => ((item.item1) = $event)
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("v-if", true),
                    ($props.type === _ctx.InputTableType.SINGLE_INPUT && $props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 1,
                          modelValue: item.item1,
                          "onUpdate:modelValue": ($event: any) => ((item.item1) = $event)
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("v-if", true),
                    _createCommentVNode(" TWO_SELECTION "),
                    ($props.type === _ctx.InputTableType.SELECTION && !$props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_label, {
                          key: 2,
                          onClick: ($event: any) => ($setup.onSelectItem(item,index))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.item1 ? item.item1.label : ''), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                      : _createCommentVNode("v-if", true),
                    ($props.type===_ctx.InputTableType.SELECTION && !$props.viewFlag && $props.secondItemList && $props.secondItemList.length>0)
                      ? (_openBlock(), _createBlock(_component_ion_chip, {
                          key: 3,
                          color: "secondary"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { color: "secondary" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.item2 ? item.item2.label : ''), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    ($props.type === _ctx.InputTableType.SELECTION && $props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_label, { key: 4 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.item1 ? item.item1.label : '') + _toDisplayString(item.item2 ? ',' + item.item2.label : ''), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    _createCommentVNode(" VIEW_INPUT "),
                    ($props.type === _ctx.InputTableType.VIEW_INPUT && !$props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_input, {
                          key: 5,
                          modelValue: item.item3,
                          "onUpdate:modelValue": ($event: any) => ((item.item3) = $event)
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                      : _createCommentVNode("v-if", true),
                    ($props.type === _ctx.InputTableType.VIEW_INPUT && $props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_label, { key: 6 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.item1 ? item.item1.label : '') + " " + _toDisplayString(item.item2 ? ',' + item.item2.label : '') + " " + _toDisplayString(item.item3 ? ',' + item.item3.label : ''), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (!$props.viewFlag)
                      ? (_openBlock(), _createBlock(_component_ion_icon, {
                          key: 7,
                          slot: "end",
                          icon: $setup.removeCircleOutline,
                          onClick: ($event: any) => ($setup.onRemoveItem(index))
                        }, null, 8 /* PROPS */, ["icon", "onClick"]))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */)),
              (!$props.viewFlag)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 0,
                    lines: "full",
                    onClick: $setup.onAddItem,
                    style: {"font-size":"14px","color":"#CCCCCC","width":"100%"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("点击添加" + _toDisplayString($props.name), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}