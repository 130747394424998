
import {IonLabel, IonGrid, IonImg, IonRow, IonCol} from '@ionic/vue';
import {getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import UserSelectionModal from "@/views/component/UserSelectionModal.vue";
import {UserInfo} from "@/vo/UserInfo";
import {getUserList} from "@/api/page";

export default {
  name: 'UserPicker',
  components: {IonGrid, IonImg, IonRow, IonCol, IonLabel, UserSelectionModal},
  props: {
    title: {type: String},
    subTitle: {type: String},
    required: {type: Boolean},
    modelValue: {type: Array, default: null},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      userSelected: Array<UserInfo>,
      userList: Array<UserInfo>,
      userPopOpen: boolean,
      event?: Event,
    } = {
      userSelected: [],
      userList: [],
      userPopOpen: false,
      event: undefined,
    }
    const state = reactive(temp);

    const onClick = () => {
      emit('onClick', props.data);
    }

    const onAddUser = () => {
      state.userPopOpen = true;
    }

    const onUserPopoverClose = (items: Array<UserInfo>) => {
      state.userPopOpen = false;
      emit('update:modelValue', items);
    }

    const removeUser = (index: number) => {
      console.info("dblclick");
      state.userSelected.splice(index, 1);
    }

    onMounted(async () => {
      state.userList = await getUserList()
      console.info("userList = ", state.userList)
    })
    return {
      ...toRefs(state),
      onClick,
      onAddUser,
      onUserPopoverClose,
      removeUser,
    }
  }
}
