/**
 * @description 消毒剂信息获取
 */
import {BadgeItem, VuexOpers} from "@/common/TypeDefs";
import {getAuditCount, getAuditList, getCount, getList} from "@/api/suspend";
import {NucleicSuspendApplyVO} from "@/vo/NucleicSuspendApplyVO";

export type StateItems = {
    suspendApplyList: Array<NucleicSuspendApplyVO>, suspendApplyCount?: BadgeItem,
    suspendAuditList: Array<NucleicSuspendApplyVO>, suspendAuditCount?: BadgeItem,
};

const state: StateItems = {suspendApplyList: [], suspendApplyCount: undefined, suspendAuditList: [], suspendAuditCount: undefined};
const getters = {
    suspendApplyList: (state: StateItems) => state.suspendApplyList,
    suspendApplyCount: (state: StateItems) => state.suspendApplyCount,
    suspendAuditList: (state: StateItems) => state.suspendAuditList,
    suspendAuditCount: (state: StateItems) => state.suspendAuditCount,
}
const mutations = {
    setSuspendApplyList(state: StateItems, suspendApplyList: Array<NucleicSuspendApplyVO>) {
        state.suspendApplyList = suspendApplyList
    },
    setSuspendApplyCount(state: StateItems, suspendApplyCount: BadgeItem) {
        state.suspendApplyCount = suspendApplyCount
    },
    setSuspendAuditList(state: StateItems, suspendAuditList: Array<NucleicSuspendApplyVO>) {
        state.suspendAuditList = suspendAuditList
    },
    setSuspendAuditCount(state: StateItems, suspendAuditCount: BadgeItem) {
        state.suspendAuditCount = suspendAuditCount
    },
}
const actions = {
    async getSuspendApplyList({commit, dispatch}: VuexOpers) {
        const {list} = await getList(1, 10000, undefined, true)

        if (list) {
            commit('setSuspendApplyList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getSuspendApplyCount({commit, dispatch}: VuexOpers) {
        const data = await getCount(undefined, true)

        if (data) {
            commit('setSuspendApplyCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getSuspendAuditList({commit, dispatch}: VuexOpers) {
        const {list} = await getAuditList(1, 10000, undefined, true)

        if (list) {
            commit('setSuspendAuditList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getSuspendAuditCount({commit, dispatch}: VuexOpers) {
        const data = await getAuditCount()
        if (data) {
            commit('setSuspendAuditCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
}
export default {state, getters, mutations, actions}
