
import {
  DatetimeChangeEventDetail,
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import VideoUploader from "@/views/component/VideoUploader.vue";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {NucleicSuspendApplyVO} from "@/vo/NucleicSuspendApplyVO";
import {IonDatetimeCustomEvent} from "@ionic/core/dist/types/components";
import {parseISO} from "date-fns";
import {doEdit} from "@/api/suspend";
import {DateUtils} from "@/common/DateUtils";

export default {
  components: {
    IonPage,
    IonHeader,
    IonModal,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonAccordion,
    IonAccordionGroup,
    IonDatetime,
    IonDatetimeButton,
    PhotoUploader,
    VideoUploader
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      startDate?: string,
      endDate?: string,
      form: NucleicSuspendApplyVO,
      event?: Event,
    } = {
      startDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.startDate) : DateUtils.tsToIsoString(new Date().getTime()),
      endDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.endDate) : DateUtils.tsToIsoString(new Date().getTime()),
      form: (props.params && props.params.item) ? props.params.item : {},
      event: undefined,
    }
    const state = reactive(temp);

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const save = async (flag: number) => {
      const originValue = state.form.status
      if (flag === 0) {
        state.form.status = Constants.ApplyStatus.UNCOMMIT
      } else if (flag === 1) {
        state.form.status = Constants.ApplyStatus.COMMIT
      }
      try {
        console.info(state)
        await doEdit(state.form)
        back()
      } catch (e: any) {
        console.error(e)
        UIUtils.showToast("", e.message, 2000)
        state.form.status = originValue
      }
    }

    const doCommit = async (flag: number) => {
      if (state.startDate) {
        state.form.startDate = parseISO(state.startDate).getTime()
      }

      if (state.endDate) {
        state.form.endDate = parseISO(state.endDate).getTime()
      } else {
        state.form.endDate = undefined
      }

      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      state.form.pictureInfo = JSON.stringify(state.form.pictureList)
      state.form.videoInfo = JSON.stringify(state.form.videoList)

      if (flag === 0) {
        await save(flag)
      } else if (flag === 1) {
        UIUtils.showAlertConfirm("确认", "是否确认提交?", async () => {
          await save(flag)
        }, () => {
          console.log("取消")
        }, "提交", "取消")
      }
    }

    const changeStartDate = (event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
      state.startDate = event.detail.value as string
    }

    const changeEndDate = (event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
      state.endDate = event.detail.value as string
    }

    const validation = () => {
      if (!state.form.startDate) {
        return "开始时间不允许为空";
      }

      if (state.form.endDate && state.form.endDate < state.form.startDate) {
        return "开始时间不能晚于结束时间";
      }
      if (!state.form.reason) {
        return "暂停原因不允许为空";
      }
      return null;
    }

    const rejectedCalc = computed(() => {
      return state.form.status === Constants.ApplyStatus.REJECT
    })

    const auditCalc = computed(() => {
      return state.form.status === Constants.ApplyStatus.FINISH
    })

    onMounted(() => {
      console.log("onMounted")
    })

    onIonViewDidEnter(() => {
      console.log("onIonViewDidEnter")

    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
      doCommit,
      rejectedCalc,
      auditCalc,
      changeStartDate,
      changeEndDate,
      CleanStatus: Constants.ApplyStatus,
      timeFormatCalc: Filters.timeFormatCalc,
      cleanStatusCreateCalc: Filters.cleanStatusCreateCalc,
    }
  }
}
