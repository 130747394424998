import {Device} from "@awesome-cordova-plugins/device";
import {AppInfo} from "@/vo/AppInfo";
import {AppVersion} from "@awesome-cordova-plugins/app-version";
import {Runtimes} from "@/common/Runtimes";

export class AppUtils {
    public static async getAppInfo(): Promise<AppInfo> {
        if (Runtimes.appInfo == null) {
            const versionCode = await AppVersion.getVersionCode()
            const versionNumber = await AppVersion.getVersionNumber()
            Runtimes.appInfo = {
                versionCode,
                versionString: versionNumber,
                platform: Device.platform,
            }
        }
        return Promise.resolve(Runtimes.appInfo)
    }
}
