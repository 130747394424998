
import {IonLabel} from '@ionic/vue';
import {getCurrentInstance, reactive, toRefs} from "vue";
import {GeoUtils} from "@/common/GeoUtils";
import {UIUtils} from "@/common/UIUtils";

export default {
  name: 'GeoLocator',
  components: {IonLabel},
  props: {
    modelValue: {type: String, default: null},
    viewFlag: {type: Boolean},
  },
  emits: ['onLocation', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      geolocation?: string,
      locationFlag: boolean,
      event?: Event,
    } = {
      geolocation: (props.modelValue || props.viewFlag ? props.modelValue : "点击获取当前位置"),
      locationFlag: false,
      event: undefined,
    }
    const state = reactive(temp);

    const onLocation = async () => {
      if (!props.viewFlag) {
        if (state.locationFlag) {
          UIUtils.showToast("", "正在定位,请勿重复点击", 2000)
          return
        }
        const originLocation = state.geolocation
        state.geolocation = "定位中"
        try {
          state.locationFlag = true
          const location = await GeoUtils.getCurrentPosition()
          console.log(location)
          state.geolocation = location.formattedAddress
          emit('update:modelValue', state.geolocation);
          emit('onLocation', location);
        } catch (e) {
          console.error(e)
          state.geolocation = originLocation
          UIUtils.showToast("", "定位失败", 2000)
        } finally {
          state.locationFlag = false
        }
      }
    }

    return {
      ...toRefs(state),
      onLocation,
    }
  }
}
