/**
 * @description 消毒剂信息获取
 */
import {VuexOpers} from "@/common/TypeDefs";
import {batchGetDicList} from "@/api/common";
import {ComboInfo} from "@/vo/ComboInfo";

export type StateItems = {
    disinfectorNameList: Array<ComboInfo>,
    disinfectorPercentList: Array<ComboInfo>,
    cleanTypeList: Array<ComboInfo>,
    cleanLocationList: Array<ComboInfo>,
    detectTypeList: Array<ComboInfo>, detectResultList: Array<ComboInfo>, notDetectReasonList: Array<ComboInfo>
};

const state: StateItems = {
    disinfectorNameList: [], disinfectorPercentList: [], cleanTypeList: [], cleanLocationList: [],
    detectTypeList: [], detectResultList: [], notDetectReasonList: []
};
const getters = {
    disinfectorNameList: (state: StateItems) => state.disinfectorNameList,
    disinfectorPercentList: (state: StateItems) => state.disinfectorPercentList,
    cleanTypeList: (state: StateItems) => state.cleanTypeList,
    cleanLocationList: (state: StateItems) => state.cleanLocationList,
    detectTypeList: (state: StateItems) => state.detectTypeList,
    detectResultList: (state: StateItems) => state.detectResultList,
    notDetectReasonList: (state: StateItems) => state.notDetectReasonList,
}
const mutations = {
    setDisinfectorNameList(state: StateItems, disinfectorNameList: Array<ComboInfo>) {
        state.disinfectorNameList = disinfectorNameList
    },
    setDisinfectorPercentList(state: StateItems, disinfectorPercentList: Array<ComboInfo>) {
        state.disinfectorPercentList = disinfectorPercentList
    },
    setCleanTypeList(state: StateItems, cleanTypeList: Array<ComboInfo>) {
        state.cleanTypeList = cleanTypeList
    },
    setCleanLocationList(state: StateItems, cleanLocationList: Array<ComboInfo>) {
        state.cleanLocationList = cleanLocationList
    },
    setDetectTypeList(state: StateItems, detectTypeList: Array<ComboInfo>) {
        state.detectTypeList = detectTypeList
    },
    setDetectResultList(state: StateItems, detectResultList: Array<ComboInfo>) {
        state.detectResultList = detectResultList
    },
    setNotDetectReasonList(state: StateItems, notDetectReasonList: Array<ComboInfo>) {
        state.notDetectReasonList = notDetectReasonList
    },
}
const actions = {
    async getDicInfos({commit, dispatch}: VuexOpers) {
        const dicInfoList = await batchGetDicList('disinfector,disinfector_percent,clean_type,clean_location,detect_type,detect_result,not_detect_reason');

        if (dicInfoList) {
            commit('setDisinfectorNameList', dicInfoList.filter(item => item.type === 'disinfector'));
            commit('setDisinfectorPercentList', dicInfoList.filter(item => item.type === 'disinfector_percent'));
            commit('setCleanTypeList', dicInfoList.filter(item => item.type === 'clean_type'));
            commit('setCleanLocationList', dicInfoList.filter(item => item.type === 'clean_location'));
            commit('setDetectTypeList', dicInfoList.filter(item => item.type === 'detect_type'));
            commit('setDetectResultList', dicInfoList.filter(item => item.type === 'detect_result'));
            commit('setNotDetectReasonList', dicInfoList.filter(item => item.type === 'not_detect_reason'));
            return Promise.resolve(dicInfoList);
        } else {
            return Promise.reject(new Error("无法获取消毒剂类型信息"));
        }
    },
}
export default {state, getters, mutations, actions}
