import router from "@/router";

export class NaviUtils {
    public static navPush(path: string) {
        console.log("nav")
        router.push(path)
    }

    public static navPop() {
        console.log("navPop")
        router.back()
    }

    public static navReplace(path: string) {
        console.log("navReplace")
        router.replace(path)
    }

    public static navPopTo(prefix: string) {
        console.log("navPopTo")
        let index = -1
        for (let i = router.getRoutes().length - 1; i >= 0; i--) {
            const route = router.getRoutes()[i]
            if (route.path.startsWith(prefix)) {
                index++
                break;
            }
            index++
        }
        if (index > 0) {
            router.go(index)
        }

    }

    public static navGo(delta: number) {
        console.log("navGo")
        router.go(delta)
    }
}
