
import {IonModal} from '@ionic/vue';
import {getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {arrowBack} from 'ionicons/icons';
import UserPopSelection from "@/views/component/UserPopSelection.vue";
import {UserInfo} from "@/vo/UserInfo";

export default {
  name: 'UserSelectionModal',
  components: {IonModal, UserPopSelection},
  props: {
    title: {type: String},
    items: {type: Array},
    open: {type: Boolean, default: false},
    modelValue: {type: Array, default: null},
  },
  emits: ['onClose', 'onSelect', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      event?: Event,
      isOpen: boolean,
      selectionItems: Array<UserInfo>,
    } = {
      event: undefined,
      isOpen: props.open,
      selectionItems: props.modelValue,
    }
    const state = reactive(temp);

    const onSelect = (item: Array<UserInfo>) => {
      console.info("onSelect")
    }
    const onClose = () => {
      emit('onClose', state.selectionItems);
      emit('update:modelValue', state.selectionItems);
      state.isOpen = false;
    }

    watch(() => props.open, (newValue, oldValue) => { //直接监听
      state.isOpen = newValue;
    });

    onMounted(() => {
      console.info("UserSelectionModal Mount")
    })
    return {
      ...toRefs(state),
      arrowBack,
      onSelect,
      onClose,
    }
  }
}
