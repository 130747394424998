import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.mutiple)
      ? (_openBlock(), _createBlock(_component_ion_radio_group, {
          key: 0,
          value: _ctx.modelValue.length>0?_ctx.modelValue[0]:null
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_ctx.title)
                  ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 0 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
                  return (_openBlock(), _createBlock(_component_ion_item, {
                    key: index,
                    onClick: ($event: any) => (_ctx.onSelect(item,index))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */),
                      _createVNode(_component_ion_radio, {
                        value: item.value
                      }, null, 8 /* PROPS */, ["value"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                }), 128 /* KEYED_FRAGMENT */)),
                _createCommentVNode("      <ion-item lines=\"none\" detail=\"false\" button @click=\"onClose()\">关闭</ion-item>")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["value"]))
      : _createCommentVNode("v-if", true),
    (_ctx.mutiple)
      ? (_openBlock(), _createBlock(_component_ion_list, { key: 1 }, {
          default: _withCtx(() => [
            (_ctx.title)
              ? (_openBlock(), _createBlock(_component_ion_list_header, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.title), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: index,
                onClick: ($event: any) => (_ctx.onSelect(item,index))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */),
                  (_ctx.mutiple)
                    ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                        key: 0,
                        value: item.value,
                        checked: _ctx.checkedFlag(item.value)
                      }, null, 8 /* PROPS */, ["value", "checked"]))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
            }), 128 /* KEYED_FRAGMENT */)),
            _createCommentVNode("    <ion-item lines=\"none\" detail=\"false\" button @click=\"onClose()\">关闭</ion-item>")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}