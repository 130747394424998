
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {arrowBack, ellipse, square, triangle} from 'ionicons/icons';
import {computed, getCurrentInstance, nextTick, reactive, toRefs} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import {useRoute} from "vue-router";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {reportData} from "@/api/inout";
import {useStore} from "vuex";
import DynamicForm from "@/views/component/DynamicForm.vue";

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, DynamicForm
  },
  setup() {
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      inOut: string | string[],
      itemTypes: { [name: string]: number },
      event?: Event,
    } = {
      inOut: route.params.inOut,
      itemTypes: Constants.ItemTypes,
      event: undefined,
    }
    const state = reactive(temp);


    const badgeNum = computed(() => {
      return null;
    })

    const back = () => {
      NaviUtils.navPop();
    }

    const gotoPage = (url: string) => {
      console.log(url)
      NaviUtils.navPush(url)
    }

    const confirm = async () => {
      try {
        const report = proxy.$refs['dynamicFormId'].getReportData()
        report["action"] = state.inOut
        console.info(report)

        await reportData(report)
        await store.dispatch('user/getUserInfo');
        back()
      } catch (e: any) {
        UIUtils.showToast("", "提交失败:" + e.message, 2000)
        console.error(e)
      }
    }

    const inoutLabel = computed(() => {
      return state.inOut === 'IN' ? '进场' : '出场'
    })

    const statusNameCalc = computed(() => {
      const userInOutStatus = store.getters["user/userInOutStatus"];
      console.info("userInOutStatus = " + userInOutStatus);
      return userInOutStatus;
    })

    onIonViewDidEnter(async () => {
      try {
        await store.dispatch('form/getReportDefItemByReporter', {id: null, type: Constants.IN_OUT_WORK_FLOW_ID, useUserId: true, variables: {"flag": state.inOut.toString()}});
        await nextTick()
        await proxy.$refs['dynamicFormId'].refreshDic()
      } catch (err: any) {
        UIUtils.showToast("", "获取页面配置失败", 2000)
      }

      // getInOutReportItems(state.inOut.toString())
      //     .then(async (items) => {
      //       state.pageItems = items
      //       await nextTick()
      //       await proxy.$refs['dynamicFormId'].refreshDic()
      //     })
      //     .catch((err) => {
      //       UIUtils.showToast("", "获取页面配置失败", 2000)
      //     })
    })

    const pageItems = computed(() => {
      return store.getters["form/pageItems"];
    })

    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      arrowBack,
      badgeNum,
      back,
      gotoPage,
      inoutLabel,
      confirm,
      statusNameCalc,
      pageItems,
    }
  }
}
