
import {IonCheckbox, IonInput, IonItem, IonLabel, IonList} from '@ionic/vue';
import {arrowBack, ellipse, square, triangle} from 'ionicons/icons';
import {computed, reactive, toRefs} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import UserPicker from "@/views/component/UserPicker.vue";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import VideoUploader from "@/views/component/VideoUploader.vue";
import {useRoute} from "vue-router";
import ReportItemDef from "@/vo/ReportItemDef";
import {Constants} from "@/common/Constants";
import {useStore} from "vuex";
import ItemChecker from "@/views/component/ItemChecker.vue";
import DateTimePicker from "@/views/component/DateTimePicker.vue";
import FileUploader from "@/views/component/FileUploader.vue";
import ItemListEditor from "@/views/component/ItemListEditor.vue";
import {getDicList} from "@/api/common";
import {SelectionItemType} from "@/common/TypeDefs";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";

export default {
  components: {
    IonList, IonItem, IonCheckbox, IonInput, IonLabel,
    UserPicker, PhotoUploader, VideoUploader, ItemChecker, DateTimePicker, FileUploader, ItemListEditor
  },
  props: {
    pageItems: {type: Array},
    afterPage: {type: Boolean},
  },
  emits: [],
  setup(props: any, {emit}: { emit: any }) {
    const route = useRoute();
    const store = useStore();

    let temp: {
      itemTypes: { [name: string]: number },
      dicCache1: { [id: string]: Array<SelectionItemType> },
      dicCache2: { [id: string]: Array<SelectionItemType> },
      event?: Event,
    } = {
      itemTypes: Constants.ItemTypes,
      dicCache1: {},
      dicCache2: {},
      event: undefined,
    }
    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const gotoPage = (url: string) => {
      console.log(url)
      NaviUtils.navPush(url)
    }

    const getReportData = () => {
      const report: { [name: string]: any } = {}
      const data: { [name: string]: any } = {}
      for (let i = 0; i < props.pageItems.length; i++) {
        const pageItem = props.pageItems[i]

        if (!opportunityCheck(pageItem)) {
          continue
        }
        if (pageItem.required && (pageItem.reportValue === null || pageItem.reportValue === undefined || pageItem.reportValue.length === 0)) {
          throw new Error("【" + pageItem.label + "】不允许为空")
        }

        switch (pageItem.itemType) {
          case state.itemTypes.INPUT:
          case state.itemTypes.RADIO:
          case state.itemTypes.COMBO:
            data[pageItem.id] = pageItem.reportValue
            if (data[pageItem.id]) {
              if (pageItem.minItems > 0 && data[pageItem.id].length < pageItem.minItems) {
                throw new Error("【" + pageItem.label + "】至少要报" + pageItem.minItems + "项")
              } else if (pageItem.maxItems > 0 && data[pageItem.id].length > pageItem.maxItems) {
                throw new Error("【" + pageItem.label + "】最多能报" + pageItem.maxItems + "项")
              }
            }
            break;
          case state.itemTypes.PICTURE:
          case state.itemTypes.VIDEO:
          case state.itemTypes.FILE_UPLOADER:
            console.log("pageItem.reportValue = ", pageItem.reportValue)
            if (pageItem.itemType === state.itemTypes.FILE_UPLOADER) {
              data[pageItem.id] = pageItem.reportValue ? pageItem.reportValue.map((item: any) => ({
                id: item.id,
                url: item.url,
                name: item.name,
                originFileName: item.originFileName
              })) : []
            } else {
              data[pageItem.id] = pageItem.reportValue ? pageItem.reportValue.map((item: any) => ({
                id: item.id,
                url: item.url
              })) : []
            }
            if (pageItem.minItems > 0 && data[pageItem.id].length < pageItem.minItems) {
              throw new Error("【" + pageItem.label + "】至少要报" + pageItem.minItems + "项")
            } else if (pageItem.maxItems > 0 && data[pageItem.id].length > pageItem.maxItems) {
              throw new Error("【" + pageItem.label + "】最多能报" + pageItem.maxItems + "项")
            }
            break;
          case state.itemTypes.SINGLE_CHECK:
          case state.itemTypes.DATE_PICKER:
          case state.itemTypes.TIME_PICKER:
          case state.itemTypes.DATETIME_PICKER:
          case state.itemTypes.INPUT_TABLE:
            data[pageItem.id] = pageItem.reportValue
            break;
          case state.itemTypes.USER_SELECTION:
            data[pageItem.id] = pageItem.reportValue ? pageItem.reportValue.map((item: any) => ({
              userId: item.userId,
              userName: item.userName
            })) : null
            break;
          default:
            console.warn("无法识别的元素类型")
        }

        if (pageItem.extName) {
          report[pageItem.extName] = data[pageItem.id]
        }
      }

      report["data"] = data
      console.info(report)
      return report
    }

    const refreshDic = async () => {
      //重新加载字典数据
      const dicRefCache: { [type: string]: Array<SelectionItemType> } = Constants.defaultDicRefCache;
      for (let i = 0; i < props.pageItems.length; i++) {
        let dicRefType: string = props.pageItems[i].dicRefType1
        if (dicRefType) {
          if (dicRefCache[dicRefType] === null || dicRefCache[dicRefType] === undefined) {
            dicRefCache[dicRefType] = await getDicList(dicRefType)
          }
          state.dicCache1[props.pageItems[i].id] = dicRefCache[dicRefType]
        }

        dicRefType = props.pageItems[i].dicRefType2
        if (dicRefType) {
          if (dicRefCache[dicRefType] === null || dicRefCache[dicRefType] === undefined) {
            dicRefCache[dicRefType] = await getDicList(dicRefType)
          }
          state.dicCache2[props.pageItems[i].id] = dicRefCache[dicRefType]
        }
      }
    }

    const itemPaddingCalc = computed(() => {
      return (itemType: number) => {
        if (itemType === state.itemTypes.PICTURE
            || itemType === state.itemTypes.VIDEO
            || itemType === state.itemTypes.USER_SELECTION
            || itemType === state.itemTypes.RADIO
            || itemType === state.itemTypes.COMBO)
          return 1;
        if (itemType === state.itemTypes.INPUT_TABLE)
          return 2;
        return 0;
      }
    })

    const maxUploadTipsCalc = computed(() => {
      return (item: ReportItemDef) => {
        if (item.maxItems > 0 && item.minItems > 0) {
          return '允许上传' + item.minItems + '到' + item.maxItems
        } else if (item.maxItems > 0) {
          return '最多上传' + item.maxItems
        } else if (item.minItems > 0) {
          return '最少上传' + item.minItems
        }
        return ''
      }
    })

    const inputModeCalc = computed(() => {
      return (item: ReportItemDef) => {
        if (item.dataType == Constants.DataTypes.INTEGER || item.dataType == Constants.DataTypes.FLOAT) {
          return 'numeric'
        } else {
          return 'text'
        }
      }
    })

    const validValuesCalc = computed(() => {
      return (item: ReportItemDef) => {
        return JSON.parse(item.validValues)
      }
    })

    const subTypeTransformer = computed(() => {
      return (item: ReportItemDef) => {
        if (item.subType === Constants.SubTypes.MATERIAL_SELECTION) {
          return Constants.InputTableType.SELECTION
        } else if (item.subType === Constants.SubTypes.MATERIAL_SELECTION_APPLY) {
          return Constants.InputTableType.VIEW_INPUT
        } else {
          return Constants.InputTableType.SINGLE_INPUT
        }
      }
    })

    const opportunityCheck = (item: ReportDefItemWithValue) => {
      if (props.afterPage) {
        return item.viewFlag === false && item.opportunity === Constants.Opportunity.AFTER_BUTTON
      } else {
        return item.viewFlag === true || item.opportunity === Constants.Opportunity.FORM
      }
    };

    const formPageItemCalc = computed(() => {
      return props.pageItems.filter((item: ReportDefItemWithValue) => opportunityCheck(item))
    })

    const trigShowCalc = computed(() => {
      return (curPageItem: ReportDefItemWithValue) => {
        if (curPageItem.trigShowList) {
          const allTrigVariables: any = {}
          if (props.pageItems) {
            props.pageItems.filter((pageItem: any) => pageItem.trigVariables)
                .forEach((pageItem: any) => {
                  Object.assign(allTrigVariables, pageItem.trigVariables)
                })
          }
          for (let i = 0; i < curPageItem.trigShowList.length; i++) {
            const trig = curPageItem.trigShowList[i]
            if (trig.type === 0) {
              if (trig.values.some(value => allTrigVariables[trig.reportDefItemId] === value)) {
                return true
              } else {
                return false
              }
            } else if (trig.type === 1) {
              if (trig.values.every(value => allTrigVariables[trig.reportDefItemId] === value)) {
                return true
              } else {
                return false
              }
            }
          }
          return true
        }
        return true
      }
    })
    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      arrowBack,
      back,
      gotoPage,
      itemPaddingCalc,
      maxUploadTipsCalc,
      inputModeCalc,
      validValuesCalc,
      getReportData,
      subTypeTransformer,
      refreshDic,
      formPageItemCalc,
      trigShowCalc,
    }
  }
}
