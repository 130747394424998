
import {removeCircleOutline} from 'ionicons/icons';
import {IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow, pickerController} from '@ionic/vue';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {PickerColumn} from "@ionic/core/dist/types/components/picker/picker-interface";
import {Constants} from "@/common/Constants";

export default {
  name: 'ItemListEditor',
  components: {IonCol, IonGrid, IonRow, IonList, IonItem, IonInput, IonLabel, IonIcon},
  props: {
    title: {type: String},
    required: {type: Boolean},
    name: {type: String},
    type: {type: Number},
    firstItemList: {type: Array},
    secondItemList: {type: Array},
    viewFlag: {type: Boolean, default: true},
    modelValue: {type: Array, default: null},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      items: Array<{ item1: { label: string, value: string } | null, item2: { label: string, value: string } | null, item3?: { label: string, value: string } | null }>,
      InputTableType: { [name: string]: number },
      event?: Event,
    } = {
      items: props.modelValue ? JSON.parse(props.modelValue) : [],
      InputTableType: Constants.InputTableType,
      event: undefined,
    }
    const state = reactive(temp)

    const onAddItem = () => {
      const itemValue = {item1: null, item2: null}
      if (props.type === Constants.InputTableType.SINGLE_INPUT) {
        state.items.push(itemValue)
      } else if (props.type === Constants.InputTableType.SELECTION) {
        state.items.push(itemValue)
        onSelectItem(itemValue, state.items.length - 1, (value: any) => {
          if (value == null) {
            state.items.splice(state.items.length - 1, 1)
          }
        })
      }
    }

    const onRemoveItem = (index: number) => {
      console.log("onRemoveItem = " + index)
      state.items.splice(index, 1)
      emit('update:modelValue', state.items)
    }

    const onSelectItem = async (selectedItem: { item1: any, item2: any }, index: number, callback?: (value: any) => void) => {
      const pickerColumns = [];
      const firstOptions: PickerColumn = {
        name: "item1",
        options: [],
      }
      if (props.firstItemList) {
        props.firstItemList.forEach((item: any) => {
          firstOptions.options.push({text: item.label, value: item.value})
        })
      }
      pickerColumns.push(firstOptions)

      let secondOptions: PickerColumn;
      if (props.secondItemList && props.secondItemList.length > 0) {
        secondOptions = {
          name: "item2",
          options: [],
        }
        props.secondItemList.forEach((item: any) => {
          secondOptions.options.push({text: item.label, value: item.value})
        })

        pickerColumns.push(secondOptions)
      }


      const picker = await pickerController.create({
        columns: pickerColumns,
        buttons: [
          {
            text: '取消',
            role: 'cancel',
            handler: () => {
              if (callback) {
                callback(null)
              }
            }
          },
          {
            text: '确认',
            handler: (value) => {
              // {
              // item1: {
              // columnIndex: 0,
              // text: "防护服",
              // value: "防护服",
              // },
              // item2: {
              // columnIndex: 1,
              // text: "1",
              // value: 1,
              // }
              // }
              console.log(value)
              state.items[index] = {item1: {label: value.item1.text, value: value.item1.value}, item2: {label: value.item2 ? value.item2.text : null, value: value.item2 ? value.item2.value : null}}
              emit('update:modelValue', state.items)
              if (callback) {
                callback(value)
              }
            },
          },
        ],
      });

      await picker.present();
    }

    const labelCalc = computed(() => {
      console.log("labelCalc")
      return (itemValue: any, itemList: Array<any>) => {
        if (itemValue === null || itemValue === null) {
          return ""
        }
        const itemObj = itemList.find((t: any) => t.value === itemValue)
        return itemObj.label
      }
    })

    onMounted(async () => {
      console.log("ItemListEditor Mounted")
    })
    return {
      ...toRefs(state),
      onSelectItem,
      onAddItem,
      onRemoveItem,
      labelCalc,
      removeCircleOutline,
    }
  }
}
