//是否连续3次出现重复字符
const regRepeat = /([0-9a-zA-Z])\1{2}/
//是否包含大写字母
const regUpperLetter = /(?=.*[A-Z])/
//是否包含小写字母
const regLowerLetter = /(?=.*[a-z])/
//是否包含大小写
const regLetter = /(?=.*[A-Z])(?=.*[a-z])/
//是否包含数字
const regDigit = /(?=.*[0-9])/
//是否包含特殊字符
const regSpChar = /(?=.*[\W_])/
//组合验证，综合上面三种，并且密码长度不小于8位
const regCompose = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/

//是否连续3次出现重复字符
export function repeatCheck(str: string) {
    return regRepeat.test(str)
}

//是否包含大写字母
export function upperLetterCheck(str: string) {
    return regUpperLetter.test(str)
}

//是否包含小写字母
export function lowerLetterCheck(str: string) {
    return regLowerLetter.test(str)
}

//是否包含大小写字母
export function letterCheck(str: string) {
    return regLetter.test(str)
}

//是否包含数字
export function digitCheck(str: string) {
    return regDigit.test(str)
}

//是否包含特殊字符
export function spCharCheck(str: string) {
    return regSpChar.test(str)
}

//组合验证，综合上面三种，并且密码长度不小于8位
export function composeCheck(str: string) {
    return regCompose.test(str)
}
