
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {addOutline, arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import VideoUploader from "@/views/component/VideoUploader.vue";
import {doEdit} from "@/api/toolsclean"
import {UIUtils} from "@/common/UIUtils";
import ToolsSelectionModal from "@/views/component/ToolsSelectionModal.vue"
import {format, parse} from "date-fns";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {ComboInfo} from "@/vo/ComboInfo";
import {ArrayUtils} from "@/common/ArrayUtils";
import {ToolsCleanVO} from "@/vo/ToolsCleanVO";
import {ToolsInfoVO} from "@/vo/ToolsInfoVO";
import {ToolsCleanDetailVO} from "@/vo/ToolsCleanDetailVO";
import {ToolSelectType} from "@/common/TypeDefs";

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonInput,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonAccordion,
    IonAccordionGroup,
    IonSelect,
    IonSelectOption,
    IonImg,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    ToolsSelectionModal,
    PhotoUploader,
    VideoUploader
  }
  ,
  props: {
    params: {
      type: Object
    }
    ,
    modalId: {
      type: String
    }
    ,
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      cleanTimeString: string,
      form: ToolsCleanVO,
      toolsSelectionOpen: boolean,
      disinfectorNameList: Array<ComboInfo>,
      disinfectorPercentList: Array<ComboInfo>,
      cleanTypeList: Array<ComboInfo>,
      toolsList: Array<ToolsInfoVO>,
      toolsSelectionItems: Array<ToolSelectType>,
      currentDetail: ToolsCleanDetailVO | null,
      event?: Event,
    } = {
      cleanTimeString: (props.params && props.params.item) ? format(new Date(props.params.item.cleanTime), 'yyyy-MM-dd HH:mm') : format(new Date(), 'yyyy-MM-dd HH:mm'),
      form: (props.params && props.params.item) ? props.params.item : {},
      toolsSelectionOpen: false,
      disinfectorNameList: store.getters["dic/disinfectorNameList"],
      disinfectorPercentList: store.getters["dic/disinfectorPercentList"],
      cleanTypeList: store.getters["dic/cleanTypeList"],
      toolsList: store.getters["selector/toolsList"],
      toolsSelectionItems: [],
      currentDetail: null,
      event: undefined,
    }
    const state = reactive(temp);

    const disinfectorNamePopoverOptions: any = {
      header: '消毒剂选择',
      // subHeader: '选择消毒剂种类',
      // message: '请选择一种消毒剂'
    };

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const save = async (flag: number) => {
      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      let confirmMsg = ""
      if (flag === 0) {
        confirmMsg = "是否确认开始消毒?"
      } else if (flag === 1) {
        confirmMsg = "是否确认提交消毒数据?"
      }
      UIUtils.showAlertConfirm("确认", confirmMsg, async () => {
        const date = parse(state.cleanTimeString, 'yyyy-MM-dd HH:mm', new Date())
        state.form.cleanTime = date.getTime()
        const originValue = state.form.status
        if (flag === 0) {
          state.form.status = Constants.CleanStatus.BEGIN
        } else if (flag === 1) {
          state.form.status = Constants.CleanStatus.COMMIT
        }

        state.form.pictureInfo = JSON.stringify(state.form.pictureList)
        state.form.videoInfo = JSON.stringify(state.form.videoList)

        state.form.details.forEach(detail => {
          const disinfectorItem: ComboInfo = ArrayUtils.findItem(state.disinfectorNameList, "value", detail.disinfectorId)
          const disinfectorPercentItem: ComboInfo = ArrayUtils.findItem(state.disinfectorPercentList, "value", detail.disinfectorPercentId)
          detail.disinfectorName = disinfectorItem.label
          detail.disinfectorPercentName = disinfectorPercentItem.label
        })

        try {
          await doEdit(state.form)
          back()
        } catch (e) {
          console.error(e)
          UIUtils.showToast("", "保存数据失败", 2000)

          state.form.status = originValue
        }
      }, () => {
        console.log("取消")
      }, "提交", "取消")
    }

    const addDetail = () => {
      if (state.form.details) {
        console.log("已有detail")
      } else {
        state.form.details = []
      }

      const addItem = {
        quantity: 1,
        disinfectorId: '',
        disinfectorName: '',
        disinfectorPercentId: '',
        disinfectorPercentName: '',
        cleanType: '',
        items: [],
        itemIds: [],
        itemNames: [],
        cleanTool: '',
      }

      state.form.details.push(addItem)
    }

    const removeDetail = (index: number) => {
      state.form.details.splice(index, 1)
    }

    const copyDetail = (index: number) => {
      const fromItem = state.form.details[index]
      const copyItem = {
        quantity: fromItem.quantity,
        disinfectorId: fromItem.disinfectorId,
        disinfectorName: fromItem.disinfectorName,
        disinfectorPercentId: fromItem.disinfectorPercentId,
        disinfectorPercentName: fromItem.disinfectorPercentName,
        cleanType: fromItem.cleanType,
        items: [],
        cleanTool: fromItem.cleanTool,
        itemIds: [],
        itemNames: [],
      }
      state.form.details.push(copyItem)
    }

    const openToolsSelection = (item: ToolsCleanDetailVO) => {
      state.currentDetail = item
      state.toolsSelectionItems = item.items
      if (!props.params.viewFlag) {
        state.toolsSelectionOpen = true
      }
    }

    const onSelectedTools = (items: Array<ToolSelectType>) => {
      const ids = []
      const names = []
      const includeTools = []
      for (let item of items) {
        const vo: ToolsInfoVO = ArrayUtils.findItem(state.toolsList, "id", item.id)
        ids.push(item.id)
        names.push(vo.name)
        includeTools.push(vo.includeTools)
      }
      if (state.currentDetail) {
        state.currentDetail.items = items
        state.form.includeTools = ""
        for (let i = 0; i < items.length; i++) {
          if (items[i].includeTools) {
            state.form.includeTools += items[i].name + "包含：" + items[i].includeTools + "；"
          }
        }
      } else {
        state.form.includeTools = undefined
      }

      state.toolsSelectionOpen = false
      state.currentDetail = null
    }

    const onSelectoinClose = () => {
      state.toolsSelectionOpen = false
      state.currentDetail = null
    }

    const validation = () => {
      if (state.form.details.length == 0) {
        return "请录入消毒工具设备详情";
      }
      for (let i = 0; i < state.form.details.length; i++) {
        const detail = state.form.details[i];
        const prefix = "第" + (i + 1) + "个工具设备, ";
        if (!detail.items || detail.items.length == 0) {
          return prefix + "消毒工具设备不允许为空";
        }
        if (!detail.disinfectorId) {
          return prefix + "消毒剂名称不允许为空";
        }
        if (!detail.disinfectorPercentId) {
          return prefix + "消毒剂配比不允许为空";
        }
        if (!detail.cleanType || detail.cleanType.trim() == '') {
          return prefix + "消毒方式不允许为空";
        }
        if (!detail.cleanTool || detail.cleanTool.trim() == '') {
          return "消毒工具不允许为空";
        }
      }
      if (!state.cleanTimeString || state.cleanTimeString.trim() == '') {
        return "消毒时间不允许为空";
      }

      return null;
    }

    const rejectedCalc = computed(() => {
      return state.form.status === Constants.CleanStatus.REJECT
    })

    onMounted(() => {
      if (props.params && props.params.item && props.params.item) {
        console.log("编辑页面,无需添加")
      } else {
        addDetail()
      }
    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      addOutline,
      back,
      save,
      rejectedCalc,
      CleanStatus: Constants.CleanStatus,
      timeFormatCalc: Filters.timeFormatCalc,
      cleanStatusCreateCalc: Filters.cleanStatusCreateCalc,
      disinfectorNamePopoverOptions,
      addDetail, removeDetail, copyDetail, openToolsSelection, onSelectedTools, onSelectoinClose
    }
  }
}
