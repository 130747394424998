
import {add, arrowBack, checkmark, chevronDownCircleOutline, close, trash} from "ionicons/icons";
import {
  CheckboxCustomEvent,
  createGesture,
  InfiniteScrollCustomEvent,
  IonCheckbox,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';
import {computed, getCurrentInstance, nextTick, onMounted, PropType, reactive, toRefs, watch} from "vue";
import {useStore} from "vuex";
import IndexBar from "./IndexBar.vue";
import {ComplexList, ComplexListGroup, IndexBarList} from "@/common/TypeDefs";
import {GestureDetail} from "@ionic/core/dist/types/utils/gesture";
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";

export default {
  name: 'ComplexList',
  components: {
    IonList, IonItem, IonItemGroup, IonItemDivider, IonFab, IonFabButton, IonCheckbox, IonItemSliding,
    IonInfiniteScroll, IonInfiniteScrollContent, IonItemOptions, IonIcon, IonContent, IonLabel, IonImg, IonRefresher,
    IonRefresherContent, IndexBar
  },
  props: {
    itemGroup: {type: Array as PropType<ComplexListGroup[]>, default: null},
    itemList: {type: Array as PropType<ComplexList>, default: null},
    enableSlide: {type: Boolean, default: false},
    detail: {type: Boolean, default: false},
    enableSlideFunc: {type: Function as PropType<(item: any) => boolean>, default: null},
    indexBar: {type: Boolean, default: false},
    checkable: {type: Boolean, default: false},
    creatable: {type: Boolean, default: false},
    alwaysChecking: {type: Boolean, default: false},
  },
  emits: ['onClick', 'onClickIndex', 'onSelect', 'onNew', 'onMoreData', 'onExitSelection', 'onRefresh'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      infiniteDisabled: boolean,
      list: Array<ComplexList>,
      searchText: string | null,
      event?: Event,
      checking: boolean,
      listRef: any,
      startTime: number,
      timerId: number,
      pageNo: number,
      selectItems: Array<any>,
    } = {
      infiniteDisabled: false,
      list: props.itemList,
      searchText: null,
      event: undefined,
      checking: props.alwaysChecking,
      listRef: undefined,
      startTime: 0,
      timerId: -1,
      pageNo: 1,
      selectItems: [],
    }
    const state = reactive(temp);

    const onSelect = (item: any) => {
      emit('onSelect', item);
    }

    const onClick = (item: any, index: number) => {
      if (state.checking === false) {
        emit("onClick", item)
        emit("onClickIndex", {item, index})
      }
    }

    const onNew = () => {
      emit('onNew')
    }

    const onExitSelection = () => {
      emit('onExitSelection')
      state.checking = false
    }

    const enableSlideCalc = computed(() => {
      return (item: any) => {
        console.log("enableSlideCalc = ", item)
        if (props.enableSlideFunc) {
          return props.enableSlideFunc(item)
        } else {
          return props.enableSlide
        }
      }
    })

    const selectionChange = (item: any, event?: CheckboxCustomEvent, check?: boolean) => {
      if (check || (event && event.detail.checked)) {
        //检查是否已经选中
        for (let i = 0; i < state.selectItems.length; i++) {
          if (state.selectItems[i].id === item.id) {
            return
          }
        }
        state.selectItems.push(item)
      } else {
        for (let i = 0; i < state.selectItems.length; i++) {
          if (state.selectItems[i].id === item.id) {
            state.selectItems.splice(i, 1);
            return
          }
        }
      }
    }

    const selectAll = (flag: boolean) => {
      for (let i = 0; i < props.itemList.length; i++) {
        selectionChange(props.itemList[i], undefined, flag)
      }
    }
    const doRefresh = async (event: RefresherCustomEvent | null) => {
      state.pageNo = 1
      state.infiniteDisabled = false
      emit('onRefresh', (data: Array<any>) => {
        state.list = data
        if (event) {
          event.target.complete()
        }
      })
    }
    const doInfinite = async (event: InfiniteScrollCustomEvent) => {
      state.infiniteDisabled = true
      state.pageNo++
      emit('onMoreData', state.pageNo, async (data: any) => {
        await event.target.complete()
        if (data.length === 0) {
          state.infiniteDisabled = true
          return
        } else {
          state.list.push(...data)
        }
        state.infiniteDisabled = false
      })

    }

    const indexBarItemCalc = computed(() => {
      if (props.itemGroup) {
        const indexBarItems: IndexBarList = []
        for (let i = 0; i < props.itemGroup.length; i++) {
          indexBarItems.push({refId: "group_" + props.itemGroup[i].id, name: props.itemGroup[i].name})
        }
        return indexBarItems;
      }
      return []
    })

    const closeSliding = () => {
      if (props.itemGroup) {
        for (let i = 0; i < props.itemGroup.length; i++) {
          for (let j = 0; j < props.itemGroup[i].list.length; j++) {
            if (proxy.$refs['sliding_' + i + '_' + j]) {
              proxy.$refs['sliding_' + i + '_' + j][0].$el.close()
            }
          }
        }
      } else if (props.itemList) {
        for (let i = 0; i < props.itemList.length; i++) {
          if (proxy.$refs['sliding_' + i]) {
            proxy.$refs['sliding_' + i][0].$el.close()
          }
        }
      }
    }

    const itemCheckCalc = computed(() => {
      return (item: any) => {
        for (let i = 0; i < state.selectItems.length; i++) {
          if (state.selectItems[i].id === item.id) {
            return true
          }
        }
        return false
      }
    })

    watch(() => props.itemList, (newValue, oldValue) => {
      state.list = newValue
      console.log(newValue)
      if (state.listRef && props.checkable) {
        nextTick(() => {
          for (let i = 0; i < newValue.length; i++) {
            const gesture = createGesture({
              // el: state.listRef.$el,
              el: proxy.$refs["check_item_" + i][0].$el,
              gestureName: 'longPress',
              threshold: 0,
              onStart: (detail: GestureDetail) => {
                console.log("onStart", detail)
                state.startTime = new Date().getTime()
                state.timerId = setTimeout(() => {
                  state.checking = true
                }, 1000)
                return true
              },
              onMove: (detail) => {
                return true
              },
              onEnd: (detail: GestureDetail) => {
                clearTimeout(state.timerId)
                return true
              }
            });
            gesture.enable(true);
          }
        })
      }
    })

    onMounted(() => {
      // proxy.$refs[refId];

    })


    return {
      ...toRefs(state),
      add, arrowBack, chevronDownCircleOutline, close, trash, checkmark,
      onClick,
      onSelect,
      onNew,
      onExitSelection,
      selectionChange,
      closeSliding,
      selectAll,
      doInfinite,
      doRefresh,
      itemCheckCalc,
      indexBarItemCalc,
      enableSlideCalc,
    }
  }
}
