/**
 * @description 消毒剂信息获取
 */
import {BadgeItem, VuexOpers} from "@/common/TypeDefs";
import {getAuditCount,getAuditList, getCount, getList} from "@/api/isolation";
import {IsolationApplyVO} from "@/vo/IsolationApplyVO";

export type StateItems = {
    isolationApplyList: Array<IsolationApplyVO>, isolationApplyCount?: BadgeItem,
    isolationAuditList: Array<IsolationApplyVO>, isolationAuditCount?: BadgeItem,
};

const state: StateItems = {isolationApplyList: [], isolationApplyCount: undefined, isolationAuditList: [], isolationAuditCount: undefined};
const getters = {
    isolationApplyList: (state: StateItems) => state.isolationApplyList,
    isolationApplyCount: (state: StateItems) => state.isolationApplyCount,
    isolationAuditList: (state: StateItems) => state.isolationAuditList,
    isolationAuditCount: (state: StateItems) => state.isolationAuditCount,
}
const mutations = {
    setIsolationApplyList(state: StateItems, isolationApplyList: Array<IsolationApplyVO>) {
        state.isolationApplyList = isolationApplyList
    },
    setIsolationApplyCount(state: StateItems, isolationApplyCount: BadgeItem) {
        state.isolationApplyCount = isolationApplyCount
    },
    setIsolationAuditList(state: StateItems, isolationAuditList: Array<IsolationApplyVO>) {
        state.isolationAuditList = isolationAuditList
    },
    setIsolationAuditCount(state: StateItems, isolationAuditCount: BadgeItem) {
        state.isolationAuditCount = isolationAuditCount
    },
}
const actions = {
    async getIsolationApplyList({commit, dispatch}: VuexOpers) {
        const {list} = await getList(1, 10000, undefined, true)

        if (list) {
            commit('setIsolationApplyList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getIsolationApplyCount({commit, dispatch}: VuexOpers) {
        const data = await getCount(undefined, true)

        if (data) {
            commit('setIsolationApplyCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getIsolationAuditList({commit, dispatch}: VuexOpers) {
        const {list} = await getAuditList(1, 10000, undefined, true)

        if (list) {
            commit('setIsolationAuditList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getIsolationAuditCount({commit, dispatch}: VuexOpers) {
        const data = await getAuditCount()
        if (data) {
            commit('setIsolationAuditCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
}
export default {state, getters, mutations, actions}
