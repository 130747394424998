/**
 * @description 消毒剂信息获取
 */
import {VuexOpers} from "@/common/TypeDefs";
import JobInfo from "@/vo/JobInfo";
import {getJobList} from "@/api/job";

export type StateItems = {
    jobList: Array<JobInfo>,
};

const state: StateItems = {jobList: []};
const getters = {
    jobList: (state: StateItems) => state.jobList,
}
const mutations = {
    setJobList(state: StateItems, jobList: Array<JobInfo>) {
        state.jobList = jobList
    },
}
const actions = {
    async getUserJobList({commit, dispatch}: VuexOpers) {
        const jobList = await getJobList({})

        if (jobList) {
            commit('setJobList', jobList);
            return Promise.resolve(jobList);
        } else {
            return Promise.reject(new Error("无法获取任务列表"));
        }
    },
}
export default {state, getters, mutations, actions}
