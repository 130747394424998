import axios from "@/common/AxiosExt";
import {API} from "./config";
import {GridResult} from "@/vo/GridResult";
import {BadgeItem} from "@/common/TypeDefs";
import {NucleicSuspendApplyVO} from "@/vo/NucleicSuspendApplyVO";

export async function getList(pageNo: number, pageSize: number, type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: GridResult<NucleicSuspendApplyVO> = await axios.get(API.GET_SUSPEND_LIST, {params: {pageNo, pageSize, type}, headers});
    return data;
}

export async function getCount(type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: BadgeItem = await axios.get(API.GET_SUSPEND_COUNT, {params: {type}, headers});
    return data;
}

export async function doEdit(room: NucleicSuspendApplyVO) {
    await axios.post(API.SAVE_SUSPEND, room);
}


export async function doDelete(ids: Array<string>) {
    await axios.post(API.DELETE_SUSPEND, ids);
}

export async function getAuditList(pageNo: number, pageSize: number, type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: GridResult<NucleicSuspendApplyVO> = await axios.get(API.GET_SUSPEND_AUDIT_LIST, {params: {pageNo, pageSize, type}, headers});
    return data;
}

export async function getAuditCount(noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: number = await axios.get(API.GET_SUSPEND_AUDIT_COUNT, {headers});
    return data;
}

export async function doAudit(id: string, flag: number, rejectReason?: string) {
    await axios.get(API.GET_SUSPEND_DO_AUDIT, {params: {id, flag, rejectReason}});
}
