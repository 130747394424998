/**
 * @description 消毒剂信息获取
 */
import {BadgeItem, VuexOpers} from "@/common/TypeDefs";
import {getAuditChildList, getAuditCount, getAuditList} from "@/api/nucleic";
import {NucleicAuditListVO} from "@/vo/NucleicAuditListVO";
import {DateUtils} from "@/common/DateUtils";

export type StateItems = {
    reportItem?: NucleicAuditListVO, reportCount?: BadgeItem,
    reportChildItem?: NucleicAuditListVO, reportChildCount?: BadgeItem,
    reportDate: Date,
};

const state: StateItems = {reportItem: undefined, reportCount: undefined, reportChildItem: undefined, reportChildCount: undefined, reportDate: new Date()};
const getters = {
    reportItem: (state: StateItems) => state.reportItem,
    reportCount: (state: StateItems) => state.reportCount,
    reportChildItem: (state: StateItems) => state.reportChildItem,
    reportChildCount: (state: StateItems) => state.reportChildCount,
    reportDate: (state: StateItems) => state.reportDate,
}
const mutations = {
    setReportItem(state: StateItems, reportItem: NucleicAuditListVO) {
        state.reportItem = reportItem
    },
    setReportCount(state: StateItems, reportCount: BadgeItem) {
        state.reportCount = reportCount
    },
    setReportChildItem(state: StateItems, reportChildItem: NucleicAuditListVO) {
        state.reportChildItem = reportChildItem
    },
    setReportChildCount(state: StateItems, reportChildCount: BadgeItem) {
        state.reportChildCount = reportChildCount
    },
    setReportDate(state: StateItems, reportDate: Date) {
        state.reportDate = reportDate
    },
}
const actions = {
    async getReportItem({commit, dispatch}: VuexOpers) {
        const item = await getAuditList(1, DateUtils.fromTs(state.reportDate.getTime(), 'yyyy-MM-dd'))

        if (item) {
            commit('setReportItem', item);
            return Promise.resolve(item);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getReportCount({commit, dispatch}: VuexOpers) {
        const data = await getAuditCount(DateUtils.fromTs(state.reportDate.getTime(), 'yyyy-MM-dd'), true)

        if (data) {
            commit('setReportCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getReportChildItem({commit, dispatch}: VuexOpers) {
        const item = await getAuditChildList("", "", 1, DateUtils.fromTs(state.reportDate.getTime(), 'yyyy-MM-dd'))

        if (item) {
            commit('setReportChildItem', item);
            return Promise.resolve(item);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getReportChildCount({commit, dispatch}: VuexOpers) {
        const data = await getAuditCount(DateUtils.fromTs(state.reportDate.getTime(), 'yyyy-MM-dd'), true)

        if (data) {
            commit('setReportChildCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
}
export default {state, getters, mutations, actions}
