
import {IonInput, IonSelect, IonSelectOption,} from '@ionic/vue';
import {defineComponent, PropType} from "vue";
import {SelectionItemType} from "@/common/TypeDefs";

export default defineComponent({
  name: 'ClearableSelector',
  props: {
    options: {type: Array as PropType<SelectionItemType[]>},
    placeholder: {type: String},
    viewFlag: {type: Boolean, default: false},
    interface: {type: String},
    modelValue: {type: String},
  },
  components: {IonSelect, IonSelectOption, IonInput},
  emits: ['update:modelValue'],
  data() {
    return {
      optionsWithClear: [] as SelectionItemType[],
      selectedId: this.$props.modelValue,
      interfaceOptions: {
        cssClass: 'clearable-selector-interface'
      }
    }
  },
  unmounted() {
    console.info('unmounted')
  },
  deactivated() {
    console.info('deactivated')
  },
  mounted() {
    console.info('mounted')

  },
  computed: {
    showLabelCalc() {
      return (id: string) => {
        if (this.$props.options) {
          const findItem = this.$props.options.find((optionItem: SelectionItemType) => optionItem.value === id)
          if (findItem)
            return findItem.label
          else
            return ""
        } else {
          return ""
        }
      };
    }
  },
  methods: {
    itemChanged(event: CustomEvent) {
      console.log(event)
      if (event.detail.value === 'CLEAR_SELECTOR_ITEM_FLAG') {
        this.selectedId = undefined
        this.$emit("update:modelValue", null)
      } else {
        this.$emit("update:modelValue", event.detail.value)
      }
    },
  },
  watch: {
    options: {
      immediate: true, //初始化时让handler调用一下
      deep: true,
      //handler什么时候调用？当isHot发生改变时。（一个参数就是newValue）
      handler(newValue, oldValue) {
        console.log('options被修改了', newValue, oldValue)
        this.optionsWithClear = []
        this.optionsWithClear.push({label: "清除", value: "CLEAR_SELECTOR_ITEM_FLAG"})
        if (newValue) {
          this.optionsWithClear.push(...newValue)
        }
      }
    }
  }
});
