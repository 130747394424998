
import {IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {arrowBack, ellipse, square, triangle} from 'ionicons/icons';
import {computed, getCurrentInstance, nextTick, reactive, toRefs} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import {useRoute} from "vue-router";
import {UIUtils} from "@/common/UIUtils";
import ReportItemDef from "@/vo/ReportItemDef";
import {getJobReportItems} from "@/api/page";
import {Constants} from "@/common/Constants";
import {useStore} from "vuex";
import DynamicForm from "@/views/component/DynamicForm.vue";
import {reportJobData} from "@/api/job";

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    DynamicForm
  },
  props: {
    id: {type: String},
    showStatus: {type: String},
    jobTitle: {type: String},
    activiId: {type: String},
  },
  setup(props: any, {emit}: { emit: any }) {
    const route = useRoute();
    const store = useStore();
    const {proxy}: any = getCurrentInstance()

    let temp: {
      pageItems: Array<ReportItemDef>
      itemTypes: { [name: string]: number },
      event?: Event,
    } = {
      pageItems: [],
      itemTypes: Constants.ItemTypes,
      event: undefined,
    }
    const state = reactive(temp);


    const badgeNum = computed(() => {
      return null;
    })

    const back = () => {
      NaviUtils.navPop();
    }

    const gotoPage = (url: string) => {
      console.log(url)
      NaviUtils.navPush(url)
    }

    const confirm = async () => {
      UIUtils.showAlertConfirm("确认", "请点击确认进行提交", async () => {
        try {
          const report = proxy.$refs['dynamicFormId'].getReportData();
          report['jobId'] = props.id
          report['activiId'] = props.activiId === '0' ? null : props.activiId
          await reportJobData(report)
          back()
        } catch (e: any) {
          UIUtils.showToast("", "提交失败:" + e.message, 2000)
          console.error(e)
        }
      }, () => {
        console.log("取消")
      }, "确认", "取消")

    }

    onIonViewDidEnter(() => {
      getJobReportItems(props.id, props.activiId === '0' ? null : props.activiId)
          .then(async (items) => {
            state.pageItems = items
            await nextTick()
            await proxy.$refs['dynamicFormId'].refreshDic()
          })
          .catch((err) => {
            UIUtils.showToast("", "获取页面配置失败", 2000)
          })
    })
    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      arrowBack,
      badgeNum,
      back,
      gotoPage,
      confirm,
    }
  }
}
