
import {IonCol, IonGrid, IonImg, IonRow} from '@ionic/vue';
import {computed, getCurrentInstance, reactive, toRefs} from "vue";
import {uuid} from 'vue-uuid';
import {FileInfoType} from "@/common/TypeDefs";
import {UIUtils} from "@/common/UIUtils";
import {upload} from "@/api/upload"
import {Chooser} from "@awesome-cordova-plugins/chooser"
import {Filters} from "@/common/Filters";

export default {
  name: 'PhotoUploder',
  components: {IonGrid, IonImg, IonRow, IonCol},
  props: {
    title: {type: String},
    subTitle: {type: String},
    required: {type: Boolean},
    modelValue: {type: Array, default: () => []},
    viewFlag: {type: Boolean},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      fileList: Array<FileInfoType>,
      disablePreview: boolean,
      event?: Event,
    } = {
      fileList: props.modelValue ? (typeof props.modelValue === 'string' ? JSON.parse(props.modelValue) : props.modelValue) : [],
      disablePreview: false,
      event: undefined,
    }
    const state = reactive(temp);

    const onClick = () => {
      emit('onClick', props.data);
    }

    const removeFile = (index: number, event?: Event) => {
      try {
        console.info("dblclick");
        state.disablePreview = true;
        state.fileList.splice(index, 1);
        emit('update:modelValue', state.fileList);
        if (event) {
          event.stopPropagation();
          event.cancelBubble = true;
        }
      } finally {
        setTimeout(() => {
          state.disablePreview = false;
        }, 1000);
      }
    }

    const takeFile = async (jobId: string) => {
      const fileInfo = await Chooser.getFile();
      if (fileInfo) {
        const dissmissFunc = await UIUtils.showLoading("正在上传文件");
        try {
          const convertedUrl = fileInfo.uri;//fileUrls.map(fileUrl => Capacitor.convertFileSrc(fileUrl));
          const id = uuid.v4();
          try {
            const result = await upload(id, jobId, convertedUrl, fileInfo.name);
            console.info("file upload result = ", result);
            if (result) {
              state.fileList.push({id: result.id, name: result.name, url: result.url, originFileName: result.originFileName ? result.originFileName : '未命名'});
            }
          } catch (e) {
            console.error("上传文件" + fileInfo.name + "失败", e);
            UIUtils.showToast("", "文件上传失败", 2000);
          }

          console.info('state.fileList = ', state.fileList);
          emit('update:modelValue', state.fileList);
        } finally {
          dissmissFunc();
        }
      } else {
        console.log("没有选择任何文件")
      }
    }

    const fileFirstLine = computed(() => {
      if (state.fileList.length > 3) {
        return state.fileList.slice(0, 3);
      } else {
        return state.fileList;
      }
    })

    const fileOtherLine = computed(() => {
      return (line: number) => {
        console.info(line);
        if (state.fileList.length > 3 + 4 * line) {
          if (state.fileList.length < 3 + 4 * (line + 1)) {
            return state.fileList.slice(3 + 4 * line, state.fileList.length);
          } else {
            return state.fileList.slice(3 + 4 * line, 3 + 4 * (line + 1));
          }
        } else {
          return [];
        }
      }
    })

    const fileLineNum = computed(() => {
      if (state.fileList.length > 3) {
        const indices = [];
        for (let i = 0; i < (state.fileList.length - 3) / 4 + 1; i++) {
          indices.push(i);
        }
        return indices;
      } else {
        return [];
      }
    })

    emit('update:modelValue', state.fileList);

    return {
      ...toRefs(state),
      onClick,
      takeFile,
      removeFile,
      fileLineNum,
      fileFirstLine,
      fileOtherLine,
      isBrowserCalc: Filters.isBrowserCalc,
    }
  }
}
