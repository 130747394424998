export class Constants {
    public static TOKEN_KEY = "loginToken";
    public static USER_INFO = "userInfo";
    public static ApiCode = {
        SUCCESS: 1,
        ERROR_NUCLEIC_NOT_REPORT_IS_NOT_EMPTY: 30019,
    }
    public static APP_HEADER = {
        BUILD_TIME: "BUILD-TIME",
        VERSION: "APP-VERSION",
        VERSION_CODE: "VERSION-CODE",
        ID: "APP-ID",
        UPDATE_ID: "UPDATE-ID",
        PLATFORM: "APP-PLATFORM",
        SOURCE_TYPE: "X-SOURCE-TYPE",
    }
    public static Validation = {
        VALIDATION_SUCCESS: '',
        VALIDATION_ERROR: 'danger'
    }
    public static ItemTypes = {
        INPUT: 0,
        RADIO: 1,
        COMBO: 2,
        PICTURE: 3,
        VIDEO: 4,
        SINGLE_CHECK: 5,
        DATE_PICKER: 6,
        TIME_PICKER: 7,
        DATETIME_PICKER: 8,
        FILE_UPLOADER: 9,
        USER_SELECTION: 10,
        INPUT_TABLE: 11,
    }
    public static InputTableType = {
        //单列输入框
        SINGLE_INPUT: 1,
        //单列/双列单选框
        SELECTION: 2,
        //前两列显示，最后一列输入框
        VIEW_INPUT: 3,
    }
    public static SubTypes = {
        NO_SUB_TYPE: 0,
        MATERIAL_SELECTION: 1,
        MATERIAL_SELECTION_APPLY: 2,
    }
    public static DataTypes = {
        STRING: 0,
        INTEGER: 1,
        FLOAT: 2,
        BOOLEAN: 3,
    }

    public static Opportunity = {
        //表单页显示
        FORM: 0,
        //点击某按钮后显示
        AFTER_BUTTON: 1
    }

    public static TrigShowType = {
        //任意匹配
        ANY: 0,
        //全部匹配
        ALL: 1,
    }

    public static CleanStatus = {
        //已开始
        BEGIN: 0,
        //提交未签字
        COMMIT: 1,
        //已签字
        SIGNED: 4,
        //驳回
        REJECT: 2,
        //归档
        ARCHIVE: 3,
    }

    public static ApplyStatus = {
        //驳回
        REJECT: -1,
        //未提交
        UNCOMMIT: 0,
        //待审核
        COMMIT: 1,
        //通过
        FINISH: 2,
    }

    public static NucleicReportStatus = {
        //已驳回
        REJECT: -10,
        //未填写
        NONE: 0,
        //未提交
        UNCOMMIT: 10,
        //待小组审核
        TEAM_AUDIT: 20,
        //待岗位审核
        POST_AUDIT: 30,
        //待部门审核
        DEPART_AUDIT: 40,
        //待公司审核
        COMP_AUDIT: 50,
        //公司审核通过
        COMP_PASSED: 60,
    }

    public static NucleicAuditStatus = {
        //未提交
        UNCOMMIT: 0,
        //已提交
        COMMIT: 10,
    }

    public static IsolationStatus = {
        //解除申请驳回
        END_REJECT: -20,
        //隔离申请驳回
        APPLY_REJECT: -10,
        //未提交
        UNCOMMIT: 0,
        //隔离申请待岗位审批
        POST_AUDIT: 10,
        //隔离申请待部门审批
        DEPART_AUDIT: 20,
        //隔离申请完成
        APPLY_FINISH: 30,
        //解除申请待岗位审批
        END_POST_AUDIT: 40,
        //解除申请待部门审批
        END_DEPART_AUDIT: 50,
        //解除申请完成
        END_APPLY_FINISH: 60,
    }

    public static HomeListType = {
        // 待办
        TODO: 0,
        //已办
        DONE: 1,
    }

    public static ChildListType = {
        // 未提交
        UNCOMMIT: 0,
        // 已提交
        COMMIT: 1,
        //已审核
        AUDIT: 2,
    }

    public static FlightType = [
        '普通', '冷链/疫情/中高风险地区'
    ]
    public static readonly REPORT_WORK_FLOW_ID = "report";
    public static readonly IN_OUT_WORK_FLOW_ID = "inout";
    public static readonly MATERIAL_WORK_FLOW_ID = "material";
    public static readonly NUCLEIC_WORK_FLOW_ID = "nucleic";

    public static defaultDicRefCache = {
        CONST_QUANTITY: [{label: '1件', value: 1}, {label: '2件', value: 2}, {label: '3件', value: 3},
            {label: '4件', value: 4}, {label: '5件', value: 5}, {label: '6件', value: 6},
            {label: '7件', value: 7}, {label: '8件', value: 8}, {label: '9件', value: 9}, {label: '10件', value: 10}]
    }
}
