import axios from "@/common/AxiosExt";
import {API} from "./config";
import {GridResult} from "@/vo/GridResult";
import {ToolsCleanVO} from "@/vo/ToolsCleanVO";
import {BadgeItem} from "@/common/TypeDefs";

export async function getList(pageNo: number, pageSize: number, type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: GridResult<ToolsCleanVO> = await axios.get(API.GET_TOOLSCLEAN_LIST, {params: {pageNo, pageSize, type}, headers});
    return data;
}

export async function getCount(type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: BadgeItem = await axios.get(API.GET_TOOLSCLEAN_COUNT, {params: {type}, headers});
    return data;
}

export async function doEdit(tools: ToolsCleanVO) {
    await axios.post(API.SAVE_TOOLSCLEAN, tools);
}


export async function doDelete(ids: Array<string>) {
    await axios.post(API.DELETE_TOOLSCLEAN, ids);
}

export async function getLastReport(areaId: string): Promise<ToolsCleanVO> {
    return await axios.get(API.GET_TOOLSCLEAN_LAST_REPORT, {params: {areaId}});
}
