import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_menu_item = _resolveComponent("menu-item")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("您好" + _toDisplayString($setup.userNameCalc), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => ($setup.doRefresh($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content, {
                "pulling-icon": $setup.chevronDownCircleOutline,
                "pulling-text": "下拉刷新",
                "refreshing-spinner": "circles",
                "refreshing-text": "刷新中..."
              }, null, 8 /* PROPS */, ["pulling-icon"])
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_ion_grid, { style: {"padding-top":"10px"} }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuItemcalc, (item, index) => {
                return (_openBlock(), _createBlock(_component_ion_row, {
                  key: item.id,
                  class: _normalizeClass({ 'menu-row': index%2==0})
                }, {
                  default: _withCtx(() => [
                    (index<$setup.menuItemcalc.length && index%2 == 0)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 0,
                          size: "6"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_menu_item, {
                              disabled: !(_ctx.user&&_ctx.user.text),
                              title: $setup.menuItemcalc[index].title,
                              image: $setup.menuItemcalc[index].icon,
                              badge: _ctx.badgeNumList[index],
                              data: $setup.menuItemcalc[index].path,
                              onOnClick: $setup.gotoPage
                            }, null, 8 /* PROPS */, ["disabled", "title", "image", "badge", "data", "onOnClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true),
                    (index<$setup.menuItemcalc.length && index%2 == 0)
                      ? (_openBlock(), _createBlock(_component_ion_col, {
                          key: 1,
                          size: "6"
                        }, {
                          default: _withCtx(() => [
                            (index<$setup.menuItemcalc.length-1)
                              ? (_openBlock(), _createBlock(_component_menu_item, {
                                  key: 0,
                                  disabled: !(_ctx.user&&_ctx.user.text),
                                  title: $setup.menuItemcalc[index+1].title,
                                  badge: _ctx.badgeNumList[index+1],
                                  image: $setup.menuItemcalc[index+1].icon,
                                  data: $setup.menuItemcalc[index+1].path,
                                  onOnClick: $setup.gotoPage
                                }, null, 8 /* PROPS */, ["disabled", "title", "badge", "image", "data", "onOnClick"]))
                              : _createCommentVNode("v-if", true)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }))
}