
import {IonButton, IonButtons, IonContent, IonFabButton, IonHeader, IonIcon, IonItemOption, IonLabel, IonPage, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, defineComponent, getCurrentInstance, reactive, toRefs} from "vue";
import {add, arrowBack, checkmark, chevronDownCircleOutline, close, trash} from "ionicons/icons";
import {doDelete, getList} from "@/api/roomclean"
import {useStore} from "vuex";
import {RoomCleanVO} from "@/vo/RoomCleanVO";
import {UIUtils} from "@/common/UIUtils";
import RoomCleanEditPage from "./RoomCleanEditPage.vue";
import ComplexList from "@/views/component/ComplexList.vue";
import {Filters} from "@/common/Filters";
import {Constants} from "@/common/Constants";

export default defineComponent({
  components: {
    IonPage,
    IonLabel,
    IonContent,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonItemOption,
    IonIcon,
    IonFabButton,
    ComplexList,
  },
  props: {
    type: {
      type: String, default: null
    },
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      pageNo: number,
      pageSize: number,
      isDisabled: boolean,
      list: Array<RoomCleanVO>,
      listRef: any,
      event?: Event,
    } = {
      pageNo: 1,
      pageSize: 10,
      isDisabled: false,
      list: [],
      listRef: null,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const viewFlagFunc = (item: RoomCleanVO) => {
      return item.status === Constants.CleanStatus.COMMIT || item.status === Constants.CleanStatus.ARCHIVE || item.status === Constants.CleanStatus.SIGNED
    }

    const viewFlagCalc = computed(() => {
      return (item: RoomCleanVO) => viewFlagFunc(item)
    })

    const openEdit = async (vo?: RoomCleanVO) => {
      if (vo) {
        const viewFlag = viewFlagFunc(vo)
        await UIUtils.showModal(RoomCleanEditPage, {title: viewFlag ? '查看' : '编辑', item: vo, viewFlag: viewFlag})
        await doRefresh()
      } else {
        await UIUtils.showModal(RoomCleanEditPage, {title: '新增', item: vo, viewFlag: false})
        await doRefresh()
      }
    }

    const deleteItem = async (item: RoomCleanVO) => {
      UIUtils.showAlertConfirm("删除", "是否确认删除?", async () => {
        if (item.id) {
          await doDelete([item.id])
          await doRefresh()
        }
      }, () => {
        console.log("取消")
      })
    }

    const deleteItems = async (items: Array<RoomCleanVO>) => {
      console.log(items)
      state.listRef.closeSliding()
    }

    const fetchData = async () => {
      //只有新创建的任务可以通过普通方法查询任务列表，提交后的任务需要做权限过滤
      const {total, list} = await getList(state.pageNo, state.pageSize, props.type)
      return list
    }

    const doRefresh = async (cb?: (data: Array<any>) => void) => {
      try {
        state.pageNo = 1
        state.pageSize = 10
        state.list = await fetchData()
        store.dispatch("clean/getRoomCleanCount")
        if (cb) {
          cb(state.list)
        }
      } finally {
        if (cb) {
          cb([])
        }
      }
    }

    const doInfinite = async (pageNo: number, cb: (data: Array<any>) => void) => {
      try {
        state.pageNo = pageNo
        const data = await fetchData()
        cb(data)
      } catch (e) {
        cb([])
      }
    }

    //此处页面刷新不生效，需要在tabs页监听
    onIonViewDidEnter(async () => {
      await doRefresh()
    })

    return {
      ...toRefs(state),
      arrowBack, add, trash, close, checkmark,
      chevronDownCircleOutline,
      openEdit,
      deleteItem,
      doRefresh,
      doInfinite,
      deleteItems,
      back,
      statusNameCalc: Filters.getCleanStatusName,
      viewFlagCalc,
      timeFormatCalc: Filters.timeFormatCalc,
    }
  }
})
