import axios from "@/common/AxiosExt";
import {API} from "./config";
import {UserInfo} from "@/vo/UserInfo";

export async function reportData(report: any) {
    const data: string = await axios.post(API.INOUT_REPORT, report);
    return data;
}

export async function getUserInfo() {
    const data: UserInfo = await axios.get(API.GET_USER_INFO);
    return data;
}



