
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import {UserInfo} from "@/vo/UserInfo";
import {NaviUtils} from "@/common/NaviUtils";
import {changePassword} from "@/api/common";
import {composeCheck} from "@/common/PasswordUtils";
import {Runtimes} from "@/common/Runtimes";

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonInput, IonLabel, IonButtons, IonButton, IonIcon,
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      form: { originPassword: string | null, newPassword: string | null, newPasswordAgain: string | null },
      userInfo: UserInfo,
      event?: Event,
    } = {
      form: {originPassword: null, newPassword: null, newPasswordAgain: null},
      userInfo: store.getters["user/userInfo"],
      event: undefined,
    }
    const state = reactive(temp);

    const back = () => {
      console.log("back")
      NaviUtils.navPop();
    }

    const save = async () => {
      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }
      try {
        await changePassword({oldpass: state.form.originPassword, pass: state.form.newPassword})
        Runtimes.needModifyPwd = false
        back()
      } catch (e: any) {
        console.error(e)
        UIUtils.showToast("", e.message, 2000)
      }

    }


    const validation = () => {
      if (!state.form.originPassword || state.form.originPassword.trim() == '') {
        return "原密码不允许为空";
      }
      if (!state.form.newPassword || state.form.newPassword.trim() == '') {
        return "新密码不允许为空";
      }
      if (state.form.newPassword !== state.form.newPasswordAgain) {
        return "两次密码输入不一致";
      }
      if (!composeCheck(state.form.newPassword)) {
        return "密码需要同时包含[大小写字母][数字][特殊符号]且长度不小于8位";
      }
      return null;
    }

    onMounted(() => {
      state.form = {originPassword: null, newPassword: null, newPasswordAgain: null}
    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
    }
  }
}
