
import {
  IonItem, IonList, IonModal, IonPage, IonSearchbar, IonButton,
  IonButtons,
  IonTitle,
  IonContent,
  IonHeader,
  IonToolbar
} from '@ionic/vue';
import {getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {arrowBack} from 'ionicons/icons';
import {useStore} from "vuex";
import {VehicleInfoVO} from "@/vo/VehicleInfoVO";

export default {
  name: 'VehicleSelectionModal',
  components: {
    IonModal, IonList, IonItem, IonPage, IonSearchbar, IonButton,
    IonButtons,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar
  },
  props: {
    open: {type: Boolean, default: false},
    modelValue: {type: Object, default: null},
  },
  emits: ['onClose', 'onSelect', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      searchText: string | null,
      event?: Event,
      vehicleList: Array<VehicleInfoVO>,
      filterVehicleList: Array<VehicleInfoVO>,
    } = {
      searchText: null,
      event: undefined,
      vehicleList: store.getters["selector/vehicleList"],
      filterVehicleList: store.getters["selector/vehicleList"]
    }
    const state = reactive(temp);

    const onSelect = (item: VehicleInfoVO) => {
      emit('onSelect', item);
      emit('update:modelValue', item);
    }
    const onClose = () => {
      emit('onClose');
    }
    const onSearch = (e: Event) => {
      console.log("onSearch, ", state.searchText, e)
      state.filterVehicleList = state.vehicleList.filter(item => (state.searchText && state.searchText.trim() != '') ? item.carNumber.indexOf(state.searchText) >= 0 : true)
    }

    onMounted(() => {
      console.info("VehicleSelectionModal Mount")
    })

    return {
      ...toRefs(state),
      arrowBack,
      onSelect,
      onClose,
      onSearch,
    }
  }
}
