
import {
  IonActionSheet,
  IonContent,
  IonHeader,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonListHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonList,
  IonImg,
  IonIcon,
  onIonViewDidEnter
} from '@ionic/vue';
import {arrowBack, chevronDownCircleOutline, documentOutline, gitBranch} from 'ionicons/icons';
import {NaviUtils} from "@/common/NaviUtils";
import {getJobList} from "@/api/job";
import JobInfo from "@/vo/JobInfo";
import {reactive, toRefs} from "vue";
import {ActionSheetButton} from "@ionic/core/dist/types/components/action-sheet/action-sheet-interface";
import {InAppBrowser} from "@awesome-cordova-plugins/in-app-browser";
import {API} from "@/api/config";
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";

export default {
  components: {
    IonItem,
    IonLabel,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonListHeader,
    IonActionSheet,
    IonItemSliding,
    IonItemOptions,
    IonItemOption, IonRefresher, IonRefresherContent,
    IonButton,
    IonButtons,
    IonList,
    IonImg,
    IonIcon,
  },
  props: {
    type: {
      type: String, default: null
    },
  },
  setup(props: any) {
    let temp: {
      jobList: Array<JobInfo>,
      jobSelectSheetOpenFlag: boolean,
      jobActiviList: Array<ActionSheetButton>,
      event?: Event,
    } = {
      jobList: [],
      jobSelectSheetOpenFlag: false,
      jobActiviList: [],
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const closeJobSelectSheet = () => {
      state.jobSelectSheetOpenFlag = false
    }

    const openWebView = (job: JobInfo) => {
      InAppBrowser.create(API.HOST + "/api/prevent/page/getFlowChart?id=" + job.id, "_blank", {
        hideurlbar: 'yes',
        hardwareback: 'yes',
        enableViewportScale: 'yes'
      })
    }
    const viewProcess = (job: JobInfo) => {
      console.log("viewProcess")
      openWebView(job)
    }

    const openJobActivSelectSheet = (job: JobInfo) => {
      if (job && job.multiActivi) {
        const temp = []
        for (const activiId in job.multiActivi) {
          temp.push({
            text: job.multiActivi[activiId],
            icon: documentOutline,
            cssClass: 'activi-button',
            handler: () => {
              NaviUtils.navPush('/job/detail/' + job.multiActivi[activiId] + '/' + job.id + '/' + encodeURIComponent(job.flightNo) + '/' + activiId)
            },
            data: activiId
          })
        }
        state.jobActiviList = temp
        state.jobSelectSheetOpenFlag = true
      } else {
        if (job.operType === 0) {
          NaviUtils.navPush('/job/detail/' + job.nodeName + '/' + job.id + '/' + encodeURIComponent(job.flightNo) + '/0')
        } else if (job.operType === 1) {
          // NaviUtils.navPush('/job/flow/' + job.id)
          openWebView(job)
        }
      }
    }

    const doRefresh = async (event?: RefresherCustomEvent) => {
      try {
        state.jobList = await getJobList({operType: props.type})
      } finally {

        if (event) {
          event.target.complete()
        }

      }
    }
    onIonViewDidEnter(async () => {
      await doRefresh()
      console.log("jobList = ", state.jobList)
    })

    return {
      ...toRefs(state),
      gitBranch,
      arrowBack,
      chevronDownCircleOutline,
      back,
      closeJobSelectSheet,
      openJobActivSelectSheet,
      viewProcess,
      doRefresh,
    }
  }
}
