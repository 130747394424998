
import {
  CheckboxCustomEvent,
  InputCustomEvent,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
    IonImg,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonItemDivider,
    IonItemGroup,
} from '@ionic/vue';
import {computed, getCurrentInstance, onMounted, reactive, toRefs, watch} from "vue";
import {arrowBack} from 'ionicons/icons';
import {useStore} from "vuex";
import {ToolsInfoVO} from "@/vo/ToolsInfoVO";
import {IndexBarList, ToolSelectType} from "@/common/TypeDefs";
import {ArrayUtils} from "@/common/ArrayUtils";
import IndexBar from "@/views/component/IndexBar.vue"
import {ToolsClassVO} from "@/vo/ToolsClassVO";

export default {
  name: 'ToolsSelectionModal',
  components: {
    IonModal, IonList, IonItem, IonPage, IonSearchbar, IonCheckbox, IonLabel, IonInput, IonButton,
    IonButtons,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonItemDivider,
    IonItemGroup,IndexBar
  },
  props: {
    open: {type: Boolean, default: false},
    modelValue: {type: Object, default: null},
    value: {type: Object, default: null},
  },
  emits: ['onClose', 'onFinish', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      searchText: string | null,
      event?: Event,
      toolsList: Array<ToolsInfoVO>,
      filterToolsList: Array<ToolsInfoVO>,
      toolsClassList: Array<ToolsClassVO>,
      filterToolsClassList: Array<ToolsClassVO>,
      checkedValues: { [id: string]: boolean },
      checkedItemNum: { [id: string]: number },
    } = {
      searchText: null,
      event: undefined,
      toolsList: store.getters["selector/toolsList"],
      filterToolsList: store.getters["selector/toolsList"],
      toolsClassList: store.getters["selector/toolsClassList"],
      filterToolsClassList: store.getters["selector/toolsClassList"],
      checkedValues: props.modelValue ? Object.fromEntries(props.modelValue.map((item: any) => {
        return [item.id, true]
      })) : {},
      checkedItemNum: props.modelValue ? Object.fromEntries(props.modelValue.map((item: any) => {
        return [item.id, item.num ? item.num : 1]
      })) : {},
    }
    const state = reactive(temp);

    const onSave = () => {
      const items: Array<ToolSelectType> = []
      for (let id in state.checkedValues) {
        if (state.checkedValues[id]) {
          const foundItem = ArrayUtils.findItem(state.filterToolsList, "id", id)
          if (state.checkedItemNum[id] <= 0 || !state.checkedItemNum[id]) {
            state.checkedItemNum[id] = 1
          }
          items.push({id: id, name: foundItem.name, num: state.checkedItemNum[id], includeTools: foundItem.includeTools})
        }
      }
      console.log(items)
      emit("onFinish", items)
    }

    const onClose = () => {
      emit('onClose');
    }

    const onSearch = (e: Event) => {
      console.log("onSearch, ", state.searchText, e)
      state.filterToolsList = state.toolsList.filter(item => (state.searchText && state.searchText.trim() != '') ? item.name.indexOf(state.searchText) >= 0 : true)
      let filterToolsClassList: Array<ToolsClassVO> = []

      state.toolsClassList.forEach(classItem => {
        const filtedItems = classItem.tools.filter(item => (state.searchText && state.searchText.trim() != '') ? item.name.indexOf(state.searchText) >= 0 : true)
        filterToolsClassList.push({id: classItem.id, name: classItem.name, tools: filtedItems})
      })
      state.filterToolsClassList = filterToolsClassList
    }

    const itemCheckChange = (event: CheckboxCustomEvent, id: string, name: string) => {
      console.log(event.detail)
      console.log(id + "=" + name)
      if (event.detail.checked) {
        state.checkedItemNum[id] = 1
      } else {
        delete state.checkedItemNum[id]
      }
    }

    const numberChange = (event: InputCustomEvent, id: string) => {
      if (event.detail.value && event.detail.value.trim() !== '') {
        console.log(event.detail)
      } else {
        // state.checkedItemNum[id] = 1
      }
    }

    const indexBarItemsCalc = computed(() => {
      if (state.toolsClassList) {
        const indexBarList: IndexBarList = []
        for (let i = 0; i < state.toolsClassList.length && i < 5; i++) {
          indexBarList.push({refId: 'group_' + state.toolsClassList[i].id, name: state.toolsClassList[i].name})
        }
        return indexBarList;
      }
      return [];
    })

    watch(() => props.modelValue, (newValue, oldValue) => { //直接监听
      state.checkedValues = newValue;
      state.checkedValues = newValue ? Object.fromEntries(newValue.map((item: any) => {
        return [item.id, true]
      })) : {}
      state.checkedItemNum = newValue ? Object.fromEntries(newValue.map((item: any) => {
        return [item.id, item.num ? item.num : 1]
      })) : {}
    });

    onMounted(() => {
      console.info("VehicleSelectionModal Mount")
    })

    return {
      ...toRefs(state),
      arrowBack,
      onSave,
      onClose,
      onSearch,
      itemCheckChange,
      numberChange,
      indexBarItemsCalc,
    }
  }
}
