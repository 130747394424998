import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_dynamic_form = _resolveComponent("dynamic-form")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_after_confirm_page = _resolveComponent("after-confirm-page")!
  const _component_ion_modal = _resolveComponent("ion-modal")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.back()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        style: {"color":"black"},
                        icon: $setup.arrowBack
                      }, null, 8 /* PROPS */, ["icon"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("物资申领 - " + _toDisplayString($setup.statusNameCalc), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_dynamic_form, {
            ref: "dynamicFormId",
            "page-items": _ctx.pageItems
          }, null, 8 /* PROPS */, ["page-items"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_footer, { class: "ion-no-border" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "footer-tool-bar" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        class: "ion-align-self-center ion-justify-content-center",
                        style: {"display":"flex","padding-left":"8px","padding-right":"8px"},
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttons, (button, index) => {
                            return (_openBlock(), _createBlock(_component_ion_button, {
                              size: "large",
                              color: "tertiary",
                              style: {"width":"100%"},
                              onClick: ($event: any) => ($setup.confirm(button.id,button.extData)),
                              key: index
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(button.label), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_modal, {
        "is-open": _ctx.afterConfirmModalOpen,
        "css-class": "my-custom-class",
        onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => ($setup.setAfterConfirmOpen(false)))
      }, {
        default: _withCtx(() => [
          (_ctx.afterConfirmModalOpen)
            ? (_openBlock(), _createBlock(_component_after_confirm_page, {
                key: 0,
                "page-items": _ctx.pageItems,
                onOnFinish: $setup.finishData
              }, null, 8 /* PROPS */, ["page-items", "onOnFinish"]))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["is-open"])
    ]),
    _: 1 /* STABLE */
  }))
}