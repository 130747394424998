
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import VideoUploader from "@/views/component/VideoUploader.vue";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {NucleicSuspendApplyVO} from "@/vo/NucleicSuspendApplyVO";
import {DateUtils} from "@/common/DateUtils";
import {doAudit} from "@/api/suspend";

export default {
  components: {
    IonPage,
    IonHeader,
    IonModal,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonDatetime,
    IonDatetimeButton,
    PhotoUploader,
    VideoUploader
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      startDate?: string,
      endDate?: string,
      form: NucleicSuspendApplyVO,
      event?: Event,
    } = {
      startDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.startDate) : DateUtils.tsToIsoString(new Date().getTime()),
      endDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.endDate) : DateUtils.tsToIsoString(new Date().getTime()),
      form: (props.params && props.params.item) ? props.params.item : {},
      event: undefined,
    }
    const state = reactive(temp);

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const audit = async (id: string, flag: number, rejectReason?: string) => {
      try {
        console.info(state)
        await doAudit(id, flag, rejectReason)
        back()
      } catch (e) {
        console.error(e)
        UIUtils.showToast("", "保存数据失败", 2000)
      }
    }

    const doAuditFunc = async (flag: number) => {
      const msg = validation(flag)
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      let confirmMsg = ""
      if (flag === 0) {
        confirmMsg = "是否确认驳回?"
      } else if (flag === 1) {
        confirmMsg = "是否确认通过?"
      }
      UIUtils.showAlertConfirm("确认", confirmMsg, async () => {
        await audit(state.form.id, flag, state.form.rejectReason)
      }, () => {
        console.log("取消")
      }, "提交", "取消")

    }

    const validation = (flag: number) => {
      if (flag === 0) {
        if (!state.form.rejectReason || state.form.rejectReason.trim() == '') {
          return "驳回原因不允许为空";
        }
      }
      return null;
    }

    const rejectedCalc = computed(() => {
      return state.form.status === Constants.ApplyStatus.REJECT
    })

    onMounted(() => {
      console.log("")
    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      audit,
      doAuditFunc,
      rejectedCalc,
      CleanStatus: Constants.ApplyStatus,
      timeFormatCalc: Filters.timeFormatCalc,
      cleanStatusCreateCalc: Filters.cleanStatusCreateCalc,
    }
  }
}
