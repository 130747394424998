
import {IonCol, IonGrid, IonRow} from '@ionic/vue';
import {computed, getCurrentInstance, reactive, toRefs} from "vue";
import MyPopover from "@/views/component/MyPopover.vue";
import {SelectionItemType} from "@/common/TypeDefs";

export default {
  name: 'ItemChecker',
  components: {IonGrid, IonRow, IonCol, MyPopover},
  props: {
    title: {type: String},
    popTitle: {type: String},
    required: {type: Boolean},
    validValues: {type: Array},
    multiple: {type: Boolean},
    modelValue: {type: Array, default: () => []},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      openState: boolean,
      selectedItems: Array<string | number>,
      event?: Event,
    } = {
      openState: false,
      selectedItems: [],
      event: undefined,
    }
    const state = reactive(temp);

    const openPopover = () => {
      state.openState = true
    }
    const onClose = (values: Array<SelectionItemType>) => {
      state.openState = false;
      console.log("所选项:", values);
      emit('update:modelValue', values);
    }

    const selectItemLabel = computed(() => {
      const value = props.modelValue ? (typeof props.modelValue === 'string' ? JSON.parse(props.modelValue) : props.modelValue) : []
      if (value && value.length > 0) {
        return value.map((item: SelectionItemType) => item.label).reduce((total: string, item: string) => total + ',' + item);
      }
      return '';
    })

    return {
      ...toRefs(state),
      onClose,
      selectItemLabel,
      openPopover,
    }
  }
}
