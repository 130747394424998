
import {IonPopover} from '@ionic/vue';
import {getCurrentInstance, reactive, toRefs, watch} from "vue";
import PopSelection from "@/views/component/PopSelection.vue";
import {SelectionItemType} from "@/common/TypeDefs";

export default {
  name: 'MyPopover',
  components: {IonPopover, PopSelection},
  props: {
    title: {type: String, default: null},
    items: {type: Array},
    mutiple: {type: Boolean, default: false},
    open: {type: Boolean, default: false},
    modelValue: {type: Array, default: null},
  },
  emits: ['onClose', 'onSelect', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      event?: Event,
      isOpen: boolean,
      selectionItems: Array<SelectionItemType>,
    } = {
      event: undefined,
      isOpen: props.open,
      selectionItems: [],
    }
    const state = reactive(temp);

    const onSelect = (item: Array<SelectionItemType>) => {
      if (!props.mutiple) {
        onClose();
      }
    }
    const onClose = () => {
      emit('onClose', state.selectionItems);
      emit('update:modelValue', state.selectionItems);
      state.isOpen = false;
    }

    watch(() => props.open, (newValue, oldValue) => { //直接监听
      state.isOpen = newValue;
    });

    return {
      ...toRefs(state),
      onSelect,
      onClose,
    }
  }
}
