import {VideoEditor} from "@awesome-cordova-plugins/video-editor";

export class VideoUtils {
    public static async compress(fileName: string, progressCallback?: (progress: number) => void) {
        const startIndex = fileName.lastIndexOf("/")
        const endIndex = fileName.lastIndexOf(".")
        let path = fileName
        let fileOriginName = ""
        let fileExtName = ""
        if (startIndex >= 0) {
            path = fileName.substring(0, startIndex) + "/"
            if (endIndex >= 0) {
                fileOriginName = fileName.substring(startIndex + 1, endIndex)
                fileExtName = fileName.substring(endIndex)
            }
        }

        const outputFileName = fileOriginName + "_comp" + fileExtName


        return await VideoEditor.transcodeVideo({
            fileUri: "file://" + fileName, // the path to the video on the device
            outputFileName: outputFileName, // the file name for the transcoded video
            outputFileType: VideoEditor.OutputFileType.MPEG4, // android is always mp4
            optimizeForNetworkUse: VideoEditor.OptimizeForNetworkUse.YES, // ios only
            saveToLibrary: true, // optional, defaults to true
            deleteInputFile: false, // optional (android only), defaults to false
            maintainAspectRatio: true, // optional (ios only), defaults to true
            width: 640, // optional, see note below on width and height
            height: 640, // optional, see notes below on width and height
            videoBitrate: 1000000, // optional, bitrate in bits, defaults to 1 megabit (1000000)
            fps: 24, // optional (android only), defaults to 24
            audioChannels: 2, // optional (ios only), number of audio channels, defaults to 2
            audioSampleRate: 44100, // optional (ios only), sample rate for the audio, defaults to 44100
            audioBitrate: 128000, // optional (ios only), audio bitrate for the video in bits, defaults to 128 kilobits (128000)
            progress: (info) => {
                if (progressCallback) {
                    progressCallback(info)
                } else {
                    console.log(info)
                }
            } // info will be a number from 0 to 100
        });
    }
}
