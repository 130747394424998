
import {IonImg, IonLabel} from '@ionic/vue';
import {getCurrentInstance, reactive, toRefs} from "vue";

export default {
  name: 'MenuItem',
  components: {IonLabel, IonImg},
  props: {
    title: {type: String},
    image: {type: String},
    data: {type: String},
    badge: {type: Number},
    disabled: {type: Boolean, default: false}
  },
  emits: ['onClick'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      event?: Event,
    } = {
      event: undefined,
    }
    const state = reactive(temp);

    const onClick = () => {
      if (!props.disabled) {
        emit('onClick', props.data);
      } else {
        console.warn("菜单被禁用")
      }
    }

    return {
      ...toRefs(state),
      onClick,
    }
  }
}
