
import {
  InfiniteScrollCustomEvent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  onIonViewDidEnter
} from '@ionic/vue';
import {NaviUtils} from "@/common/NaviUtils";
import {defineComponent, getCurrentInstance, reactive, toRefs} from "vue";
import {add, arrowBack, chevronDownCircleOutline, trash} from "ionicons/icons";
import {MaterialApplyVO} from "@/vo/MaterialApplyVO";
import {getList, getProcessableMaterialApplyList} from "@/api/material"
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {useStore} from "vuex";

export default defineComponent({
  components: {
    IonPage, IonItemOptions, IonItemOption, IonFab, IonFabButton, IonIcon, IonItemSliding, IonRefresher, IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },
  props: {
    type: {type: String},
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      pageNo: number,
      pageSize: number,
      isDisabled: boolean,
      applyList: Array<MaterialApplyVO>,
      event?: Event,
    } = {
      pageNo: 1,
      pageSize: 10,
      isDisabled: false,
      applyList: [],
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const openApply = (apply?: MaterialApplyVO) => {
      if (apply) {
        store.commit('form/setMaterialApplyDetail', apply)
        NaviUtils.navPush('/material/detail/' + apply.id)
      } else {
        store.commit('form/setMaterialApplyDetail', null)
        NaviUtils.navPush('/material/detail/-1')
      }
    }

    const deleteApply = (refId: string, id: string) => {
      console.log("删除ID = " + id)
      proxy.$refs[refId][0].$el.closeOpened()
    }

    const fetchData = async () => {
      //只有新创建的任务可以通过普通方法查询任务列表，提交后的任务需要做权限过滤
      if (props.type === 10) {
        const {total, list} = await getList(parseInt(props.type), state.pageNo, state.pageSize)
        return list
      } else {
        const {total, list} = await getProcessableMaterialApplyList(parseInt(props.type), state.pageNo, state.pageSize)
        return list
      }
    }
    const doRefresh = async (event: RefresherCustomEvent | null) => {
      try {
        state.pageNo = 1
        state.pageSize = 10
        state.isDisabled = false
        state.applyList = await fetchData()
      } finally {

        if (event) {
          event.target.complete()
        }

      }
    }

    const doInfinite = async (event: InfiniteScrollCustomEvent) => {
      try {
        state.pageNo++
        const data = await fetchData()
        if (data.length === 0) {
          state.isDisabled = true;
        } else {
          state.applyList.push(...data)
        }
      } finally {
        await event.target.complete()
      }
    }

    //此处页面刷新不生效，需要在tabs页监听
    onIonViewDidEnter(async () => {
      await doRefresh(null)
    })

    return {
      ...toRefs(state),
      arrowBack, add, trash,
      chevronDownCircleOutline,
      openApply,
      deleteApply,
      doRefresh,
      doInfinite,
    }
  }
})
