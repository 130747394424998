/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import {VuexOpers} from "@/common/TypeDefs";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import {getFullReportItems, getReportDefItemByReporter} from "@/api/common";
import {MaterialApplyVO} from "@/vo/MaterialApplyVO";

export type StateItems = {
    pageItems: Array<ReportDefItemWithValue>, fullItems: Array<ReportDefItemWithValue>, materialApplyDetail: MaterialApplyVO | null
};

const state: StateItems = {pageItems: [], fullItems: [], materialApplyDetail: null};
const getters = {
    pageItems: (state: StateItems) => state.pageItems,
    fullItems: (state: StateItems) => state.fullItems,
    materialApplyDetail: (state: StateItems) => state.materialApplyDetail,
}
const mutations = {
    /**
     * @description 设置用户名
     * @param {*} state
     * @param {*} username
     */
    setPageItems(state: StateItems, pageItems: Array<ReportDefItemWithValue>) {
        state.pageItems = pageItems;
    },
    setFullItems(state: StateItems, fullItems: Array<ReportDefItemWithValue>) {
        state.fullItems = fullItems;
    },
    setMaterialApplyDetail(state: StateItems, materialApplyDetail: MaterialApplyVO | null) {
        state.materialApplyDetail = materialApplyDetail;
    },
}
const actions = {
    async getReportDefItemByReporter({commit, dispatch}: VuexOpers, params: { id: string, type: string, useUserId: boolean, variables?: object }) {
        const pageItems = await getReportDefItemByReporter(params.id, params.type, params.useUserId, params.variables);

        if (pageItems) {
            commit('setPageItems', pageItems);
            return Promise.resolve(pageItems);
        } else {
            return Promise.reject(new Error("无法获取页面配置信息"));
        }
    },
    async getFullReportItems({commit, dispatch}: VuexOpers, params: { id: string, type: string, useUserId: boolean }) {
        const pageItems = await getFullReportItems(params.id, params.type, params.useUserId);

        if (pageItems) {
            commit('setFullItems', pageItems);
            return Promise.resolve(pageItems);
        } else {
            return Promise.reject(new Error("无法获取页面配置信息"));
        }
    },
}
export default {state, getters, mutations, actions}
