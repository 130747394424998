
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar, onIonViewDidEnter} from '@ionic/vue';
import {arrowBack, ellipse, square, triangle} from 'ionicons/icons';
import {computed, getCurrentInstance, nextTick, reactive, toRefs} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import {useRoute} from "vue-router";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {useStore} from "vuex";
import DynamicForm from "@/views/component/DynamicForm.vue";
import {getButtons, materialApply} from "@/api/material";
import {getMaterialReportViewItems} from "@/api/page";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import AfterConfirmPage from "@/views/AfterConfirmPage.vue";
import {MaterialApplyVO} from "@/vo/MaterialApplyVO";

export default {
  components: {
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, DynamicForm, AfterConfirmPage
  },
  props: {
    id: {type: String},
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      pageItems: Array<ReportDefItemWithValue>,
      itemTypes: { [name: string]: number },
      buttons: Array<{ id: string, label: string, extData?: object }>,
      afterConfirmModalOpen: boolean,
      reportData: any,
      event?: Event,
    } = {
      pageItems: [],
      itemTypes: Constants.ItemTypes,
      buttons: [],
      afterConfirmModalOpen: false,
      reportData: null,
      event: undefined,
    }
    const state = reactive(temp);


    const badgeNum = computed(() => {
      return null;
    })

    const back = () => {
      NaviUtils.navPop();
    }

    const confirm = async (id: string, extData: object) => {
      try {
        const report = proxy.$refs['dynamicFormId'].getReportData()
        console.info(report)
        report["id"] = getId()
        report["variables"] = extData
        console.log("confirm log")
        const afterPageItems = state.pageItems.filter(item => item.viewFlag === false && item.opportunity === Constants.Opportunity.AFTER_BUTTON && item.opportunityInfo.id === id)
        if (afterPageItems.length === 0) {
          await materialApply(report)
          back()
        } else {
          state.reportData = report
          setAfterConfirmOpen(true)
        }
      } catch (e: any) {
        UIUtils.showToast("", "提交失败:" + e.message, 2000)
        console.error(e)
      }
    }

    const finishData = async (report: any) => {
      try {
        const newData = Object.assign(state.reportData.data, report.data)
        state.reportData.data = newData

        console.info("finishData", state.reportData, report)

        await materialApply(state.reportData)

        setAfterConfirmOpen(false)
        back()
      } catch (e: any) {
        UIUtils.showToast("", "提交失败:" + e.message, 2000)
        console.error(e)
      }
    }

    const getId = () => {
      return props.id === '-1' ? null : props.id
    }

    const setAfterConfirmOpen = (value: boolean) => {
      state.afterConfirmModalOpen = value
    }

    const statusNameCalc = computed(() => {
      const applyVO: MaterialApplyVO = store.getters['form/materialApplyDetail']
      if (applyVO === null) {
        return '新建'
      } else {
        return applyVO.statusName
      }
    })

    onIonViewDidEnter(async () => {
          const id = getId()
          if (id) {
            const data: Array<ReportDefItemWithValue> = await store.dispatch('form/getFullReportItems', {id: id, type: Constants.MATERIAL_WORK_FLOW_ID, useUserId: false});
            if (data) {
              state.pageItems = data
              await nextTick()
              await proxy.$refs['dynamicFormId'].refreshDic()
            } else {
              state.pageItems = []
            }
          } else {
            getMaterialReportViewItems(getId()).then(async (items) => {
              state.pageItems = items
              await nextTick()
              await proxy.$refs['dynamicFormId'].refreshDic()
            }).catch((err) => {
              UIUtils.showToast("", "获取页面配置失败", 2000)
            })
          }


          getButtons(id).then((buttons) => {
            const tempArray: Array<{ label: string, id: string, extData?: object }> = []
            if (buttons && buttons.length > 0) {
              buttons.forEach((button) => {
                tempArray.push({label: button.buttonName, id: button.id, extData: JSON.parse(button.buttonExtData)})
              })
            }
            state.buttons = tempArray
          })
        }
    )
    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      arrowBack,
      badgeNum,
      back,
      confirm,
      setAfterConfirmOpen,
      finishData,
      statusNameCalc,
    }
  }
}
