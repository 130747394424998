
import {
  DatetimeChangeEventDetail,
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonCol,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter,
} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import {Constants} from "@/common/Constants";
import {Filters} from "@/common/Filters";
import {IonDatetimeCustomEvent} from "@ionic/core/dist/types/components";
import {parseISO} from "date-fns";
import {doEdit} from "@/api/isolation";
import {DateUtils} from "@/common/DateUtils";
import PhotoUploader from "@/views/component/PhotoUploader.vue";
import {IsolationApplyVO} from "@/vo/IsolationApplyVO";
import {SelectionItemType} from "@/common/TypeDefs";

export default {
  components: {
    IonPage,
    IonHeader,
    IonModal,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonTextarea,
    IonLabel,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    IonAccordion,
    IonAccordionGroup,
    IonDatetime,
    IonDatetimeButton,
    IonSelect,
    IonSelectOption,
    IonCheckbox,
    PhotoUploader,
  }
  ,
  props: {
    params: {
      type: Object
    },
    modalId: {
      type: String
    },
  }
  ,
  setup(props: any) {
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      startDate?: string,
      endDate?: string,
      form: IsolationApplyVO,
      isolationTypeList: Array<SelectionItemType>
      event?: Event,
    } = {
      startDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.startDate) : DateUtils.tsToIsoString(new Date().getTime()),
      endDate: (props.params && props.params.item) ? DateUtils.tsToIsoString(props.params.item.endDate) : DateUtils.tsToIsoString(new Date().getTime()),
      form: (props.params && props.params.item) ? props.params.item : {closeContactFlag: false, reportFlag: false},
      isolationTypeList: [{label: '集中隔离(观察)中', value: '集中隔离(观察)中'}, {label: '居家隔离(观察)中', value: '居家隔离(观察)中'}, {label: '居家健康检测', value: '居家健康检测'}],
      event: undefined,
    }
    const state = reactive(temp);

    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const save = async (flag: number) => {
      const originValue = state.form.status
      if (flag === 0) {
        state.form.submit = false
      } else if (flag === 1) {
        state.form.submit = true
      }
      try {
        console.info(state)
        await doEdit(state.form)
        back()
      } catch (e: any) {
        console.error(e)
        UIUtils.showToast("", e.message, 2000)
        state.form.status = originValue
      }
    }

    const doCommit = async (flag: number) => {
      if (state.startDate) {
        state.form.startDate = parseISO(state.startDate).getTime()
      }

      if (state.endDate) {
        state.form.endDate = parseISO(state.endDate).getTime()
      }

      const msg = validation()
      if (msg) {
        UIUtils.showToast("", msg, 2000)
        return
      }

      state.form.pictureInfo = JSON.stringify(state.form.pictureList)

      if (flag === 0) {
        await save(flag)
      } else if (flag === 1) {
        UIUtils.showAlertConfirm("确认", "是否确认提交?", async () => {
          await save(flag)
        }, () => {
          console.log("取消")
        }, "提交", "取消")
      }
    }

    const changeStartDate = (event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
      state.startDate = event.detail.value as string
    }

    const changeEndDate = (event: IonDatetimeCustomEvent<DatetimeChangeEventDetail>) => {
      state.endDate = event.detail.value as string
    }

    const validation = () => {
      if (!state.form.type) {
        return "隔离类型不允许为空";
      }
      if (!state.form.startDate) {
        return "开始时间不允许为空";
      }
      if (state.form.endDate && state.form.endDate < state.form.startDate) {
        return "开始时间不能晚于结束时间";
      }
      if (!state.form.isolationReason) {
        return "隔离原因不允许为空";
      }
      if (!state.form.isolationLocation) {
        return "隔离地点不允许为空";
      }

      if (state.form.status === Constants.IsolationStatus.APPLY_FINISH) {
        if (!state.form.pictureList || state.form.pictureList.length === 0) {
          return "解除隔离照片不允许为空";
        }
      }
      return null;
    }

    const rejectedCalc = computed(() => {
      return state.form.status === Constants.IsolationStatus.APPLY_REJECT || state.form.status === Constants.IsolationStatus.END_REJECT
    })

    const endCalc = computed(() => {
      return state.form.status !== Constants.IsolationStatus.APPLY_FINISH && state.form.status !== Constants.IsolationStatus.END_REJECT
    })

    const viewFlagCalc = computed(() => {
      return state.form.status > Constants.IsolationStatus.UNCOMMIT
    })
    onMounted(() => {
      console.log("onMounted")
    })

    onIonViewDidEnter(() => {
      console.log("onIonViewDidEnter")

    })
    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      back,
      save,
      doCommit,
      rejectedCalc,
      endCalc,
      changeStartDate,
      changeEndDate,
      viewFlagCalc,
      IsolationStatus: Constants.IsolationStatus,
      timeFormatCalc: Filters.timeFormatCalc,
      cleanStatusCreateCalc: Filters.cleanStatusCreateCalc,
    }
  }
}
