export type StateItems = {
    backButtonCallback?: () => boolean,
};

const state: StateItems = {backButtonCallback: undefined};
const getters = {
    getBackButtonCallback: (state: StateItems) => state.backButtonCallback,
}
const mutations = {
    addBackButtonCallback(state: StateItems, {backButtonCallback}: { backButtonCallback: () => boolean }) {
        state.backButtonCallback = backButtonCallback
    },
    clearBackButtonCallback() {
        state.backButtonCallback = undefined
    }
}
const actions = {}
export default {state, getters, mutations, actions}
