import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createBlock(_component_ion_label, {
    class: _normalizeClass({'placeholder-text':!$props.modelValue,'label-text-wrap':true}),
    readonly: "",
    onClick: $setup.onLocation,
    style: {"width":"100%"}
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.geolocation), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "onClick"]))
}