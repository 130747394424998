
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  onIonViewDidEnter
} from '@ionic/vue';
import {NaviUtils} from "@/common/NaviUtils";
import {computed, defineComponent, getCurrentInstance, reactive, toRefs} from "vue";
import {add, arrowBack, checkmark, chevronDownCircleOutline, close, trash} from "ionicons/icons";
import {getAuditList} from "@/api/suspend"
import {RefresherCustomEvent} from "@ionic/core/dist/types/components/refresher/refresher-interface";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import NucleicSuspendAuditEditPage from "./NucleicSuspendAuditEditPage.vue";
import ComplexList from "@/views/component/ComplexList.vue";
import {Filters} from "@/common/Filters";
import {Constants} from "@/common/Constants";
import {NucleicSuspendApplyVO} from "@/vo/NucleicSuspendApplyVO";

export default defineComponent({
  components: {
    IonPage,
    IonLabel,
    IonContent,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonButtons,
    IonButton,
    IonIcon,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    ComplexList,
  },
  props: {
    type: {
      type: String, default: null
    },
  },
  setup(props: any) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      pageNo: number,
      pageSize: number,
      isDisabled: boolean,
      list: Array<NucleicSuspendApplyVO>,
      listRef: any,
      event?: Event,
    } = {
      pageNo: 1,
      pageSize: 10,
      isDisabled: false,
      list: [],
      listRef: null,
      event: undefined,
    }

    const state = reactive(temp);

    const back = () => {
      NaviUtils.navPop();
    }

    const viewFlagFunc = (item: NucleicSuspendApplyVO) => {
      return item.status === Constants.ApplyStatus.REJECT || item.status === Constants.ApplyStatus.FINISH
    }

    const viewFlagCalc = computed(() => {
      return (item: NucleicSuspendApplyVO) => viewFlagFunc(item)
    })

    const openEdit = async (vo?: NucleicSuspendApplyVO) => {
      if (vo) {
        const viewFlag = viewFlagFunc(vo)
        await UIUtils.showModal(NucleicSuspendAuditEditPage, {title: '审核', item: vo, viewFlag})
        await doRefresh()
      }
    }

    const fetchData = async () => {
      //只有新创建的任务可以通过普通方法查询任务列表，提交后的任务需要做权限过滤
      const {total, list} = await getAuditList(state.pageNo, state.pageSize, props.type)
      return list
    }

    const doRefresh = async (cb?: (data: Array<any>) => void) => {
      try {
        state.pageNo = 1
        state.pageSize = 10
        state.list = await fetchData()
        store.dispatch("suspend/getSuspendAuditCount")
        if (cb) {
          cb(state.list)
        }
      } finally {
        if (cb) {
          cb([])
        }
      }
    }

    const doInfinite = async (pageNo: number, cb: (data: Array<any>) => void) => {
      try {
        state.pageNo = pageNo
        const data = await fetchData()
        cb(data)
      } catch (e) {
        cb([])
      }
    }

    //此处页面刷新不生效，需要在tabs页监听
    onIonViewDidEnter(async () => {
      await doRefresh()
    })

    return {
      ...toRefs(state),
      arrowBack, add, trash, close, checkmark,
      chevronDownCircleOutline,
      openEdit,
      doRefresh,
      doInfinite,
      back,
      statusNameCalc: Filters.getApplyStatusName,
      viewFlagCalc,
      timeFormatCalc: Filters.timeFormatCalc,
    }
  }
})
