import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_pop_selection = _resolveComponent("user-pop-selection")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, { "is-open": _ctx.isOpen }, {
    default: _withCtx(() => [
      _createVNode(_component_user_pop_selection, {
        modelValue: _ctx.selectionItems,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectionItems) = $event)),
        title: $props.title,
        items: $props.items,
        onOnSelect: $setup.onSelect,
        onOnClose: $setup.onClose
      }, null, 8 /* PROPS */, ["modelValue", "title", "items", "onOnSelect", "onOnClose"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["is-open"]))
}