import axios from "@/common/AxiosExt";
import {API} from "./config";
import qs from "qs";
import ReportItemDef from "@/vo/ReportItemDef";
import {UserInfo} from "@/vo/UserInfo";
import {MaterialApplyVO} from "@/vo/MaterialApplyVO";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";

export async function getInOutReportItems(inout: string) {
    const data: Array<ReportItemDef> = await axios.post(API.INOUT_REPORT_ITEMS + "?" + qs.stringify({
        inout: inout
    }));
    return data;
}

export async function getJobReportItems(jobId: string, activiId: string | null) {
    const data: Array<ReportItemDef> = await axios.post(API.JOB_REPORT_ITEMS + "?" + qs.stringify({
        jobId: jobId,
        activiId: activiId
    }));
    return data;
}

export async function getMaterialReportViewItems(id: string | null) {
    const data: Array<ReportDefItemWithValue> = await axios.get(API.GET_MATERIAL_VIEW_ITEMS, {params: {id: id}});
    return data;
}

export async function getUserList() {
    const data: Array<UserInfo> = await axios.get(API.GET_USER_LIST);
    return data;
}

export async function getMaterialApplyReport(id: string) {
    const data: MaterialApplyVO = await axios.get(API.GET_MATERIAL_APPLY_REPORT, {params: {id: id}});
    return data;
}

