import { registerPlugin } from '@capacitor/core';

import type { UmengPushPlugin } from './definitions';

const UmengPush = registerPlugin<UmengPushPlugin>('UmengPush', {
  web: () => import('./web').then(m => new m.UmengPushWeb()),
});

export * from './definitions';
export { UmengPush };
