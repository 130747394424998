import {createApp} from 'vue'
import App from './App.vue'
import router from './router';

import {IonicVue} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

/* Theme variables */
import './theme/variables.css';

import './theme/global.css';

import VueAxios from "vue-axios";
import axios from "axios";
import {setupStore} from "@/store";
import {AppUtils} from "@/common/AppUtils";
import {Runtimes} from "@/common/Runtimes";

import VueVirtualScroller from 'vue-virtual-scroller'

const app = createApp(App)
    .use(VueAxios, axios)
    .use(IonicVue)
    .use(router)
    .use(VueVirtualScroller);


setupStore(app);

router.isReady().then(() => {
    app.mount('#app');
    AppUtils.getAppInfo().then((appInfo) => {
        Runtimes.appInfo = appInfo
    })
});
