// http request 拦截器
import axios, {AxiosError} from "axios";
import {NaviUtils} from "@/common/NaviUtils";
import {API} from "@/api/config";
import {Constants} from "@/common/Constants";
import {ApiResult} from "@/vo/ApiResult";
import {UIUtils} from "@/common/UIUtils";
import {Runtimes} from "@/common/Runtimes";

const httpStatusIgnoreUrls: { [code: number]: Array<{ url: string, message?: string }> } = {401: [{url: API.LOGIN, message: '用户名密码错误'}]};

let dismissFunc: any = null
axios.interceptors.request.use(
    async config => {
        console.log("dismissFunc", dismissFunc)
        let noloading = false
        if (config.headers && config.headers.noloading && config.headers.noloading === '1') {
            noloading = true
            delete config.headers['noloading']
        }
        if (dismissFunc == null) {
            if (!noloading) {
                dismissFunc = await UIUtils.showLoadingSingle("请求中")
            }
        }
        const tokenString = localStorage.getItem('loginToken')
        const tokenObj = tokenString ? JSON.parse(tokenString) : null;
        if (!config.headers) {
            config.headers = {};
        }
        if (tokenObj && tokenObj.access_token && config.headers.authorization !== 'IGNORED') { // 判断是否存在token，如果存在的话，则每个http header都加上token
            if (config.headers.authorization) {
                console.warn("已有authorization字段 = ", config.headers.authorization)
            } else {
                config.headers.authorization = "Bearer " + tokenObj.access_token  //请求头加上token
            }
        }

        try {
            config.headers[Constants.APP_HEADER.SOURCE_TYPE] = "OPEN_API"
            config.headers[Constants.APP_HEADER.ID] = API.APP_ID
            config.headers[Constants.APP_HEADER.BUILD_TIME] = process.env.BUILD_TIME
            if (Runtimes.appInfo) {
                config.headers[Constants.APP_HEADER.VERSION] = Runtimes.appInfo.versionString
                config.headers[Constants.APP_HEADER.VERSION_CODE] = Runtimes.appInfo.versionCode
                config.headers[Constants.APP_HEADER.UPDATE_ID] = API.APP_ID + "-" + Runtimes.appInfo.versionString
                config.headers[Constants.APP_HEADER.PLATFORM] = Runtimes.appInfo.platform
            }
        } catch (e) {
            console.error(e)
        }
        return config
    },
    err => {
        return Promise.reject(err)
    });

// http response 拦截器
axios.interceptors.response.use(
    response => {
        if (dismissFunc != null) {
            dismissFunc()
            dismissFunc = null
        }
        console.log(response);
        const data = response.data;
        if (data) {
            const apiResult = <ApiResult<any>>data;
            if (apiResult.code) {
                if (apiResult.code != Constants.ApiCode.SUCCESS) {
                    if (apiResult.code === Constants.ApiCode.ERROR_NUCLEIC_NOT_REPORT_IS_NOT_EMPTY) {
                        return Promise.reject(apiResult);
                    } else {
                        return Promise.reject(new Error(apiResult.msg));
                    }
                }
                return Promise.resolve(apiResult.data);
            }
        }
        console.log("无法识别的数据格式 = ", response.data);
        return Promise.resolve(response.data);
    },
    //接口错误状态处理，也就是说无响应时的处理
    (error: AxiosError) => {
        if (dismissFunc != null) {
            dismissFunc()
            dismissFunc = null
        }
        console.log(error);
        if (!error || error.code === 'ECONNABORTED') {
            console.warn('服务请求超时')
            return Promise.reject(error)
        }
        // const {response: {status, statusText, data: {msg = '服务器发生错误'}}} = error
        const {response} = error

        const matchIndex = response && httpStatusIgnoreUrls[response.status] ? httpStatusIgnoreUrls[response.status].findIndex((item) => item.url === (response ? response.request.responseURL : null)) : -1;
        if (matchIndex >= 0) {
            return Promise.reject(new Error(httpStatusIgnoreUrls[401][matchIndex].message));
        }

        const tokenString = localStorage.getItem(Constants.TOKEN_KEY);
        //拦截错误响应，做统一处理
        if (response) {
            switch (response.status) {
                case 401:
                    if (tokenString) {
                        localStorage.removeItem(Constants.TOKEN_KEY);
                    }
                    console.warn("需要登录", response)
                    NaviUtils.navReplace("/login");
                    return Promise.reject(new Error("请先登录"));
            }
            return Promise.reject(error); // 返回接口返回的错误信息
        }
    });

export default axios;
