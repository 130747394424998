import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dd4fd72"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "require-item-star"
}
const _hoisted_2 = {
  key: 0,
  class: "require-item-star"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_item_checker = _resolveComponent("item-checker")!
  const _component_date_time_picker = _resolveComponent("date-time-picker")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_photo_uploader = _resolveComponent("photo-uploader")!
  const _component_video_uploader = _resolveComponent("video-uploader")!
  const _component_file_uploader = _resolveComponent("file-uploader")!
  const _component_user_picker = _resolveComponent("user-picker")!
  const _component_item_list_editor = _resolveComponent("item-list-editor")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createBlock(_component_ion_list, { lines: "full" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.formPageItemCalc, (item, index) => {
        return (_openBlock(), _createBlock(_component_ion_item, {
          key: index,
          class: _normalizeClass({'ion-no-padding':$setup.itemPaddingCalc(item.itemType)!==0})
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" 输入框 "),
            (item.itemType===_ctx.itemTypes.INPUT && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 0,
                  position: item.viewFlag?'fixed':'floating',
                  class: "report-item-class"
                }, {
                  default: _withCtx(() => [
                    (item.required)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                      : _createCommentVNode("v-if", true),
                    _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["position"]))
              : _createCommentVNode("v-if", true),
            (item.itemType===_ctx.itemTypes.INPUT && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_ion_input, {
                  key: 1,
                  readonly: item.viewFlag,
                  inputmode: $setup.inputModeCalc(item),
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event)
                }, null, 8 /* PROPS */, ["readonly", "inputmode", "modelValue", "onUpdate:modelValue"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 单选框 "),
            (item.itemType===_ctx.itemTypes.RADIO && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_item_checker, {
                  key: 2,
                  title: item.label,
                  multiple: false,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "valid-values": $setup.validValuesCalc(item)
                }, null, 8 /* PROPS */, ["title", "modelValue", "onUpdate:modelValue", "required", "valid-values"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 多选框 "),
            (item.itemType===_ctx.itemTypes.COMBO && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_item_checker, {
                  key: 3,
                  title: item.label,
                  multiple: true,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "valid-values": $setup.validValuesCalc(item)
                }, null, 8 /* PROPS */, ["title", "modelValue", "onUpdate:modelValue", "required", "valid-values"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode("\r\n      DATE_PICKER: 6,\r\n      TIME_PICKER: 7,\r\n      DATETIME_PICKER: 8,\r\n      FILE_UPLOADER: 9,\r\n      "),
            ((item.itemType===_ctx.itemTypes.DATE_PICKER||item.itemType===_ctx.itemTypes.TIME_PICKER||item.itemType===_ctx.itemTypes.DATETIME_PICKER) && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_date_time_picker, {
                  key: 4,
                  mode: item.itemType,
                  title: item.label,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "view-flag": item.viewFlag
                }, null, 8 /* PROPS */, ["mode", "title", "modelValue", "onUpdate:modelValue", "required", "view-flag"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 勾选框 "),
            (item.itemType===_ctx.itemTypes.SINGLE_CHECK && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_ion_label, {
                  key: 5,
                  class: "report-item-class"
                }, {
                  default: _withCtx(() => [
                    (item.required)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
                      : _createCommentVNode("v-if", true),
                    _createTextVNode(" " + _toDisplayString(item.label), 1 /* TEXT */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              : _createCommentVNode("v-if", true),
            (item.itemType===_ctx.itemTypes.SINGLE_CHECK && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                  key: 6,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  color: "success",
                  slot: "end",
                  disabled: item.viewFlag
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 照片上传框 "),
            (item.itemType===_ctx.itemTypes.PICTURE && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_photo_uploader, {
                  key: 7,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "view-flag": item.viewFlag,
                  title: item.label,
                  "sub-title": $setup.maxUploadTipsCalc(item)+'张照片',
                  style: {"width":"100%"}
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "required", "view-flag", "title", "sub-title"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 视频上传框 "),
            (item.itemType===_ctx.itemTypes.VIDEO && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_video_uploader, {
                  key: 8,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "view-flag": item.viewFlag,
                  title: item.label,
                  "sub-title": $setup.maxUploadTipsCalc(item)+'个视频',
                  "video-id": item.id,
                  style: {"width":"100%"}
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "required", "view-flag", "title", "sub-title", "video-id"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 文件上传框 "),
            (item.itemType===_ctx.itemTypes.FILE_UPLOADER && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_file_uploader, {
                  key: 9,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "view-flag": item.viewFlag,
                  title: item.label,
                  "sub-title": $setup.maxUploadTipsCalc(item)+'个文件',
                  style: {"width":"100%"}
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "required", "view-flag", "title", "sub-title"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 人员选择框 "),
            (item.itemType===_ctx.itemTypes.USER_SELECTION && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_user_picker, {
                  key: 10,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  "view-flag": item.viewFlag,
                  title: item.label,
                  "sub-title": "",
                  style: {"width":"100%"}
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "required", "view-flag", "title"]))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" 表格输入框 "),
            (item.itemType===_ctx.itemTypes.INPUT_TABLE && $setup.trigShowCalc(item))
              ? (_openBlock(), _createBlock(_component_item_list_editor, {
                  key: 11,
                  modelValue: item.reportValue,
                  "onUpdate:modelValue": ($event: any) => ((item.reportValue) = $event),
                  required: item.required,
                  title: item.label,
                  "view-flag": item.viewFlag,
                  type: $setup.subTypeTransformer(item),
                  "first-item-list": _ctx.dicCache1[item.id],
                  "second-item-list": _ctx.dicCache2[item.id],
                  style: {"width":"100%"}
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "required", "title", "view-flag", "type", "first-item-list", "second-item-list"]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["class"]))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }))
}