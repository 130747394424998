import axios from "@/common/AxiosExt";
import {API} from "./config";
import JobInfo from "@/vo/JobInfo";

export async function getJobList(params: any, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: Array<JobInfo> = await axios.get(API.GET_JOB_LIST, {headers, params});
    return data;
}

export async function getFlowChartConfigString(jobId: string) {
    const data: string = await axios.get(API.GET_FLOWCHART_CONFIG_STRING, {params: {jobId}});
    return data;
}

export async function reportJobData(report: any) {
    const data: string = await axios.post(API.JOB_REPORT, report);
    return data;
}


