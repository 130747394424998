/**
 * @description 房间信息获取
 */
import {VuexOpers} from "@/common/TypeDefs";
import {getBasicDataList} from "@/api/common";
import {RoomInfoVO} from "@/vo/RoomInfoVO";
import {VehicleInfoVO} from "@/vo/VehicleInfoVO";
import {ToolsInfoVO} from "@/vo/ToolsInfoVO";
import {ToolsClassVO} from "@/vo/ToolsClassVO";

export type StateItems = {
    roomList: Array<RoomInfoVO>,
    vehicleList: Array<VehicleInfoVO>,
    toolsList: Array<ToolsInfoVO>,
    toolsClassList: Array<ToolsClassVO>,
};

const state: StateItems = {roomList: [], vehicleList: [], toolsList: [], toolsClassList: []};
const getters = {
    roomList: (state: StateItems) => state.roomList,
    vehicleList: (state: StateItems) => state.vehicleList,
    toolsList: (state: StateItems) => state.toolsList,
    toolsClassList: (state: StateItems) => state.toolsClassList,
}
const mutations = {
    setRoomList(state: StateItems, roomList: Array<RoomInfoVO>) {
        state.roomList = roomList;
    },
    setVehicleList(state: StateItems, vehicleList: Array<VehicleInfoVO>) {
        state.vehicleList = vehicleList;
    },
    setToolsList(state: StateItems, toolsList: Array<ToolsInfoVO>) {
        state.toolsList = toolsList;
    },
    setToolsClassList(state: StateItems, toolsClassList: Array<ToolsClassVO>) {
        state.toolsClassList = toolsClassList;
    },
}
const actions = {
    async getBasicDataList({commit, dispatch}: VuexOpers) {
        const data = await getBasicDataList();

        if (data) {
            commit('setRoomList', data.roomList);
            commit('setVehicleList', data.vehicleList);
            commit('setToolsList', data.toolsList);
            commit('setToolsClassList', data.toolsClassList);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取数据信息"));
        }
    },
}
export default {state, getters, mutations, actions}
