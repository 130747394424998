import {API} from "./config";
import {FileTransfer, FileTransferObject, FileUploadOptions} from "@awesome-cordova-plugins/file-transfer";
import {UploadResult} from "@/vo/UploadResult";
import axios from "@/common/AxiosExt";


const fileTransfer: FileTransferObject = FileTransfer.create();

// fileTransfer.onProgress((event: ProgressEvent) => {
//     console.info(event);
// });

export async function upload(id: string, formId: string, fileUrl: string, name?: string) {
    console.info(`id = ${id} ,formId = ${formId} , fileUrl = ${fileUrl}`);
    const fileName = name ? name : (fileUrl.indexOf("/") >= 0 ? fileUrl.substring(fileUrl.lastIndexOf("/") + 1) : fileUrl);
    const tokenString = localStorage.getItem('loginToken')
    const tokenObj = tokenString ? JSON.parse(tokenString) : null;
    const options: FileUploadOptions = {
        fileKey: id + "_" + fileName,
        fileName: fileName,
        headers: {
            "authorization": "Bearer " + (tokenObj ? tokenObj.access_token : ""),
        },
        params: {
            id, formId, name
        },
    }

    return new Promise<UploadResult>(((resolve, reject) => {
        fileTransfer.upload(fileUrl, API.UPLOAD_PIC, options)
            .then((data) => {
                const result = JSON.parse(data.response);
                if (result.code === 1) {
                    resolve(result.data);
                } else {
                    reject(new Error(result.msg));
                }
            }, (err) => {
                reject(err);
            });
    }));

}

export async function uploadDataUrl(id: string, formId: string, fileUrl: string) {
    const data: UploadResult = await axios.post(API.UPLOAD_PIC_DATA_URL, {id, formId, fileUrl});
    return data;
}
