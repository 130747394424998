import axios from "@/common/AxiosExt";
import {API} from "./config";
import {SelectionItemType} from "@/common/TypeDefs";
import {ReportDefItemWithValue} from "@/vo/ReportDefItemWithValue";
import {BasicDataVO} from "@/vo/BasicDataVO";
import {AppUpdateInfo} from "@/vo/AppUpdateInfo";
import {AppMenu} from "@/vo/AppMenu";
import {ReGeoCodeInfo} from "@/vo/ReGeoCodeInfo";

export async function getDicList(type: string) {
    const data: Array<SelectionItemType> = await axios.get(API.DIC_INFO_LIST, {params: {type}});
    return data;
}

export async function batchGetDicList(types: string) {
    const data: Array<SelectionItemType> = await axios.get(API.DIC_INFO_LIST, {headers: {noloading: '1'}, params: {types}});
    return data;
}

export async function getReportDefItemByReporter(id: string, type: string, useUserId: boolean, variables?: object) {
    const data: Array<ReportDefItemWithValue> = await axios.post(API.GET_REPORT_DEFITEM_BY_REPORTER, {id, type, useUserId, variables: JSON.stringify(variables)});
    return data;
}

export async function getFullReportItems(id: string, type: string, useUserId: boolean) {
    const data: Array<ReportDefItemWithValue> = await axios.post(API.GET_FULL_REPORT_ITEMS, {id, type, useUserId});
    return data;
}

export async function getBasicDataList() {
    const data: BasicDataVO = await axios.get(API.GET_BASIC_DATA_LIST, {headers: {noloading: '1'}});
    return data;
}

export async function changePassword(params: { pass: string | null, oldpass: string | null }) {
    const data: string = await axios.post(API.CHANGE_PASSWORD, params);
    return data;
}

export async function getHomeBadge(noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: { [id: string]: number } = await axios.get(API.GET_HOME_BADGE, {headers});
    return data;
}

export async function checkUpdate(noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: AppUpdateInfo = await axios.get(API.APP_CHECK_UPDATE, {headers});
    return data;
}

export async function registToken(params: any) {
    const headers: any = {noloading: '1'}
    const data = await axios.get(API.REGIST_TOKEN, {headers, params});
    return data;
}

export async function updateTableSign(params: any) {
    await axios.post(API.UPDATE_TABLE_SIGN, params);
}

export async function getHomeMenu() {
    const data: Array<AppMenu> = await axios.get(API.GET_HOME_MENU);
    return data;
}

export async function regeo(data: any) {
    const result: ReGeoCodeInfo = await axios.post(API.GET_REGEO_INFO, data);
    return result;
}
