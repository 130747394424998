
import {IonCol, IonGrid, IonLoading, IonRow, IonSpinner} from '@ionic/vue';
import {computed, getCurrentInstance, reactive, toRefs} from "vue";
import {uuid} from 'vue-uuid';
import {VideoInfoType} from "@/common/TypeDefs";
import {UIUtils} from "@/common/UIUtils";
import {upload} from "@/api/upload"
import {Camera, CameraOptions} from "@awesome-cordova-plugins/camera";
import {Capacitor} from "@capacitor/core";
import {UploadResult} from "@/vo/UploadResult";
import {VideoUtils} from "@/common/VideoUtils";
import {Filters} from "@/common/Filters";

export default {
  name: 'VideoUploder',
  components: {IonGrid, IonRow, IonCol, IonSpinner, IonLoading},
  props: {
    title: {type: String},
    subTitle: {type: String},
    required: {type: Boolean},
    modelValue: {type: Array, default: () => []},
    videoId: {type: String},
    viewFlag: {type: Boolean},
  },
  emits: ['onClick', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()

    let temp: {
      uploadingFlag: boolean,
      videoList: Array<VideoInfoType>,
      videoStatusMap: { [id: string]: boolean },
      //该列表中的元素均为上传完成的元素
      uploadStatusMap: { [id: string]: boolean },
      compressLoadingFlag: boolean,
      progressPercent?: number,
      progressText?: string,
      event?: Event,
    } = {
      uploadingFlag: false,
      videoList: props.modelValue ? (typeof props.modelValue === 'string' ? JSON.parse(props.modelValue) : props.modelValue) : [],
      videoStatusMap: {},
      uploadStatusMap: {},
      compressLoadingFlag: false,
      progressPercent: undefined,
      progressText: undefined,
      event: undefined,
    }
    const state = reactive(temp);

    const onCompressLoadingDismiss = () => {
      state.compressLoadingFlag = false
    }

    const removeVideo = (index: number) => {
      console.info("dblclick");
      state.videoList.splice(index, 1);

      emit('update:modelValue', state.videoList);
    }

    const onVideoClick = (refId: string) => {
      if (proxy.$refs[refId][0].paused) {
        proxy.$refs[refId][0].play();
      } else {
        proxy.$refs[refId][0].pause();
      }
    }

    const resetVideo = (refId: string) => {
      proxy.$refs[refId][0].pause();
      proxy.$refs[refId][0].currentTime = 0;
    }

    const onCanPlay = (refId: string) => {
      if (!state.videoStatusMap[refId]) {
        proxy.$refs[refId][0].currentTime = 0;
      }
    }

    const onPlaying = (refId: string) => {
      state.videoStatusMap[refId] = true;
    }

    const onPause = (refId: string) => {
      state.videoStatusMap[refId] = false;
    }

    const takeVideo = async (jobId: string) => {
      try {
        if (state.uploadingFlag) {
          UIUtils.showToast("", "当前有未上传完成的视频，请稍后再试", 2000);
          return;
        }

        state.uploadingFlag = true;
        const options: CameraOptions = {
          quality: 50,
          destinationType: Camera.DestinationType.FILE_URI,
          sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
          mediaType: Camera.MediaType.VIDEO
        };

        const fileUrlsOld = await Camera.getPicture(options);
        console.info("videoUrls = " + fileUrlsOld);
        const convertedUrls = Capacitor.convertFileSrc(fileUrlsOld);

        let newUrl = null
        state.compressLoadingFlag = true
        try {
          newUrl = await VideoUtils.compress(fileUrlsOld, (progress => {
            state.progressPercent = progress
            state.progressText = "当前压缩进度:" + (state.progressPercent * 100).toFixed(2) + "%"
          }))
        } catch (e) {
          UIUtils.showToast("", "压缩失败, 使用原始视频", 2000);
          newUrl = fileUrlsOld
        } finally {
          state.compressLoadingFlag = false
        }


        let successItems = [];
        let failItemIndex = [];

        const id = uuid.v4();
        successItems.push({id: id, name: 'video', url: convertedUrls, originUrl: newUrl});

        const startIndex = state.videoList.length;
        state.videoList.push(...successItems);

        for (let i = 0; i < successItems.length; i++) {
          try {
            const result: UploadResult = await upload(successItems[i].id, jobId, successItems[i].originUrl);
            console.info("video upload result = ", result);
            if (result) {
              successItems[i].url = result.url;
              state.uploadStatusMap[successItems[i].id] = true;
            }
          } catch (e) {
            console.error("上传视频" + successItems[i].originUrl + "失败", e);
            failItemIndex.push(i);
          }
        }

        for (let i = 0; i < failItemIndex.length; i++) {
          state.videoList.splice(startIndex + failItemIndex[i], 1);
        }
        if (failItemIndex.length > 0) {
          UIUtils.showToast("", "共计" + failItemIndex.length + "个视频上传失败", 2000);
        } else {
          UIUtils.showToast("", "视频上传成功", 2000);
        }

        emit('update:modelValue', state.videoList);
        console.info(state.videoList);
      } finally {
        state.uploadingFlag = false;
      }
    }

    const videoFullId = computed(() => {
      return (index: number, line?: number) => {
        if (line != undefined && line >= 0) {
          return props.videoId + '_' + (index + line * 4 + 3);
        } else {
          return props.videoId + '_' + index;
        }
      }
    })

    const videoFirstLine = computed(() => {
      if (state.videoList.length > 3) {
        return state.videoList.slice(0, 3);
      } else {
        return state.videoList;
      }
    })

    const videoOtherLine = computed(() => {
      return (line: number) => {
        console.info(line);
        if (state.videoList.length > 3 + 4 * line) {
          if (state.videoList.length < 3 + 4 * (line + 1)) {
            return state.videoList.slice(3 + 4 * line, state.videoList.length);
          } else {
            return state.videoList.slice(3 + 4 * line, 3 + 4 * (line + 1));
          }
        } else {
          return [];
        }
      }
    })

    const videoLineNum = computed(() => {
      if (state.videoList.length > 3) {
        const indices = [];
        for (let i = 0; i < (state.videoList.length - 3) / 4 + 1; i++) {
          indices.push(i);
        }
        return indices;
      } else {
        return [];
      }
    })

    emit('update:modelValue', state.videoList)

    for (let i = 0; i < state.videoList.length; i++) {
      state.uploadStatusMap[state.videoList[i].id] = true
    }

    return {
      ...toRefs(state),
      resetVideo,
      onVideoClick,
      onCanPlay,
      onPlaying,
      onPause,
      removeVideo,
      videoFullId,
      takeVideo,
      videoFirstLine,
      videoOtherLine,
      videoLineNum,
      onCompressLoadingDismiss,
      isBrowserCalc: Filters.isBrowserCalc,
    }
  }
}
