import {SystemUserInfo} from "@/vo/SystemUserInfo";
import {AppInfo} from "@/vo/AppInfo";

export class Runtimes {
    public static userInfo?: SystemUserInfo;

    public static appInfo: AppInfo;

    public static needModifyPwd = false;
}
