import {addDays, format, parse} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";

export class DateUtils {
    public static FULL_DATE_PATTERN = "yyyy-MM-dd HH:mm:ss"

    /**
     *将当前时区时间之间转换为UTC,或者将UTC转为当地时区时间
     * @param date 给定时间
     * @param isToUtc true 表示从本地时间转UTC时间 false 表示从UTC时间转本地时间
     * @param pattern 日期格式，传入日期字符串的时候设置
     */
    public static convertTime(date: string | Date | null, isToUtc: boolean, defaultValue: Date, pattern?: string): Date {
        let time: Date
        if (date == null) {
            return defaultValue
        }
        if (typeof date === 'string') {
            if (pattern) {
                time = parse(date, pattern, new Date())
            } else {
                return defaultValue
            }
        } else {
            time = date
        }

        const convertTime = new Date(time.getTime() - (isToUtc ? 1 : -1) * time.getTimezoneOffset() * 60000)
        return convertTime
    }

    public static parseTimeString(time: string, pattern: string): number {
        const date = parse(time, pattern, new Date())
        return date.getTime()
    }

    public static fromTs(ts: number, pattern: string): string {
        return format(ts, pattern)
    }

    public static getLastDay() {
        return addDays(new Date(), -1)
    }

    public static nowIsoStringWithZone() {
        const newDate = utcToZonedTime(new Date(), Intl.DateTimeFormat().resolvedOptions().timeZone)
        return newDate
    }

    public static tsToIsoString(ts: number) {
        //toISOString方法总是返回标准时区时间, 无法修改, 因此需要手动调整时间
        const now = new Date();
        return new Date(ts - now.getTimezoneOffset() * 60 * 1000).toISOString()
    }
}
