/**
 * @description 消毒剂信息获取
 */
import {BadgeItem, VuexOpers} from "@/common/TypeDefs";
import {getCount as getRoomCount, getList as getRoomList} from "@/api/roomclean";
import {getCount as getVehicleCount, getList as getVehicleList} from "@/api/vehicleclean";
import {getCount as getToolsCount, getList as getToolsList} from "@/api/toolsclean";
import {RoomCleanVO} from "@/vo/RoomCleanVO";
import {VehicleCleanVO} from "@/vo/VehicleCleanVO";
import {ToolsCleanVO} from "@/vo/ToolsCleanVO";

export type StateItems = {
    roomCleanList: Array<RoomCleanVO>, roomCleanLCount?: BadgeItem,
    vehicleCleanList: Array<VehicleCleanVO>, vehicleCleanLCount?: BadgeItem,
    toolsCleanList: Array<ToolsCleanVO>, toolsCleanLCount?: BadgeItem,
};

const state: StateItems = {roomCleanList: [], vehicleCleanList: [], toolsCleanList: [], roomCleanLCount: undefined, vehicleCleanLCount: undefined, toolsCleanLCount: undefined};
const getters = {
    roomCleanList: (state: StateItems) => state.roomCleanList,
    vehicleCleanList: (state: StateItems) => state.vehicleCleanList,
    toolsCleanList: (state: StateItems) => state.toolsCleanList,
    roomCleanCount: (state: StateItems) => state.roomCleanLCount,
    vehicleCleanCount: (state: StateItems) => state.vehicleCleanLCount,
    toolsCleanCount: (state: StateItems) => state.toolsCleanLCount,
}
const mutations = {
    setRoomCleanList(state: StateItems, roomCleanList: Array<RoomCleanVO>) {
        state.roomCleanList = roomCleanList
    },
    setRoomCleanCount(state: StateItems, roomCleanLCount: BadgeItem) {
        state.roomCleanLCount = roomCleanLCount
    },
    setVehicleCleanList(state: StateItems, vehicleCleanList: Array<VehicleCleanVO>) {
        state.vehicleCleanList = vehicleCleanList
    },
    setVehicleCleanCount(state: StateItems, vehicleCleanLCount: BadgeItem) {
        state.vehicleCleanLCount = vehicleCleanLCount
    },
    setToolsCleanList(state: StateItems, toolsCleanList: Array<ToolsCleanVO>) {
        state.toolsCleanList = toolsCleanList
    },
    setToolsCleanCount(state: StateItems, toolsCleanLCount: BadgeItem) {
        state.toolsCleanLCount = toolsCleanLCount
    },
}
const actions = {
    async getRoomCleanList({commit, dispatch}: VuexOpers) {
        const {list} = await getRoomList(1, 10000, undefined, true)

        if (list) {
            commit('setRoomCleanList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getRoomCleanCount({commit, dispatch}: VuexOpers) {
        const data = await getRoomCount(undefined, true)

        if (data) {
            commit('setRoomCleanCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getVehicleCleanList({commit, dispatch}: VuexOpers) {
        const {list} = await getVehicleList(1, 10000, undefined, true)

        if (list) {
            commit('setVehicleCleanList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getVehicleCleanCount({commit, dispatch}: VuexOpers) {
        const data = await getVehicleCount(undefined, true)

        if (data) {
            commit('setVehicleCleanCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getToolsCleanList({commit, dispatch}: VuexOpers) {
        const {list} = await getToolsList(1, 10000, undefined, true)

        if (list) {
            commit('setToolsCleanList', list);
            return Promise.resolve(list);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
    async getToolsCleanCount({commit, dispatch}: VuexOpers) {
        const data = await getToolsCount(undefined, true)

        if (data) {
            commit('setToolsCleanCount', data);
            return Promise.resolve(data);
        } else {
            return Promise.reject(new Error("无法获取列表"));
        }
    },
}
export default {state, getters, mutations, actions}
