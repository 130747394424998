import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_input = _resolveComponent("ion-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.viewFlag)
      ? (_openBlock(), _createBlock(_component_ion_select, {
          key: 0,
          interface: _ctx.interface,
          "interface-options": _ctx.interfaceOptions,
          placeholder: _ctx.placeholder,
          modelValue: _ctx.selectedId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedId) = $event)),
          style: {"min-width":"100%"},
          onIonChange: _ctx.itemChanged
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionsWithClear, (item) => {
              return (_openBlock(), _createBlock(_component_ion_select_option, {
                value: item.value,
                key: item.value,
                class: _normalizeClass({'clear-label-class':item.value==='CLEAR_SELECTOR_ITEM_FLAG'})
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value", "class"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["interface", "interface-options", "placeholder", "modelValue", "onIonChange"]))
      : _createCommentVNode("v-if", true),
    (_ctx.viewFlag)
      ? (_openBlock(), _createBlock(_component_ion_input, {
          key: 1,
          readonly: true,
          value: _ctx.showLabelCalc(_ctx.selectedId)
        }, null, 8 /* PROPS */, ["value"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}