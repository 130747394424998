
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFab,
  IonFabButton,
  IonIcon
} from '@ionic/vue';
import {getCurrentInstance, onMounted, onUnmounted, reactive, toRefs, watch} from "vue";
import {arrowBack, trash} from 'ionicons/icons';
import {useStore} from "vuex";
import {ScreenOrientation} from '@awesome-cordova-plugins/screen-orientation';
import SignaturePad from "signature_pad";

export default {
  name: 'SignaturePadModal',
  components: {
    IonModal,
    IonPage,
    IonButton,
    IonButtons,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonFab,
    IonFabButton,
    IonIcon
  },
  props: {
    open: {type: Boolean, default: false},
    modelValue: {type: String, default: null},
  },
  emits: ['onClose', 'onSelect', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      refreshFlag: boolean,
      signaturePadRef: any,
      signaturePadInst?: SignaturePad,
      event?: Event,
    } = {
      refreshFlag: false,
      signaturePadRef: null,
      signaturePadInst: undefined,
      event: undefined,
    }
    const state = reactive(temp);


    const onClose = () => {
      state.signaturePadInst = undefined
      state.refreshFlag = false
      emit('onClose')
    }
    const onSave = () => {
      if (state.signaturePadInst) {
        const dataURL = state.signaturePadInst.toDataURL("image/jpeg", 1.0)
        console.log(dataURL);
        emit("update:modelValue", dataURL)
        onClose()
      }
    }

    const clear = () => {
      if (state.signaturePadInst) {
        state.signaturePadInst.clear()
      }
    }

    onMounted(() => {
      console.info("signaturePadRef Mount")

    })

    onUnmounted(() => {
      console.info("signaturePadRef onUnmounted")
    })

    watch(() => props.open, async (n, o) => {
      if (n === true) {
        try {
          await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)
        } catch (e: any) {
          console.error(e)
        }
        state.refreshFlag = true
        let intervalId = setInterval(() => {
          if (state.signaturePadRef) {
            if (!state.signaturePadInst) {
              const content = document.querySelector("#content")
              if (content) {
                const parentHeight = content.clientHeight
                const parentWidth = content.clientWidth
                state.signaturePadRef.setAttribute("width", parentWidth);
                state.signaturePadRef.setAttribute("height", parentHeight);
                state.signaturePadInst = new SignaturePad(state.signaturePadRef, {
                  backgroundColor: '#ffffff'
                });
                clearInterval(intervalId)
              }
            }
          }
        }, 500)
      } else {
        try {
          await ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
        } catch (e: any) {
          console.error(e)
        }
      }
    })
    return {
      ...toRefs(state),
      arrowBack, trash,
      onClose,
      onSave,
      clear,
    }
  }
}
