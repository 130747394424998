/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
import {SystemUserInfo} from "@/vo/SystemUserInfo";
import {VuexOpers} from "@/common/TypeDefs";
import {getSystemUser, login} from "@/api/login";
import {Constants} from "@/common/Constants";
import {getUserInfo} from "@/api/inout";
import {UserInfo} from "@/vo/UserInfo";
import CookieUtils from "@/common/CookieUtils";
import {AppMenu} from "@/vo/AppMenu";
import {getHomeMenu} from "@/api/common";

export type StateItems = { systemUserInfo: SystemUserInfo | null, userInfo: UserInfo | null, userMenu: Array<AppMenu> };

const state: StateItems = {systemUserInfo: null, userInfo: null, userMenu: []};
const getters = {
    userName: (state: StateItems) => state.userInfo ? state.userInfo.text : null,
    userInOutStatus: (state: StateItems) => state.userInfo ? state.userInfo.statusName : null,
    userInfo: (state: StateItems) => state.userInfo,
    userMenu: (state: StateItems) => state.userMenu,
}
const mutations = {
    /**
     * @description 设置用户名
     * @param {*} state
     * @param {*} username
     */
    setSystemUserInfo(state: StateItems, systemUserInfo: SystemUserInfo) {
        state.systemUserInfo = systemUserInfo;
    },
    setUserInfo(state: StateItems, userInfo: UserInfo) {
        state.userInfo = userInfo;
    },
    setUserMenu(state: StateItems, userMenu: Array<AppMenu>) {
        state.userMenu = userMenu;
    },
}
const actions = {
    /**
     * @description 登录
     * @param {*} { commit }
     * @param {*} userInfo
     */
    async login({commit}: VuexOpers, params: { username: string, password: string }) {
        const loginResult = await login(params.username, params.password)
        if (loginResult && loginResult.access_token) {
            localStorage.setItem(Constants.TOKEN_KEY, JSON.stringify(loginResult));
            return Promise.resolve();
        } else {
            return Promise.reject(new Error("登录失败"));
        }
    },
    async getSystemUserInfo({commit, dispatch}: VuexOpers) {
        const userInfo = await getSystemUser();

        if (userInfo) {
            commit('setSystemUserInfo', userInfo);
            return Promise.resolve();
        } else {
            return Promise.reject(new Error("无法获取用户信息"));
        }
    },
    async getUserInfo({commit, dispatch}: VuexOpers) {
        document.cookie = "";
        const userInfo = await getUserInfo();
        if (userInfo) {
            commit('setUserInfo', userInfo);
            return Promise.resolve(userInfo);
        } else {
            return Promise.reject(new Error("无法获取用户信息"));
        }
    },
    async getUserMenu({commit, dispatch}: VuexOpers) {
        const menuList = await getHomeMenu();

        if (menuList) {
            commit('setUserMenu', menuList);
            return Promise.resolve(menuList);
        } else {
            return Promise.reject(new Error("无法获取用户菜单信息"));
        }
    },
    /**
     * @description 退出登录
     * @param {*} { dispatch }
     */
    async logout({commit}: VuexOpers) {
        // const tokenString = localStorage.getItem(Constants.TOKEN_KEY);
        // if (tokenString) {
        //     await logout(JSON.parse(tokenString).access_token);
        // }
        localStorage.removeItem(Constants.TOKEN_KEY);
        localStorage.removeItem(Constants.USER_INFO);
        commit("setSystemUserInfo", null);
        CookieUtils.setCookie("vertx-web.session", "", -1);
        return Promise.resolve();
    },
}
export default {state, getters, mutations, actions}
