import {Constants} from "@/common/Constants";
import {computed} from "vue";
import {format} from "date-fns";
import {BadgeItem} from "@/common/TypeDefs";
import {AuditStatisticsDataVO} from "@/vo/AuditStatisticsDataVO";
import {AuditStatisticsVO} from "@/vo/AuditStatisticsVO";
import {Capacitor} from "@capacitor/core";

export class Filters {
    public static getCleanStatusName = computed(() => {
        return (status: number): string => {
            switch (status) {
                case Constants.CleanStatus.BEGIN:
                    return "已开始";
                case Constants.CleanStatus.COMMIT:
                    return "已提交";
                case Constants.CleanStatus.SIGNED:
                    return "已签字";
                case Constants.CleanStatus.REJECT:
                    return "已驳回";
                case Constants.CleanStatus.ARCHIVE:
                    return "已归档";
            }
            return "未知";
        }
    })

    public static getApplyStatusName = computed(() => {
        return (status: number): string => {
            switch (status) {
                case Constants.ApplyStatus.UNCOMMIT:
                    return "未提交";
                case Constants.ApplyStatus.COMMIT:
                    return "待审核";
                case Constants.ApplyStatus.FINISH:
                    return "通过";
                case Constants.ApplyStatus.REJECT:
                    return "驳回";
            }
            return "未知";
        }
    })

    public static getIsolationStatusName = computed(() => {
        return (status: number): string => {
            switch (status) {
                case Constants.IsolationStatus.APPLY_REJECT:
                    return "隔离申请驳回";
                case Constants.IsolationStatus.END_REJECT:
                    return "解除申请驳回";
                case Constants.IsolationStatus.UNCOMMIT:
                    return "未提交";
                case Constants.IsolationStatus.POST_AUDIT:
                    return "隔离申请待岗位审批";
                case Constants.IsolationStatus.DEPART_AUDIT:
                    return "隔离申请待部门审批";
                case Constants.IsolationStatus.APPLY_FINISH:
                    return "隔离申请完成";
                case Constants.IsolationStatus.END_POST_AUDIT:
                    return "解除申请待岗位审批";
                case Constants.IsolationStatus.END_DEPART_AUDIT:
                    return "解除申请待部门审批";
                case Constants.IsolationStatus.END_APPLY_FINISH:
                    return "解除申请完成";
            }
            return "未知";
        }
    })

    public static getNucleicReportStatusName = computed(() => {
        return (status: number): string => {
            switch (status) {
                case Constants.NucleicReportStatus.NONE:
                    return "未填写";
                case Constants.NucleicReportStatus.UNCOMMIT:
                    return "未提交";
                case Constants.NucleicReportStatus.TEAM_AUDIT:
                    return "待小组审核";
                case Constants.NucleicReportStatus.POST_AUDIT:
                    return "待岗位审核";
                case Constants.NucleicReportStatus.DEPART_AUDIT:
                    return "待部门审核";
                case Constants.NucleicReportStatus.COMP_AUDIT:
                    return "待公司审核";
                case Constants.NucleicReportStatus.COMP_PASSED:
                    return "公司审核通过";
                case Constants.NucleicReportStatus.REJECT:
                    return "已驳回";
            }
            return "未知";
        }
    })

    public static getNucleicAuditStatusName = computed(() => {
        return (status: number): string => {
            switch (status) {
                case Constants.NucleicAuditStatus.UNCOMMIT:
                    return "未提交";
                case Constants.NucleicAuditStatus.COMMIT:
                    return "已提交";
            }
            return "未知";
        }
    })

    public static timeFormatCalc = computed(() => {
        return (ts: number, pattern?: string) => {
            try {
                if (!pattern) {
                    pattern = 'yyyy-MM-dd HH:mm:ss'
                }
                return format(ts, pattern)
            } catch (e) {
                return ''
            }
        }
    })

    public static jobTypeCalc = computed(() => {
        return (type: number) => {
            try {
                return Constants.defaultDicRefCache
            } catch (e) {
                return '无效的时间戳'
            }
        }
    })

    public static cleanStatusCreateCalc = computed(() => {
        return (status?: number) => {
            if (status === null || status === undefined) {
                return -1;
            }
            return status;
        }
    })

    public static statusNum = (item: BadgeItem, statusList: Array<number>) => {
        try {
            if (item) {
                return Object.entries(item).filter((entry) => {
                    const status = parseInt(entry[0])
                    return statusList.some(s => s === status)
                }).map(entry => entry[1]).reduce((t, i) => t + i)
            }
        } catch (e) {
            console.error(e)
        }
        return 0
    }

    public static getLastDeptName = (item: AuditStatisticsVO | AuditStatisticsDataVO) => {
        if (item) {
            if (item.teamName) {
                return item.teamName
            } else if (item.postName) {
                return item.postName
            } else if (item.departName) {
                return item.departName
            } else {
                return item.compName
            }
        }
        return '未知'
    }

    public static isBrowserCalc = computed(() => {
        return Capacitor.getPlatform() === 'web'
    });
}
