
import {IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonPage, IonRow, IonTitle, IonToolbar} from '@ionic/vue';
import {arrowBack, scanOutline} from 'ionicons/icons';
import {computed, getCurrentInstance, onMounted, PropType, reactive, toRefs} from "vue";
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";
import NucleicAuditTemperPage from "@/views/nucleic/audit/NucleicAuditTemperPage.vue"
import {NucleicAuditListVO} from "@/vo/NucleicAuditListVO";
import {NucleicTemperInfo} from "@/vo/NucleicTemperVO";
import {doTemperAuditList} from "@/api/nucleic";
import InputModal from "@/views/component/InputModal.vue"

export default {
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonButtons,
    IonButton,
    IonIcon,
    IonCol,
    IonRow,
    IonGrid,
    IonFooter,
    NucleicAuditTemperPage,
    InputModal,
  }
  ,
  props: {
    params: {
      type: Object as PropType<{
        title: string,
        viewFlag: boolean,
        index: number,
        auditItems: NucleicAuditListVO
      }>
    },
    modalId: {
      type: String
    },
  },
  emits: ['onSubmit'],
  setup(props: any, {emit}: { emit: any }) {
    console.log(props)
    console.log("props.modalId = " + props.modalId)
    const {proxy}: any = getCurrentInstance()

    const route = useRoute();
    const store = useStore();

    let temp: {
      currentType: number,
      currentIndex: number,
      auditItems: NucleicAuditListVO,
      btnDisable: boolean,
      rejectReason?: string,
      rejectModelOpen: boolean,
      rejectItem?: NucleicTemperInfo,
      event?: Event,
    } = {
      currentType: 0,
      currentIndex: props.params ? props.params.index : 0,
      auditItems: props.params ? props.params.auditItems : null,
      btnDisable: false,
      rejectReason: undefined,
      rejectModelOpen: false,
      event: undefined,
    }

    const state = reactive(temp);


    const back = () => {
      console.log("back")
      UIUtils.dismissModal(props.modalId);
    }

    const moveItem = (delta: number) => {
      state.currentIndex = state.currentIndex + delta
    }

    const doSubmit = async (id: string, rejectFlag: boolean, rejectReason?: string) => {
      try {
        state.btnDisable = true
        await doTemperAuditList(id, rejectFlag, rejectReason)
        if (!removeItem(state.currentIndex)) {
          back()
        }
      } catch (e: any) {
        UIUtils.showToast("", e.message, 2000)
      } finally {
        state.btnDisable = false
      }
    }
    const onSubmit = async (type: number, item: NucleicTemperInfo, flag: number) => {
      if (flag === 1) {
        UIUtils.showAlertConfirm("确认", "是否确认通过这条记录?", async () => {
          await doSubmit(item.id, false)
        }, () => {
          console.info("取消确认")
        })
      } else {
        state.rejectModelOpen = true
        state.rejectItem = item
      }
    }

    const onReject = async (flag: number) => {
      console.log("驳回上报", flag, state.rejectItem)
      if (flag === 1 && state.rejectItem) {
        await doSubmit(state.rejectItem.id, true, state.rejectReason)
        UIUtils.showToast("", "驳回成功", 2000)
      }
      state.rejectItem = undefined
      state.rejectModelOpen = false
    }

    const getItem = (index: number) => {
      if (props.params) {
        console.info("getItem")
        const endIndex = state.auditItems.temperItems.length - 1
        if (index < state.auditItems.temperItems.length) {
          return {
            flag: index === 0 ? (endIndex === 0 ? -3 : -1) : (index === endIndex ? 1 : 0),
            type: 2,
            item: state.auditItems.temperItems[index]
          }
        }
      }
      return {flag: 0, type: 2}
    }

    const removeItem = (index: number) => {
      if (props.params) {
        state.auditItems.temperItems.splice(index, 1)
        if (state.currentIndex === state.auditItems.temperItems.length) {
          state.currentIndex--
        }
        if (state.auditItems.temperItems.length === 0) {
          return false
        }
      }
      return true
    }

    const currentItemCalc = computed(() => {
      const data = getItem(state.currentIndex).item
      console.log("currentItemCalc", data)
      return data
    })

    const currentTypeCalc = computed(() => {
      const data = getItem(state.currentIndex).type
      return data
    })

    const currentFlagCalc = computed(() => {
      const data = getItem(state.currentIndex).flag
      return data
    })

    onMounted(() => {
      console.log("")
    })


    return {
      ...toRefs(state),
      scanOutline,
      arrowBack,
      moveItem,
      onSubmit,
      onReject,
      currentItemCalc,
      currentTypeCalc,
      currentFlagCalc,
      back,
    }
  }
}
