import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-87063d74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "require-item-star"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_my_popover = _resolveComponent("my-popover")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { onClick: $setup.openPopover }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "report-item-class" }, {
                default: _withCtx(() => [
                  ($props.required)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                    : _createCommentVNode("v-if", true),
                  _createTextVNode(_toDisplayString($props.title), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_my_popover, {
                title: $props.popTitle,
                modelValue: _ctx.selectedItems,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedItems) = $event)),
                open: _ctx.openState,
                mutiple: $props.multiple,
                items: $props.validValues,
                onOnClose: _cache[1] || (_cache[1] = (values)=>$setup.onClose(values))
              }, null, 8 /* PROPS */, ["title", "modelValue", "open", "mutiple", "items"])
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { class: "selection-item-class" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.selectItemLabel), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"]))
}