
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
  IonFabButton,
  IonIcon
} from '@ionic/vue';
import {getCurrentInstance, onMounted, PropType, reactive, toRefs} from "vue";
import {arrowBack, checkmark, copyOutline, trash} from 'ionicons/icons';
import {useStore} from "vuex";
import ComplexList from "@/views/component/ComplexList.vue"
import {SignatureItemType, SignatureResultType} from "@/common/TypeDefs";
import SignaturePadModal from "@/views/component/SignaturePadModal.vue";
import {UIUtils} from "@/common/UIUtils";

export default {
  name: 'SignatureSelectionModal',
  components: {
    IonModal,
    IonPage,
    IonButton,
    IonButtons,
    IonTitle,
    IonContent,
    IonHeader,
    IonToolbar,
    IonFabButton,
    IonIcon,
    ComplexList,
    SignaturePadModal
  },
  props: {
    open: {type: Boolean, default: false},
    itemList: {type: Object as PropType<SignatureItemType>, default: null},
    modelValue: {type: Object as PropType<SignatureResultType>, default: null},
  },
  emits: ['onClose', 'onSign', 'update:modelValue'],
  setup(props: any, {emit}: { emit: any }) {
    const {proxy}: any = getCurrentInstance()
    const store = useStore()

    let temp: {
      event?: Event,
      signFlag: boolean,
      signDataURL?: string,
      signatureItemListRef?: any,
      checkedValues: Array<SignatureItemType>,
    } = {
      event: undefined,
      signDataURL: undefined,
      signatureItemListRef: undefined,
      signFlag: false,
      checkedValues: [],
    }
    const state = reactive(temp);

    const openSign = (selectItems: Array<SignatureItemType>) => {
      if (selectItems && selectItems.length > 0) {
        state.signFlag = true
        state.checkedValues = selectItems
      } else {
        UIUtils.showToast("", "请至少选择一条记录", 1000)
      }

    }
    const onClose = () => {
      emit('onClose');
    }

    const onSignatureClose = () => {
      state.signFlag = false

      console.info("onSignatureClose", state.signDataURL)
      if (state.signDataURL && state.signDataURL.trim() !== '') {
        emit('onSign', state.checkedValues.map(item => {
          return {
            id: item.id,
            dataURL: state.signDataURL
          }
        }))
      }
    }

    const selectAll = (flag: boolean) => {
      if (state.signatureItemListRef) {
        state.signatureItemListRef.selectAll(flag)
      }
    }

    onMounted(() => {
      console.info("SignatureSelectionModal Mount")
      state.signDataURL = undefined
      state.checkedValues = []
    })

    return {
      ...toRefs(state),
      arrowBack, trash, copyOutline, checkmark,
      openSign,
      onClose,
      onSignatureClose,
      selectAll,
    }
  }
}
