import axios from "@/common/AxiosExt";
import {API} from "./config";
import {GridResult} from "@/vo/GridResult";
import {BadgeItem} from "@/common/TypeDefs";
import {NucleicSuspendApplyVO} from "@/vo/NucleicSuspendApplyVO";
import {IsolationApplyVO} from "@/vo/IsolationApplyVO";

export async function getList(pageNo: number, pageSize: number, type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: GridResult<IsolationApplyVO> = await axios.get(API.GET_ISOLATION_APPLY_LIST_BY_USER, {params: {pageNo, pageSize, type}, headers});
    return data;
}

export async function getCount(type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: BadgeItem = await axios.get(API.GET_ISOLATION_APPLY_COUNT_BY_USER, {params: {type}, headers});
    return data;
}

export async function getAuditCount(noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: BadgeItem = await axios.get(API.GET_ISOLATION_AUDIT_COUNT, {headers});
    return data;
}

export async function doEdit(vo: IsolationApplyVO) {
    await axios.post(API.SAVE_ISOLATION_APPLY, vo);
}


export async function doDelete(ids: Array<string>) {
    await axios.post(API.DELETE_ISOLATION_APPLY, ids);
}

export async function getAuditList(pageNo: number, pageSize: number, type?: number, noloading?: boolean) {
    const headers: any = {}
    if (noloading) {
        headers.noloading = '1'
    }
    const data: GridResult<NucleicSuspendApplyVO> = await axios.get(API.GET_ISOLATION_AUDIT_LIST, {params: {pageNo, pageSize, type}, headers});
    return data;
}

export async function doAudit(id: string, reject: number, reason?: string) {
    await axios.get(API.DO_ISOLATION_AUDIT, {params: {id, reject, reason}});
}
