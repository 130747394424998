
import {IonGrid, IonRow, IonCol, IonLabel, IonIcon, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonImg} from '@ionic/vue';
import {ellipse, square, triangle, arrowBack} from 'ionicons/icons';
import {computed, reactive, toRefs} from "vue";
import {NaviUtils} from "@/common/NaviUtils";
import {useStore} from "vuex";
import {UIUtils} from "@/common/UIUtils";

export default {
  components: {IonGrid, IonRow, IonCol, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonImg},
  setup() {
    const store = useStore();

    let temp: {
      menuItems: Array<{ title: string, image: string }>,
      event?: Event,
    } = {
      menuItems: [{title: '航班任务', image: 'assets/home/航班任务.png'}, {title: '出入场', image: 'assets/home/出入场.png'},
        {title: '密码管理', image: 'assets/home/密码管理.png'}, {title: '消息管理', image: 'assets/home/消息管理.png'}],
      event: undefined,
    }

    const state = reactive(temp);

    const badgeNum = computed(() => {
      return null;
    })

    const gotoPage = (flag: string) => {
      const url = '/inout_confirm/' + flag
      const userInfo = store.getters["user/userInfo"];
      if (userInfo.inoutStatus === 1 && flag === 'OUT') {
        UIUtils.showToast('', '您已经在场外,不能出场', 2000)
        return
      } else if (userInfo.inoutStatus === 3 && flag === 'IN') {
        UIUtils.showToast('', '您已经在场内,不能进场', 2000)
        return
      }
      console.log("userInfo=", userInfo)
      NaviUtils.navPush(url)
    }

    const back = () => {
      NaviUtils.navPop();
    }

    const statusNameCalc = computed(() => {
      const userInOutStatus = store.getters["user/userInOutStatus"];
      console.info("userInOutStatus = " + userInOutStatus);
      return userInOutStatus;
    })

    return {
      ...toRefs(state),
      ellipse,
      square,
      triangle,
      arrowBack,
      badgeNum,
      back,
      gotoPage,
      statusNameCalc,
    }
  }
}
